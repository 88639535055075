import * as React from "react";
import * as ReactDOM from "react-dom";
import type { GlobalProvider } from "@ladle/react";
import { SectionDevToolsProvider } from "../src/editing/devtools";
import "../node_modules/@relume_io/relume-tailwind/dist/styles.css";
import "./styles.css";

const apiBaseUrl = "https://apis.relume.io/v1/preview";

function Portal({ children }: { children: React.ReactNode }) {
  const [container] = React.useState(() => {
    return document.createElement("div");
  });
  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      container.remove();
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
}

export const Provider: GlobalProvider = ({ children, globalState, storyMeta }) => {
  const webflowPreviewUrl = `${apiBaseUrl}/${storyMeta?.apiPath}`;
  return (
    <>
      <SectionDevToolsProvider
        id={storyMeta?.id}
        mode={globalState.mode}
        webflowPreviewUrl={webflowPreviewUrl}
        Portal={Portal}
      >
        {children}
      </SectionDevToolsProvider>
    </>
  );
};
