import { Declaration, Import, ImportForm } from "./dependencies";
import { ManagedValue } from "./properties";
import { parseValue } from "./parser";
import { defined } from "../shared";

type Access =
  | {
      type: "get";
      field: string | number;
    }
  | {
      type: "apply";
      args: ManagedValue[];
    };

type Boxed = { value: unknown };

export class ReferenceTracker {
  constructor(
    readonly target: Boxed,
    readonly accesses: readonly Access[] = [],
  ) {}

  get(field: string): ReferenceTracker {
    return new ReferenceTracker(this.target, [...this.accesses, { type: "get", field }]);
  }

  apply(args: ManagedValue[]): ReferenceTracker {
    return new ReferenceTracker(this.target, [...this.accesses, { type: "apply", args }]);
  }
}

function BaseTrackedObject() {
  throw new Error();
}

const trackers = new WeakMap<object, ReferenceTracker>();

type TrackedObject = object & { __tracked?: true };

function createTrackedObject(tracker: ReferenceTracker): TrackedObject {
  const tracked = new Proxy(BaseTrackedObject, {
    get(target, field) {
      if (!(typeof field === "string" || typeof field === "number")) {
        throw new Error("Unsupported prop type, only string and number are allowed");
      }
      return createTrackedObject(tracker.get(field));
    },
    apply(target, thisArg, argumentsList) {
      const parsedArgs = argumentsList.map(parseValue);
      return createTrackedObject(tracker.apply(parsedArgs));
    },
  });
  trackers.set(tracked, tracker);
  return tracked;
}

export function isTrackedObject(value: unknown): value is TrackedObject {
  return typeof value === "function" && trackers.has(value);
}

export function getTracker(value: TrackedObject) {
  return defined(trackers.get(value), "Value is not a tracked object");
}

export function trackImport<T>(name: string, value: unknown, form: ImportForm, path: string): T {
  return createTrackedObject(new ReferenceTracker(new Import(name, value, form, path))) as T;
}

/**
 * track an external value such as react hooks, e.g.
 * `const [state, setState] = useRegistered(useState)(0);`
 *
 * allow us to use them in template components and understand how they are used
 */
export function track<T>(value: T): T {
  return createTrackedObject(new ReferenceTracker({ value })) as T;
}

/**
 * register a declaration in a template component
 *
 * registered declaration will be recreated like `const <name> = <src>;` during source code export
 *
 * this function also tracks the value so we can understand how it's used in the template and recreate it.
 * tracked operations includes
 *  - reading (i.e. `value.field` of `value['field']`)
 *  - indexing (i.e. `value[0]`)
 *  - calling (i.e. `value(param)`), the arguments can also be tracked (given they are registered)
 *  - chained operations (i.e. `value.field[0].method(param)`)
 *
 * @param name - variable name to be used in exported code
 * @param dependencies - list of imports needed by the value, this is imported so we know what to import in the exported code
 * @param src - source code of the value if it cannot be JSON serialized, e.g. functions like hooks
 */
export function registerDeclaration<T>(
  name: string,
  value: T,
  dependencies: unknown[],
  src?: { ts: string; js: string },
): T {
  return createTrackedObject(
    new ReferenceTracker(new Declaration(name, value, dependencies, src)),
  ) as T;
}
