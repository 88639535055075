import { DocumentNode } from "./document";

class BaseManagedValue {
  constructor(readonly value: unknown) {}
}

export class NullishValue extends BaseManagedValue {
  readonly type = "nullish" as const;
  constructor() {
    super(undefined);
  }
}

export class PrimitiveValue extends BaseManagedValue {
  readonly type = "primitive" as const;
  constructor(public readonly value: string | number | boolean) {
    super(value);
  }
}

export class FunctionValue extends BaseManagedValue {
  readonly type = "function" as const;
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(public readonly call: Function) {
    super(call);
  }
}

export class ArrayValue extends BaseManagedValue {
  readonly type = "array" as const;
  constructor(
    value: unknown,
    public readonly items: ManagedValue[],
  ) {
    super(value);
  }
}

export class ObjectValue extends BaseManagedValue {
  readonly type = "object" as const;
  constructor(
    value: unknown,
    public readonly entries: [string, ManagedValue][],
  ) {
    super(value);
  }
}

export class DocumentNodeValue extends BaseManagedValue {
  readonly type = "documentNode" as const;
  constructor(
    value: unknown,
    public readonly node: DocumentNode,
  ) {
    super(value);
  }
}

export type ManagedValue =
  | NullishValue
  | PrimitiveValue
  | FunctionValue
  | ArrayValue
  | ObjectValue
  | DocumentNodeValue;

export type NodeProperties = ObjectValue;
