import * as React from "react";
import { useState, useEffect, useContext, useReducer, useCallback, useMemo, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { importRegistry } from "../import-registry";
import "./relume-ui";
import "./react-icons-bi";
import "./react-icons-rx";

importRegistry.registerImports([
  [React, "React", "namespace", "react"],
  [useState, "useState", "named", "react"],
  [useEffect, "useEffect", "named", "react"],
  [useContext, "useContext", "named", "react"],
  [useReducer, "useReducer", "named", "react"],
  [useCallback, "useCallback", "named", "react"],
  [useMemo, "useMemo", "named", "react"],
  [useRef, "useRef", "named", "react"],
  [AnimatePresence, "AnimatePresence", "named", "framer-motion"],
]);

const motionRegistered = importRegistry.registerImport(motion, "motion", "named", "framer-motion");
importRegistry.registerDerived([
  [motion.div, motionRegistered.div],
  [motion.img, motionRegistered.img],
]);
