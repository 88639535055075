import { createContextManager } from "../shared";

class BaseDependency {
  constructor(
    readonly name: string,
    readonly value: unknown,
  ) {}
}

export type ImportForm = "named" | "default" | "namespace"; // only when needed: "effect"

export class Import extends BaseDependency {
  readonly type = "import" as const;
  constructor(
    readonly name: string,
    readonly value: unknown,
    readonly form: ImportForm,
    readonly path: string,
  ) {
    super(name, value);
  }
}

const withDeclarationTracking = createContextManager<{
  scope: "dynamic" | "static";
}>({
  scope: "static",
});

export class Declaration extends BaseDependency {
  readonly type = "declaration" as const;
  readonly scope: "dynamic" | "static";
  constructor(
    readonly name: string,
    readonly value: unknown,
    readonly dependencies: readonly unknown[],
    readonly src?: {
      ts: string;
      js: string;
    },
  ) {
    super(name, value);
    this.scope = withDeclarationTracking(({ scope }) => scope);
  }
}

export type Dependency = Declaration | Import;

export function withRegistrationTracking<T>(fn: () => T): T {
  return withDeclarationTracking((_, setContext) => {
    setContext({ scope: "dynamic" });
    return fn();
  });
}
