import { DocumentNode } from "./document";

/**
 * search for nodes that satisfy @param condition from the given @param node
 * children of the matched node won't be searched
 *
 * @param includeSelf whether to include the node itself in the search
 */
export function* searchFromTree<N extends DocumentNode>(
  node: DocumentNode,
  condition: (node: DocumentNode) => node is N,
  includeSelf = false,
): Generator<N> {
  if (includeSelf && condition(node)) {
    yield node;
  } else if ("children" in node) {
    for (const child of node.children) {
      if (condition(child)) {
        yield child;
      } else {
        yield* searchFromTree<N>(child, condition);
      }
    }
  }
}
