import { importRegistry } from "../import-registry";
import {
  BiAbacus,
  BiAccessibility,
  BiAddToQueue,
  BiAdjust,
  BiAlarmAdd,
  BiAlarmExclamation,
  BiAlarmOff,
  BiAlarmSnooze,
  BiAlarm,
  BiAlbum,
  BiAlignJustify,
  BiAlignLeft,
  BiAlignMiddle,
  BiAlignRight,
  BiAnalyse,
  BiAnchor,
  BiAngry,
  BiAperture,
  BiArch,
  BiArchiveIn,
  BiArchiveOut,
  BiArchive,
  BiArea,
  BiArrowBack,
  BiArrowFromBottom,
  BiArrowFromLeft,
  BiArrowFromRight,
  BiArrowFromTop,
  BiArrowToBottom,
  BiArrowToLeft,
  BiArrowToRight,
  BiArrowToTop,
  BiAt,
  BiAtom,
  BiAward,
  BiBadgeCheck,
  BiBadge,
  BiBaguette,
  BiBall,
  BiBandAid,
  BiBarChartAlt2,
  BiBarChartAlt,
  BiBarChartSquare,
  BiBarChart,
  BiBarcodeReader,
  BiBarcode,
  BiBaseball,
  BiBasket,
  BiBasketball,
  BiBath,
  BiBattery,
  BiBed,
  BiBeenHere,
  BiBeer,
  BiBellMinus,
  BiBellOff,
  BiBellPlus,
  BiBell,
  BiBible,
  BiBitcoin,
  BiBlanket,
  BiBlock,
  BiBluetooth,
  BiBody,
  BiBold,
  BiBoltCircle,
  BiBomb,
  BiBone,
  BiBong,
  BiBookAdd,
  BiBookAlt,
  BiBookBookmark,
  BiBookContent,
  BiBookHeart,
  BiBookOpen,
  BiBookReader,
  BiBook,
  BiBookmarkAltMinus,
  BiBookmarkAltPlus,
  BiBookmarkAlt,
  BiBookmarkHeart,
  BiBookmarkMinus,
  BiBookmarkPlus,
  BiBookmark,
  BiBookmarks,
  BiBorderAll,
  BiBorderBottom,
  BiBorderInner,
  BiBorderLeft,
  BiBorderNone,
  BiBorderOuter,
  BiBorderRadius,
  BiBorderRight,
  BiBorderTop,
  BiBot,
  BiBowlHot,
  BiBowlRice,
  BiBowlingBall,
  BiBox,
  BiBracket,
  BiBraille,
  BiBrain,
  BiBriefcaseAlt2,
  BiBriefcaseAlt,
  BiBriefcase,
  BiBrightnessHalf,
  BiBrightness,
  BiBroadcast,
  BiBrushAlt,
  BiBrush,
  BiBugAlt,
  BiBug,
  BiBuildingHouse,
  BiBuilding,
  BiBuildings,
  BiBulb,
  BiBullseye,
  BiBuoy,
  BiBusSchool,
  BiBus,
  BiCabinet,
  BiCableCar,
  BiCake,
  BiCalculator,
  BiCalendarAlt,
  BiCalendarCheck,
  BiCalendarEdit,
  BiCalendarEvent,
  BiCalendarExclamation,
  BiCalendarHeart,
  BiCalendarMinus,
  BiCalendarPlus,
  BiCalendarStar,
  BiCalendarWeek,
  BiCalendarX,
  BiCalendar,
  BiCameraHome,
  BiCameraMovie,
  BiCameraOff,
  BiCamera,
  BiCandles,
  BiCapsule,
  BiCaptions,
  BiCar,
  BiCard,
  BiCaretDownCircle,
  BiCaretDownSquare,
  BiCaretDown,
  BiCaretLeftCircle,
  BiCaretLeftSquare,
  BiCaretLeft,
  BiCaretRightCircle,
  BiCaretRightSquare,
  BiCaretRight,
  BiCaretUpCircle,
  BiCaretUpSquare,
  BiCaretUp,
  BiCarousel,
  BiCartAdd,
  BiCartAlt,
  BiCartDownload,
  BiCart,
  BiCast,
  BiCategoryAlt,
  BiCategory,
  BiCctv,
  BiCertification,
  BiChair,
  BiChalkboard,
  BiChart,
  BiChat,
  BiCheckCircle,
  BiCheckDouble,
  BiCheckShield,
  BiCheckSquare,
  BiCheck,
  BiCheckboxChecked,
  BiCheckboxMinus,
  BiCheckboxSquare,
  BiCheckbox,
  BiCheese,
  BiChevronDownCircle,
  BiChevronDownSquare,
  BiChevronDown,
  BiChevronLeftCircle,
  BiChevronLeftSquare,
  BiChevronLeft,
  BiChevronRightCircle,
  BiChevronRightSquare,
  BiChevronRight,
  BiChevronUpCircle,
  BiChevronUpSquare,
  BiChevronUp,
  BiChevronsDown,
  BiChevronsLeft,
  BiChevronsRight,
  BiChevronsUp,
  BiChild,
  BiChip,
  BiChurch,
  BiCircleHalf,
  BiCircleQuarter,
  BiCircleThreeQuarter,
  BiCircle,
  BiClinic,
  BiClipboard,
  BiCloset,
  BiCloudDownload,
  BiCloudDrizzle,
  BiCloudLightRain,
  BiCloudLightning,
  BiCloudRain,
  BiCloudSnow,
  BiCloudUpload,
  BiCloud,
  BiCodeAlt,
  BiCodeBlock,
  BiCodeCurly,
  BiCode,
  BiCoffeeTogo,
  BiCoffee,
  BiCog,
  BiCoinStack,
  BiCoin,
  BiCollapseAlt,
  BiCollapseHorizontal,
  BiCollapseVertical,
  BiCollapse,
  BiCollection,
  BiColorFill,
  BiColor,
  BiColumns,
  BiCommand,
  BiCommentAdd,
  BiCommentCheck,
  BiCommentDetail,
  BiCommentDots,
  BiCommentEdit,
  BiCommentError,
  BiCommentMinus,
  BiCommentX,
  BiComment,
  BiCompass,
  BiConfused,
  BiConversation,
  BiCookie,
  BiCool,
  BiCopyAlt,
  BiCopy,
  BiCopyright,
  BiCreditCardAlt,
  BiCreditCardFront,
  BiCreditCard,
  BiCricketBall,
  BiCrop,
  BiCross,
  BiCrosshair,
  BiCrown,
  BiCubeAlt,
  BiCube,
  BiCuboid,
  BiCurrentLocation,
  BiCustomize,
  BiCut,
  BiCycling,
  BiCylinder,
  BiData,
  BiDesktop,
  BiDetail,
  BiDevices,
  BiDialpadAlt,
  BiDialpad,
  BiDiamond,
  BiDice1,
  BiDice2,
  BiDice3,
  BiDice4,
  BiDice5,
  BiDice6,
  BiDirections,
  BiDisc,
  BiDish,
  BiDislike,
  BiDizzy,
  BiDna,
  BiDockBottom,
  BiDockLeft,
  BiDockRight,
  BiDockTop,
  BiDollarCircle,
  BiDollar,
  BiDonateBlood,
  BiDonateHeart,
  BiDoorOpen,
  BiDotsHorizontalRounded,
  BiDotsHorizontal,
  BiDotsVerticalRounded,
  BiDotsVertical,
  BiDoughnutChart,
  BiDownArrowAlt,
  BiDownArrowCircle,
  BiDownArrow,
  BiDownload,
  BiDownvote,
  BiDrink,
  BiDroplet,
  BiDumbbell,
  BiDuplicate,
  BiEditAlt,
  BiEdit,
  BiEnvelopeOpen,
  BiEnvelope,
  BiEqualizer,
  BiEraser,
  BiErrorAlt,
  BiErrorCircle,
  BiError,
  BiEuro,
  BiExclude,
  BiExitFullscreen,
  BiExit,
  BiExpandAlt,
  BiExpandHorizontal,
  BiExpandVertical,
  BiExpand,
  BiExport,
  BiExtension,
  BiFace,
  BiFastForwardCircle,
  BiFastForward,
  BiFemaleSign,
  BiFemale,
  BiFileBlank,
  BiFileFind,
  BiFile,
  BiFilm,
  BiFilterAlt,
  BiFilter,
  BiFingerprint,
  BiFirstAid,
  BiFirstPage,
  BiFlag,
  BiFolderMinus,
  BiFolderOpen,
  BiFolderPlus,
  BiFolder,
  BiFontColor,
  BiFontFamily,
  BiFontSize,
  BiFont,
  BiFoodMenu,
  BiFoodTag,
  BiFootball,
  BiFork,
  BiFridge,
  BiFullscreen,
  BiGame,
  BiGasPump,
  BiGhost,
  BiGift,
  BiGitBranch,
  BiGitCommit,
  BiGitCompare,
  BiGitMerge,
  BiGitPullRequest,
  BiGitRepoForked,
  BiGlassesAlt,
  BiGlasses,
  BiGlobeAlt,
  BiGlobe,
  BiGridAlt,
  BiGridHorizontal,
  BiGridSmall,
  BiGridVertical,
  BiGrid,
  BiGroup,
  BiHandicap,
  BiHappyAlt,
  BiHappyBeaming,
  BiHappyHeartEyes,
  BiHappy,
  BiHardHat,
  BiHash,
  BiHdd,
  BiHeading,
  BiHeadphone,
  BiHealth,
  BiHeartCircle,
  BiHeartSquare,
  BiHeart,
  BiHelpCircle,
  BiHide,
  BiHighlight,
  BiHistory,
  BiHive,
  BiHomeAlt2,
  BiHomeAlt,
  BiHomeCircle,
  BiHomeHeart,
  BiHomeSmile,
  BiHome,
  BiHorizontalCenter,
  BiHorizontalLeft,
  BiHorizontalRight,
  BiHotel,
  BiHourglass,
  BiIdCard,
  BiImageAdd,
  BiImageAlt,
  BiImage,
  BiImages,
  BiImport,
  BiInfinite,
  BiInfoCircle,
  BiInfoSquare,
  BiInjection,
  BiIntersect,
  BiItalic,
  BiJoystickAlt,
  BiJoystickButton,
  BiJoystick,
  BiKey,
  BiKnife,
  BiLabel,
  BiLandscape,
  BiLaptop,
  BiLastPage,
  BiLaugh,
  BiLayerMinus,
  BiLayerPlus,
  BiLayer,
  BiLayout,
  BiLeaf,
  BiLeftArrowAlt,
  BiLeftArrowCircle,
  BiLeftArrow,
  BiLeftDownArrowCircle,
  BiLeftIndent,
  BiLeftTopArrowCircle,
  BiLemon,
  BiLibrary,
  BiLike,
  BiLineChartDown,
  BiLineChart,
  BiLinkAlt,
  BiLinkExternal,
  BiLink,
  BiLira,
  BiListCheck,
  BiListMinus,
  BiListOl,
  BiListPlus,
  BiListUl,
  BiLoaderAlt,
  BiLoaderCircle,
  BiLoader,
  BiLocationPlus,
  BiLockAlt,
  BiLockOpenAlt,
  BiLockOpen,
  BiLock,
  BiLogInCircle,
  BiLogIn,
  BiLogOutCircle,
  BiLogOut,
  BiLowVision,
  BiMagnet,
  BiMailSend,
  BiMaleFemale,
  BiMaleSign,
  BiMale,
  BiMapAlt,
  BiMapPin,
  BiMap,
  BiMask,
  BiMath,
  BiMedal,
  BiMehAlt,
  BiMehBlank,
  BiMeh,
  BiMemoryCard,
  BiMenuAltLeft,
  BiMenuAltRight,
  BiMenu,
  BiMerge,
  BiMessageAdd,
  BiMessageAltAdd,
  BiMessageAltCheck,
  BiMessageAltDetail,
  BiMessageAltDots,
  BiMessageAltEdit,
  BiMessageAltError,
  BiMessageAltMinus,
  BiMessageAltX,
  BiMessageAlt,
  BiMessageCheck,
  BiMessageDetail,
  BiMessageDots,
  BiMessageEdit,
  BiMessageError,
  BiMessageMinus,
  BiMessageRoundedAdd,
  BiMessageRoundedCheck,
  BiMessageRoundedDetail,
  BiMessageRoundedDots,
  BiMessageRoundedEdit,
  BiMessageRoundedError,
  BiMessageRoundedMinus,
  BiMessageRoundedX,
  BiMessageRounded,
  BiMessageSquareAdd,
  BiMessageSquareCheck,
  BiMessageSquareDetail,
  BiMessageSquareDots,
  BiMessageSquareEdit,
  BiMessageSquareError,
  BiMessageSquareMinus,
  BiMessageSquareX,
  BiMessageSquare,
  BiMessageX,
  BiMessage,
  BiMeteor,
  BiMicrochip,
  BiMicrophoneOff,
  BiMicrophone,
  BiMinusBack,
  BiMinusCircle,
  BiMinusFront,
  BiMinus,
  BiMobileAlt,
  BiMobileLandscape,
  BiMobileVibration,
  BiMobile,
  BiMoneyWithdraw,
  BiMoney,
  BiMoon,
  BiMouseAlt,
  BiMouse,
  BiMoveHorizontal,
  BiMoveVertical,
  BiMove,
  BiMoviePlay,
  BiMovie,
  BiMusic,
  BiNavigation,
  BiNetworkChart,
  BiNews,
  BiNoEntry,
  BiNoSignal,
  BiNote,
  BiNotepad,
  BiNotificationOff,
  BiNotification,
  BiObjectsHorizontalCenter,
  BiObjectsHorizontalLeft,
  BiObjectsHorizontalRight,
  BiObjectsVerticalBottom,
  BiObjectsVerticalCenter,
  BiObjectsVerticalTop,
  BiOutline,
  BiPackage,
  BiPaintRoll,
  BiPaint,
  BiPalette,
  BiPaperPlane,
  BiPaperclip,
  BiParagraph,
  BiParty,
  BiPaste,
  BiPauseCircle,
  BiPause,
  BiPen,
  BiPencil,
  BiPhoneCall,
  BiPhoneIncoming,
  BiPhoneOff,
  BiPhoneOutgoing,
  BiPhone,
  BiPhotoAlbum,
  BiPieChartAlt2,
  BiPieChartAlt,
  BiPieChart,
  BiPin,
  BiPlanet,
  BiPlayCircle,
  BiPlay,
  BiPlug,
  BiPlusCircle,
  BiPlusMedical,
  BiPlus,
  BiPodcast,
  BiPointer,
  BiPoll,
  BiPolygon,
  BiPopsicle,
  BiPound,
  BiPowerOff,
  BiPrinter,
  BiPulse,
  BiPurchaseTagAlt,
  BiPurchaseTag,
  BiPyramid,
  BiQrScan,
  BiQr,
  BiQuestionMark,
  BiRadar,
  BiRadioCircleMarked,
  BiRadioCircle,
  BiRadio,
  BiReceipt,
  BiRectangle,
  BiRecycle,
  BiRedo,
  BiReflectHorizontal,
  BiReflectVertical,
  BiRefresh,
  BiRegistered,
  BiRename,
  BiRepeat,
  BiReplyAll,
  BiReply,
  BiRepost,
  BiReset,
  BiRestaurant,
  BiRevision,
  BiRewindCircle,
  BiRewind,
  BiRfid,
  BiRightArrowAlt,
  BiRightArrowCircle,
  BiRightArrow,
  BiRightDownArrowCircle,
  BiRightIndent,
  BiRightTopArrowCircle,
  BiRocket,
  BiRotateLeft,
  BiRotateRight,
  BiRss,
  BiRuble,
  BiRuler,
  BiRun,
  BiRupee,
  BiSad,
  BiSave,
  BiScan,
  BiScatterChart,
  BiScreenshot,
  BiSearchAlt2,
  BiSearchAlt,
  BiSearch,
  BiSelectMultiple,
  BiSelection,
  BiSend,
  BiServer,
  BiShapeCircle,
  BiShapePolygon,
  BiShapeSquare,
  BiShapeTriangle,
  BiShareAlt,
  BiShare,
  BiShekel,
  BiShieldAlt2,
  BiShieldAlt,
  BiShieldMinus,
  BiShieldPlus,
  BiShieldQuarter,
  BiShieldX,
  BiShield,
  BiShocked,
  BiShoppingBag,
  BiShowAlt,
  BiShow,
  BiShower,
  BiShuffle,
  BiSidebar,
  BiSignal1,
  BiSignal2,
  BiSignal3,
  BiSignal4,
  BiSignal5,
  BiSitemap,
  BiSkipNextCircle,
  BiSkipNext,
  BiSkipPreviousCircle,
  BiSkipPrevious,
  BiSleepy,
  BiSliderAlt,
  BiSlider,
  BiSlideshow,
  BiSmile,
  BiSortAZ,
  BiSortAlt2,
  BiSortDown,
  BiSortUp,
  BiSortZA,
  BiSort,
  BiSpa,
  BiSpaceBar,
  BiSpeaker,
  BiSprayCan,
  BiSpreadsheet,
  BiSquareRounded,
  BiSquare,
  BiStar,
  BiStation,
  BiStats,
  BiSticker,
  BiStopCircle,
  BiStop,
  BiStopwatch,
  BiStoreAlt,
  BiStore,
  BiStreetView,
  BiStrikethrough,
  BiSubdirectoryLeft,
  BiSubdirectoryRight,
  BiSun,
  BiSupport,
  BiSushi,
  BiSwim,
  BiSync,
  BiTab,
  BiTable,
  BiTachometer,
  BiTagAlt,
  BiTag,
  BiTargetLock,
  BiTaskX,
  BiTask,
  BiTaxi,
  BiTennisBall,
  BiTerminal,
  BiTestTube,
  BiText,
  BiTimeFive,
  BiTime,
  BiTimer,
  BiTired,
  BiToggleLeft,
  BiToggleRight,
  BiTone,
  BiTrafficCone,
  BiTrain,
  BiTransferAlt,
  BiTransfer,
  BiTrashAlt,
  BiTrash,
  BiTrendingDown,
  BiTrendingUp,
  BiTrim,
  BiTrip,
  BiTrophy,
  BiTv,
  BiUnderline,
  BiUndo,
  BiUnite,
  BiUniversalAccess,
  BiUnlink,
  BiUpArrowAlt,
  BiUpArrowCircle,
  BiUpArrow,
  BiUpload,
  BiUpsideDown,
  BiUpvote,
  BiUsb,
  BiUserCheck,
  BiUserCircle,
  BiUserMinus,
  BiUserPin,
  BiUserPlus,
  BiUserVoice,
  BiUserX,
  BiUser,
  BiVector,
  BiVerticalBottom,
  BiVerticalCenter,
  BiVerticalTop,
  BiVial,
  BiVideoOff,
  BiVideoPlus,
  BiVideoRecording,
  BiVideo,
  BiVoicemail,
  BiVolumeFull,
  BiVolumeLow,
  BiVolumeMute,
  BiVolume,
  BiWalk,
  BiWalletAlt,
  BiWallet,
  BiWater,
  BiWebcam,
  BiWifi0,
  BiWifi1,
  BiWifi2,
  BiWifiOff,
  BiWifi,
  BiWind,
  BiWindowAlt,
  BiWindowClose,
  BiWindowOpen,
  BiWindow,
  BiWindows,
  BiWine,
  BiWinkSmile,
  BiWinkTongue,
  BiWon,
  BiWorld,
  BiWrench,
  BiXCircle,
  BiX,
  BiYen,
  BiZoomIn,
  BiZoomOut,
  BiSolidAddToQueue,
  BiSolidAdjustAlt,
  BiSolidAdjust,
  BiSolidAlarmAdd,
  BiSolidAlarmExclamation,
  BiSolidAlarmOff,
  BiSolidAlarmSnooze,
  BiSolidAlarm,
  BiSolidAlbum,
  BiSolidAmbulance,
  BiSolidAnalyse,
  BiSolidAngry,
  BiSolidArch,
  BiSolidArchiveIn,
  BiSolidArchiveOut,
  BiSolidArchive,
  BiSolidArea,
  BiSolidArrowFromBottom,
  BiSolidArrowFromLeft,
  BiSolidArrowFromRight,
  BiSolidArrowFromTop,
  BiSolidArrowToBottom,
  BiSolidArrowToLeft,
  BiSolidArrowToRight,
  BiSolidArrowToTop,
  BiSolidAward,
  BiSolidBabyCarriage,
  BiSolidBackpack,
  BiSolidBadgeCheck,
  BiSolidBadgeDollar,
  BiSolidBadge,
  BiSolidBaguette,
  BiSolidBall,
  BiSolidBalloon,
  BiSolidBandAid,
  BiSolidBank,
  BiSolidBarChartAlt2,
  BiSolidBarChartSquare,
  BiSolidBarcode,
  BiSolidBaseball,
  BiSolidBasket,
  BiSolidBasketball,
  BiSolidBath,
  BiSolidBatteryCharging,
  BiSolidBatteryFull,
  BiSolidBatteryLow,
  BiSolidBattery,
  BiSolidBed,
  BiSolidBeenHere,
  BiSolidBeer,
  BiSolidBellMinus,
  BiSolidBellOff,
  BiSolidBellPlus,
  BiSolidBellRing,
  BiSolidBell,
  BiSolidBible,
  BiSolidBinoculars,
  BiSolidBlanket,
  BiSolidBoltCircle,
  BiSolidBolt,
  BiSolidBomb,
  BiSolidBone,
  BiSolidBong,
  BiSolidBookAdd,
  BiSolidBookAlt,
  BiSolidBookBookmark,
  BiSolidBookContent,
  BiSolidBookHeart,
  BiSolidBookOpen,
  BiSolidBookReader,
  BiSolidBook,
  BiSolidBookmarkAltMinus,
  BiSolidBookmarkAltPlus,
  BiSolidBookmarkAlt,
  BiSolidBookmarkHeart,
  BiSolidBookmarkMinus,
  BiSolidBookmarkPlus,
  BiSolidBookmarkStar,
  BiSolidBookmark,
  BiSolidBookmarks,
  BiSolidBot,
  BiSolidBowlHot,
  BiSolidBowlRice,
  BiSolidBowlingBall,
  BiSolidBox,
  BiSolidBrain,
  BiSolidBriefcaseAlt2,
  BiSolidBriefcaseAlt,
  BiSolidBriefcase,
  BiSolidBrightnessHalf,
  BiSolidBrightness,
  BiSolidBrushAlt,
  BiSolidBrush,
  BiSolidBugAlt,
  BiSolidBug,
  BiSolidBuildingHouse,
  BiSolidBuilding,
  BiSolidBuildings,
  BiSolidBulb,
  BiSolidBullseye,
  BiSolidBuoy,
  BiSolidBusSchool,
  BiSolidBus,
  BiSolidBusiness,
  BiSolidCabinet,
  BiSolidCableCar,
  BiSolidCake,
  BiSolidCalculator,
  BiSolidCalendarAlt,
  BiSolidCalendarCheck,
  BiSolidCalendarEdit,
  BiSolidCalendarEvent,
  BiSolidCalendarExclamation,
  BiSolidCalendarHeart,
  BiSolidCalendarMinus,
  BiSolidCalendarPlus,
  BiSolidCalendarStar,
  BiSolidCalendarWeek,
  BiSolidCalendarX,
  BiSolidCalendar,
  BiSolidCameraHome,
  BiSolidCameraMovie,
  BiSolidCameraOff,
  BiSolidCameraPlus,
  BiSolidCamera,
  BiSolidCapsule,
  BiSolidCaptions,
  BiSolidCarBattery,
  BiSolidCarCrash,
  BiSolidCarGarage,
  BiSolidCarMechanic,
  BiSolidCarWash,
  BiSolidCar,
  BiSolidCard,
  BiSolidCaretDownCircle,
  BiSolidCaretDownSquare,
  BiSolidCaretLeftCircle,
  BiSolidCaretLeftSquare,
  BiSolidCaretRightCircle,
  BiSolidCaretRightSquare,
  BiSolidCaretUpCircle,
  BiSolidCaretUpSquare,
  BiSolidCarousel,
  BiSolidCartAdd,
  BiSolidCartAlt,
  BiSolidCartDownload,
  BiSolidCart,
  BiSolidCastle,
  BiSolidCat,
  BiSolidCategoryAlt,
  BiSolidCategory,
  BiSolidCctv,
  BiSolidCertification,
  BiSolidChalkboard,
  BiSolidChart,
  BiSolidChat,
  BiSolidCheckCircle,
  BiSolidCheckShield,
  BiSolidCheckSquare,
  BiSolidCheckboxChecked,
  BiSolidCheckboxMinus,
  BiSolidCheckbox,
  BiSolidCheese,
  BiSolidChess,
  BiSolidChevronDownCircle,
  BiSolidChevronDownSquare,
  BiSolidChevronDown,
  BiSolidChevronLeftCircle,
  BiSolidChevronLeftSquare,
  BiSolidChevronLeft,
  BiSolidChevronRightCircle,
  BiSolidChevronRightSquare,
  BiSolidChevronRight,
  BiSolidChevronUpCircle,
  BiSolidChevronUpSquare,
  BiSolidChevronUp,
  BiSolidChevronsDown,
  BiSolidChevronsLeft,
  BiSolidChevronsRight,
  BiSolidChevronsUp,
  BiSolidChip,
  BiSolidChurch,
  BiSolidCircleHalf,
  BiSolidCircleQuarter,
  BiSolidCircleThreeQuarter,
  BiSolidCircle,
  BiSolidCity,
  BiSolidClinic,
  BiSolidCloudDownload,
  BiSolidCloudLightning,
  BiSolidCloudRain,
  BiSolidCloudUpload,
  BiSolidCloud,
  BiSolidCoffeeAlt,
  BiSolidCoffeeBean,
  BiSolidCoffeeTogo,
  BiSolidCoffee,
  BiSolidCog,
  BiSolidCoinStack,
  BiSolidCoin,
  BiSolidCollection,
  BiSolidColorFill,
  BiSolidColor,
  BiSolidCommentAdd,
  BiSolidCommentCheck,
  BiSolidCommentDetail,
  BiSolidCommentDots,
  BiSolidCommentEdit,
  BiSolidCommentError,
  BiSolidCommentMinus,
  BiSolidCommentX,
  BiSolidComment,
  BiSolidCompass,
  BiSolidComponent,
  BiSolidConfused,
  BiSolidContact,
  BiSolidConversation,
  BiSolidCookie,
  BiSolidCool,
  BiSolidCopyAlt,
  BiSolidCopy,
  BiSolidCopyright,
  BiSolidCoupon,
  BiSolidCreditCardAlt,
  BiSolidCreditCardFront,
  BiSolidCreditCard,
  BiSolidCricketBall,
  BiSolidCrop,
  BiSolidCrown,
  BiSolidCubeAlt,
  BiSolidCube,
  BiSolidCuboid,
  BiSolidCustomize,
  BiSolidCylinder,
  BiSolidDashboard,
  BiSolidData,
  BiSolidDetail,
  BiSolidDevices,
  BiSolidDiamond,
  BiSolidDice1,
  BiSolidDice2,
  BiSolidDice3,
  BiSolidDice4,
  BiSolidDice5,
  BiSolidDice6,
  BiSolidDirectionLeft,
  BiSolidDirectionRight,
  BiSolidDirections,
  BiSolidDisc,
  BiSolidDiscount,
  BiSolidDish,
  BiSolidDislike,
  BiSolidDizzy,
  BiSolidDockBottom,
  BiSolidDockLeft,
  BiSolidDockRight,
  BiSolidDockTop,
  BiSolidDog,
  BiSolidDollarCircle,
  BiSolidDonateBlood,
  BiSolidDonateHeart,
  BiSolidDoorOpen,
  BiSolidDoughnutChart,
  BiSolidDownArrowAlt,
  BiSolidDownArrowCircle,
  BiSolidDownArrowSquare,
  BiSolidDownArrow,
  BiSolidDownload,
  BiSolidDownvote,
  BiSolidDrink,
  BiSolidDropletHalf,
  BiSolidDroplet,
  BiSolidDryer,
  BiSolidDuplicate,
  BiSolidEditAlt,
  BiSolidEditLocation,
  BiSolidEdit,
  BiSolidEject,
  BiSolidEnvelopeOpen,
  BiSolidEnvelope,
  BiSolidEraser,
  BiSolidErrorAlt,
  BiSolidErrorCircle,
  BiSolidError,
  BiSolidEvStation,
  BiSolidExit,
  BiSolidExtension,
  BiSolidEyedropper,
  BiSolidFaceMask,
  BiSolidFace,
  BiSolidFactory,
  BiSolidFastForwardCircle,
  BiSolidFileArchive,
  BiSolidFileBlank,
  BiSolidFileCss,
  BiSolidFileDoc,
  BiSolidFileExport,
  BiSolidFileFind,
  BiSolidFileGif,
  BiSolidFileHtml,
  BiSolidFileImage,
  BiSolidFileImport,
  BiSolidFileJpg,
  BiSolidFileJs,
  BiSolidFileJson,
  BiSolidFileMd,
  BiSolidFilePdf,
  BiSolidFilePlus,
  BiSolidFilePng,
  BiSolidFileTxt,
  BiSolidFile,
  BiSolidFilm,
  BiSolidFilterAlt,
  BiSolidFirstAid,
  BiSolidFlagAlt,
  BiSolidFlagCheckered,
  BiSolidFlag,
  BiSolidFlame,
  BiSolidFlask,
  BiSolidFlorist,
  BiSolidFolderMinus,
  BiSolidFolderOpen,
  BiSolidFolderPlus,
  BiSolidFolder,
  BiSolidFoodMenu,
  BiSolidFridge,
  BiSolidGame,
  BiSolidGasPump,
  BiSolidGhost,
  BiSolidGift,
  BiSolidGraduation,
  BiSolidGridAlt,
  BiSolidGrid,
  BiSolidGroup,
  BiSolidGuitarAmp,
  BiSolidHandDown,
  BiSolidHandLeft,
  BiSolidHandRight,
  BiSolidHandUp,
  BiSolidHand,
  BiSolidHappyAlt,
  BiSolidHappyBeaming,
  BiSolidHappyHeartEyes,
  BiSolidHappy,
  BiSolidHardHat,
  BiSolidHdd,
  BiSolidHeartCircle,
  BiSolidHeartSquare,
  BiSolidHeart,
  BiSolidHelpCircle,
  BiSolidHide,
  BiSolidHomeAlt2,
  BiSolidHomeCircle,
  BiSolidHomeHeart,
  BiSolidHomeSmile,
  BiSolidHome,
  BiSolidHot,
  BiSolidHotel,
  BiSolidHourglassBottom,
  BiSolidHourglassTop,
  BiSolidHourglass,
  BiSolidIdCard,
  BiSolidImageAdd,
  BiSolidImageAlt,
  BiSolidImage,
  BiSolidInbox,
  BiSolidInfoCircle,
  BiSolidInfoSquare,
  BiSolidInjection,
  BiSolidInstitution,
  BiSolidInvader,
  BiSolidJoystickAlt,
  BiSolidJoystickButton,
  BiSolidJoystick,
  BiSolidKey,
  BiSolidKeyboard,
  BiSolidLabel,
  BiSolidLandmark,
  BiSolidLandscape,
  BiSolidLaugh,
  BiSolidLayerMinus,
  BiSolidLayerPlus,
  BiSolidLayer,
  BiSolidLayout,
  BiSolidLeaf,
  BiSolidLeftArrowAlt,
  BiSolidLeftArrowCircle,
  BiSolidLeftArrowSquare,
  BiSolidLeftArrow,
  BiSolidLeftDownArrowCircle,
  BiSolidLeftTopArrowCircle,
  BiSolidLemon,
  BiSolidLike,
  BiSolidLocationPlus,
  BiSolidLockAlt,
  BiSolidLockOpenAlt,
  BiSolidLockOpen,
  BiSolidLock,
  BiSolidLogInCircle,
  BiSolidLogIn,
  BiSolidLogOutCircle,
  BiSolidLogOut,
  BiSolidLowVision,
  BiSolidMagicWand,
  BiSolidMagnet,
  BiSolidMapAlt,
  BiSolidMapPin,
  BiSolidMap,
  BiSolidMask,
  BiSolidMedal,
  BiSolidMegaphone,
  BiSolidMehAlt,
  BiSolidMehBlank,
  BiSolidMeh,
  BiSolidMemoryCard,
  BiSolidMessageAdd,
  BiSolidMessageAltAdd,
  BiSolidMessageAltCheck,
  BiSolidMessageAltDetail,
  BiSolidMessageAltDots,
  BiSolidMessageAltEdit,
  BiSolidMessageAltError,
  BiSolidMessageAltMinus,
  BiSolidMessageAltX,
  BiSolidMessageAlt,
  BiSolidMessageCheck,
  BiSolidMessageDetail,
  BiSolidMessageDots,
  BiSolidMessageEdit,
  BiSolidMessageError,
  BiSolidMessageMinus,
  BiSolidMessageRoundedAdd,
  BiSolidMessageRoundedCheck,
  BiSolidMessageRoundedDetail,
  BiSolidMessageRoundedDots,
  BiSolidMessageRoundedEdit,
  BiSolidMessageRoundedError,
  BiSolidMessageRoundedMinus,
  BiSolidMessageRoundedX,
  BiSolidMessageRounded,
  BiSolidMessageSquareAdd,
  BiSolidMessageSquareCheck,
  BiSolidMessageSquareDetail,
  BiSolidMessageSquareDots,
  BiSolidMessageSquareEdit,
  BiSolidMessageSquareError,
  BiSolidMessageSquareMinus,
  BiSolidMessageSquareX,
  BiSolidMessageSquare,
  BiSolidMessageX,
  BiSolidMessage,
  BiSolidMeteor,
  BiSolidMicrochip,
  BiSolidMicrophoneAlt,
  BiSolidMicrophoneOff,
  BiSolidMicrophone,
  BiSolidMinusCircle,
  BiSolidMinusSquare,
  BiSolidMobileVibration,
  BiSolidMobile,
  BiSolidMoon,
  BiSolidMouseAlt,
  BiSolidMouse,
  BiSolidMoviePlay,
  BiSolidMovie,
  BiSolidMusic,
  BiSolidNavigation,
  BiSolidNetworkChart,
  BiSolidNews,
  BiSolidNoEntry,
  BiSolidNote,
  BiSolidNotepad,
  BiSolidNotificationOff,
  BiSolidNotification,
  BiSolidObjectsHorizontalCenter,
  BiSolidObjectsHorizontalLeft,
  BiSolidObjectsHorizontalRight,
  BiSolidObjectsVerticalBottom,
  BiSolidObjectsVerticalCenter,
  BiSolidObjectsVerticalTop,
  BiSolidOffer,
  BiSolidPackage,
  BiSolidPaintRoll,
  BiSolidPaint,
  BiSolidPalette,
  BiSolidPaperPlane,
  BiSolidParking,
  BiSolidParty,
  BiSolidPaste,
  BiSolidPear,
  BiSolidPen,
  BiSolidPencil,
  BiSolidPhoneCall,
  BiSolidPhoneIncoming,
  BiSolidPhoneOff,
  BiSolidPhoneOutgoing,
  BiSolidPhone,
  BiSolidPhotoAlbum,
  BiSolidPiano,
  BiSolidPieChartAlt2,
  BiSolidPieChartAlt,
  BiSolidPieChart,
  BiSolidPin,
  BiSolidPizza,
  BiSolidPlaneAlt,
  BiSolidPlaneLand,
  BiSolidPlaneTakeOff,
  BiSolidPlane,
  BiSolidPlanet,
  BiSolidPlaylist,
  BiSolidPlug,
  BiSolidPlusCircle,
  BiSolidPlusSquare,
  BiSolidPointer,
  BiSolidPolygon,
  BiSolidPopsicle,
  BiSolidPrinter,
  BiSolidPurchaseTagAlt,
  BiSolidPurchaseTag,
  BiSolidPyramid,
  BiSolidQuoteAltLeft,
  BiSolidQuoteAltRight,
  BiSolidQuoteLeft,
  BiSolidQuoteRight,
  BiSolidQuoteSingleLeft,
  BiSolidQuoteSingleRight,
  BiSolidRadiation,
  BiSolidRadio,
  BiSolidReceipt,
  BiSolidRectangle,
  BiSolidRegistered,
  BiSolidRename,
  BiSolidReport,
  BiSolidRewindCircle,
  BiSolidRightArrowAlt,
  BiSolidRightArrowCircle,
  BiSolidRightArrowSquare,
  BiSolidRightArrow,
  BiSolidRightDownArrowCircle,
  BiSolidRightTopArrowCircle,
  BiSolidRocket,
  BiSolidRuler,
  BiSolidSad,
  BiSolidSave,
  BiSolidSchool,
  BiSolidSearchAlt2,
  BiSolidSearch,
  BiSolidSelectMultiple,
  BiSolidSend,
  BiSolidServer,
  BiSolidShapes,
  BiSolidShareAlt,
  BiSolidShare,
  BiSolidShieldAlt2,
  BiSolidShieldMinus,
  BiSolidShieldPlus,
  BiSolidShieldX,
  BiSolidShield,
  BiSolidShip,
  BiSolidShocked,
  BiSolidShoppingBagAlt,
  BiSolidShoppingBag,
  BiSolidShoppingBags,
  BiSolidShow,
  BiSolidShower,
  BiSolidSkipNextCircle,
  BiSolidSkipPreviousCircle,
  BiSolidSkull,
  BiSolidSleepy,
  BiSolidSlideshow,
  BiSolidSmile,
  BiSolidSortAlt,
  BiSolidSpa,
  BiSolidSpeaker,
  BiSolidSprayCan,
  BiSolidSpreadsheet,
  BiSolidSquareRounded,
  BiSolidSquare,
  BiSolidStarHalf,
  BiSolidStar,
  BiSolidSticker,
  BiSolidStopwatch,
  BiSolidStoreAlt,
  BiSolidStore,
  BiSolidSun,
  BiSolidSushi,
  BiSolidTShirt,
  BiSolidTachometer,
  BiSolidTagAlt,
  BiSolidTagX,
  BiSolidTag,
  BiSolidTaxi,
  BiSolidTennisBall,
  BiSolidTerminal,
  BiSolidThermometer,
  BiSolidTimeFive,
  BiSolidTime,
  BiSolidTimer,
  BiSolidTired,
  BiSolidToTop,
  BiSolidToggleLeft,
  BiSolidToggleRight,
  BiSolidTone,
  BiSolidTorch,
  BiSolidTrafficBarrier,
  BiSolidTrafficCone,
  BiSolidTraffic,
  BiSolidTrain,
  BiSolidTrashAlt,
  BiSolidTrash,
  BiSolidTreeAlt,
  BiSolidTree,
  BiSolidTrophy,
  BiSolidTruck,
  BiSolidTv,
  BiSolidUniversalAccess,
  BiSolidUpArrowAlt,
  BiSolidUpArrowCircle,
  BiSolidUpArrowSquare,
  BiSolidUpArrow,
  BiSolidUpsideDown,
  BiSolidUpvote,
  BiSolidUserAccount,
  BiSolidUserBadge,
  BiSolidUserCheck,
  BiSolidUserCircle,
  BiSolidUserDetail,
  BiSolidUserMinus,
  BiSolidUserPin,
  BiSolidUserPlus,
  BiSolidUserRectangle,
  BiSolidUserVoice,
  BiSolidUserX,
  BiSolidUser,
  BiSolidVector,
  BiSolidVial,
  BiSolidVideoOff,
  BiSolidVideoPlus,
  BiSolidVideoRecording,
  BiSolidVideo,
  BiSolidVideos,
  BiSolidVirusBlock,
  BiSolidVirus,
  BiSolidVolumeFull,
  BiSolidVolumeLow,
  BiSolidVolumeMute,
  BiSolidVolume,
  BiSolidWalletAlt,
  BiSolidWallet,
  BiSolidWasher,
  BiSolidWatchAlt,
  BiSolidWatch,
  BiSolidWebcam,
  BiSolidWidget,
  BiSolidWindowAlt,
  BiSolidWine,
  BiSolidWinkSmile,
  BiSolidWinkTongue,
  BiSolidWrench,
  BiSolidXCircle,
  BiSolidXSquare,
  BiSolidYinYang,
  BiSolidZap,
  BiSolidZoomIn,
  BiSolidZoomOut,
  BiLogo500Px,
  BiLogo99Designs,
  BiLogoAdobe,
  BiLogoAirbnb,
  BiLogoAlgolia,
  BiLogoAmazon,
  BiLogoAndroid,
  BiLogoAngular,
  BiLogoApple,
  BiLogoAudible,
  BiLogoAws,
  BiLogoBaidu,
  BiLogoBehance,
  BiLogoBing,
  BiLogoBitcoin,
  BiLogoBlender,
  BiLogoBlogger,
  BiLogoBootstrap,
  BiLogoCPlusPlus,
  BiLogoChrome,
  BiLogoCodepen,
  BiLogoCreativeCommons,
  BiLogoCss3,
  BiLogoDailymotion,
  BiLogoDeezer,
  BiLogoDevTo,
  BiLogoDeviantart,
  BiLogoDigg,
  BiLogoDigitalocean,
  BiLogoDiscordAlt,
  BiLogoDiscord,
  BiLogoDiscourse,
  BiLogoDjango,
  BiLogoDocker,
  BiLogoDribbble,
  BiLogoDropbox,
  BiLogoDrupal,
  BiLogoEbay,
  BiLogoEdge,
  BiLogoEtsy,
  BiLogoFacebookCircle,
  BiLogoFacebookSquare,
  BiLogoFacebook,
  BiLogoFigma,
  BiLogoFirebase,
  BiLogoFirefox,
  BiLogoFlask,
  BiLogoFlickrSquare,
  BiLogoFlickr,
  BiLogoFlutter,
  BiLogoFoursquare,
  BiLogoGit,
  BiLogoGithub,
  BiLogoGitlab,
  BiLogoGmail,
  BiLogoGoLang,
  BiLogoGoogleCloud,
  BiLogoGooglePlusCircle,
  BiLogoGooglePlus,
  BiLogoGoogle,
  BiLogoGraphql,
  BiLogoHeroku,
  BiLogoHtml5,
  BiLogoImdb,
  BiLogoInstagramAlt,
  BiLogoInstagram,
  BiLogoInternetExplorer,
  BiLogoInvision,
  BiLogoJava,
  BiLogoJavascript,
  BiLogoJoomla,
  BiLogoJquery,
  BiLogoJsfiddle,
  BiLogoKickstarter,
  BiLogoKubernetes,
  BiLogoLess,
  BiLogoLinkedinSquare,
  BiLogoLinkedin,
  BiLogoMagento,
  BiLogoMailchimp,
  BiLogoMarkdown,
  BiLogoMastercard,
  BiLogoMastodon,
  BiLogoMediumOld,
  BiLogoMediumSquare,
  BiLogoMedium,
  BiLogoMessenger,
  BiLogoMeta,
  BiLogoMicrosoftTeams,
  BiLogoMicrosoft,
  BiLogoMongodb,
  BiLogoNetlify,
  BiLogoNodejs,
  BiLogoOkRu,
  BiLogoOpera,
  BiLogoPatreon,
  BiLogoPaypal,
  BiLogoPeriscope,
  BiLogoPhp,
  BiLogoPinterestAlt,
  BiLogoPinterest,
  BiLogoPlayStore,
  BiLogoPocket,
  BiLogoPostgresql,
  BiLogoProductHunt,
  BiLogoPython,
  BiLogoQuora,
  BiLogoReact,
  BiLogoRedbubble,
  BiLogoReddit,
  BiLogoRedux,
  BiLogoSass,
  BiLogoShopify,
  BiLogoSketch,
  BiLogoSkype,
  BiLogoSlackOld,
  BiLogoSlack,
  BiLogoSnapchat,
  BiLogoSoundcloud,
  BiLogoSpotify,
  BiLogoSpringBoot,
  BiLogoSquarespace,
  BiLogoStackOverflow,
  BiLogoSteam,
  BiLogoStripe,
  BiLogoTailwindCss,
  BiLogoTelegram,
  BiLogoTiktok,
  BiLogoTrello,
  BiLogoTripAdvisor,
  BiLogoTumblr,
  BiLogoTux,
  BiLogoTwitch,
  BiLogoTwitter,
  BiLogoTypescript,
  BiLogoUnity,
  BiLogoUnsplash,
  BiLogoUpwork,
  BiLogoVenmo,
  BiLogoVimeo,
  BiLogoVisa,
  BiLogoVisualStudio,
  BiLogoVk,
  BiLogoVuejs,
  BiLogoWhatsappSquare,
  BiLogoWhatsapp,
  BiLogoWikipedia,
  BiLogoWindows,
  BiLogoWix,
  BiLogoWordpress,
  BiLogoXing,
  BiLogoYahoo,
  BiLogoYelp,
  BiLogoYoutube,
  BiLogoZoom,
} from "react-icons/bi";

const reactIconsBi = "react-icons/bi";
importRegistry.registerImports([
  [BiAbacus, "BiAbacus", "named", reactIconsBi],
  [BiAccessibility, "BiAccessibility", "named", reactIconsBi],
  [BiAddToQueue, "BiAddToQueue", "named", reactIconsBi],
  [BiAdjust, "BiAdjust", "named", reactIconsBi],
  [BiAlarmAdd, "BiAlarmAdd", "named", reactIconsBi],
  [BiAlarmExclamation, "BiAlarmExclamation", "named", reactIconsBi],
  [BiAlarmOff, "BiAlarmOff", "named", reactIconsBi],
  [BiAlarmSnooze, "BiAlarmSnooze", "named", reactIconsBi],
  [BiAlarm, "BiAlarm", "named", reactIconsBi],
  [BiAlbum, "BiAlbum", "named", reactIconsBi],
  [BiAlignJustify, "BiAlignJustify", "named", reactIconsBi],
  [BiAlignLeft, "BiAlignLeft", "named", reactIconsBi],
  [BiAlignMiddle, "BiAlignMiddle", "named", reactIconsBi],
  [BiAlignRight, "BiAlignRight", "named", reactIconsBi],
  [BiAnalyse, "BiAnalyse", "named", reactIconsBi],
  [BiAnchor, "BiAnchor", "named", reactIconsBi],
  [BiAngry, "BiAngry", "named", reactIconsBi],
  [BiAperture, "BiAperture", "named", reactIconsBi],
  [BiArch, "BiArch", "named", reactIconsBi],
  [BiArchiveIn, "BiArchiveIn", "named", reactIconsBi],
  [BiArchiveOut, "BiArchiveOut", "named", reactIconsBi],
  [BiArchive, "BiArchive", "named", reactIconsBi],
  [BiArea, "BiArea", "named", reactIconsBi],
  [BiArrowBack, "BiArrowBack", "named", reactIconsBi],
  [BiArrowFromBottom, "BiArrowFromBottom", "named", reactIconsBi],
  [BiArrowFromLeft, "BiArrowFromLeft", "named", reactIconsBi],
  [BiArrowFromRight, "BiArrowFromRight", "named", reactIconsBi],
  [BiArrowFromTop, "BiArrowFromTop", "named", reactIconsBi],
  [BiArrowToBottom, "BiArrowToBottom", "named", reactIconsBi],
  [BiArrowToLeft, "BiArrowToLeft", "named", reactIconsBi],
  [BiArrowToRight, "BiArrowToRight", "named", reactIconsBi],
  [BiArrowToTop, "BiArrowToTop", "named", reactIconsBi],
  [BiAt, "BiAt", "named", reactIconsBi],
  [BiAtom, "BiAtom", "named", reactIconsBi],
  [BiAward, "BiAward", "named", reactIconsBi],
  [BiBadgeCheck, "BiBadgeCheck", "named", reactIconsBi],
  [BiBadge, "BiBadge", "named", reactIconsBi],
  [BiBaguette, "BiBaguette", "named", reactIconsBi],
  [BiBall, "BiBall", "named", reactIconsBi],
  [BiBandAid, "BiBandAid", "named", reactIconsBi],
  [BiBarChartAlt2, "BiBarChartAlt2", "named", reactIconsBi],
  [BiBarChartAlt, "BiBarChartAlt", "named", reactIconsBi],
  [BiBarChartSquare, "BiBarChartSquare", "named", reactIconsBi],
  [BiBarChart, "BiBarChart", "named", reactIconsBi],
  [BiBarcodeReader, "BiBarcodeReader", "named", reactIconsBi],
  [BiBarcode, "BiBarcode", "named", reactIconsBi],
  [BiBaseball, "BiBaseball", "named", reactIconsBi],
  [BiBasket, "BiBasket", "named", reactIconsBi],
  [BiBasketball, "BiBasketball", "named", reactIconsBi],
  [BiBath, "BiBath", "named", reactIconsBi],
  [BiBattery, "BiBattery", "named", reactIconsBi],
  [BiBed, "BiBed", "named", reactIconsBi],
  [BiBeenHere, "BiBeenHere", "named", reactIconsBi],
  [BiBeer, "BiBeer", "named", reactIconsBi],
  [BiBellMinus, "BiBellMinus", "named", reactIconsBi],
  [BiBellOff, "BiBellOff", "named", reactIconsBi],
  [BiBellPlus, "BiBellPlus", "named", reactIconsBi],
  [BiBell, "BiBell", "named", reactIconsBi],
  [BiBible, "BiBible", "named", reactIconsBi],
  [BiBitcoin, "BiBitcoin", "named", reactIconsBi],
  [BiBlanket, "BiBlanket", "named", reactIconsBi],
  [BiBlock, "BiBlock", "named", reactIconsBi],
  [BiBluetooth, "BiBluetooth", "named", reactIconsBi],
  [BiBody, "BiBody", "named", reactIconsBi],
  [BiBold, "BiBold", "named", reactIconsBi],
  [BiBoltCircle, "BiBoltCircle", "named", reactIconsBi],
  [BiBomb, "BiBomb", "named", reactIconsBi],
  [BiBone, "BiBone", "named", reactIconsBi],
  [BiBong, "BiBong", "named", reactIconsBi],
  [BiBookAdd, "BiBookAdd", "named", reactIconsBi],
  [BiBookAlt, "BiBookAlt", "named", reactIconsBi],
  [BiBookBookmark, "BiBookBookmark", "named", reactIconsBi],
  [BiBookContent, "BiBookContent", "named", reactIconsBi],
  [BiBookHeart, "BiBookHeart", "named", reactIconsBi],
  [BiBookOpen, "BiBookOpen", "named", reactIconsBi],
  [BiBookReader, "BiBookReader", "named", reactIconsBi],
  [BiBook, "BiBook", "named", reactIconsBi],
  [BiBookmarkAltMinus, "BiBookmarkAltMinus", "named", reactIconsBi],
  [BiBookmarkAltPlus, "BiBookmarkAltPlus", "named", reactIconsBi],
  [BiBookmarkAlt, "BiBookmarkAlt", "named", reactIconsBi],
  [BiBookmarkHeart, "BiBookmarkHeart", "named", reactIconsBi],
  [BiBookmarkMinus, "BiBookmarkMinus", "named", reactIconsBi],
  [BiBookmarkPlus, "BiBookmarkPlus", "named", reactIconsBi],
  [BiBookmark, "BiBookmark", "named", reactIconsBi],
  [BiBookmarks, "BiBookmarks", "named", reactIconsBi],
  [BiBorderAll, "BiBorderAll", "named", reactIconsBi],
  [BiBorderBottom, "BiBorderBottom", "named", reactIconsBi],
  [BiBorderInner, "BiBorderInner", "named", reactIconsBi],
  [BiBorderLeft, "BiBorderLeft", "named", reactIconsBi],
  [BiBorderNone, "BiBorderNone", "named", reactIconsBi],
  [BiBorderOuter, "BiBorderOuter", "named", reactIconsBi],
  [BiBorderRadius, "BiBorderRadius", "named", reactIconsBi],
  [BiBorderRight, "BiBorderRight", "named", reactIconsBi],
  [BiBorderTop, "BiBorderTop", "named", reactIconsBi],
  [BiBot, "BiBot", "named", reactIconsBi],
  [BiBowlHot, "BiBowlHot", "named", reactIconsBi],
  [BiBowlRice, "BiBowlRice", "named", reactIconsBi],
  [BiBowlingBall, "BiBowlingBall", "named", reactIconsBi],
  [BiBox, "BiBox", "named", reactIconsBi],
  [BiBracket, "BiBracket", "named", reactIconsBi],
  [BiBraille, "BiBraille", "named", reactIconsBi],
  [BiBrain, "BiBrain", "named", reactIconsBi],
  [BiBriefcaseAlt2, "BiBriefcaseAlt2", "named", reactIconsBi],
  [BiBriefcaseAlt, "BiBriefcaseAlt", "named", reactIconsBi],
  [BiBriefcase, "BiBriefcase", "named", reactIconsBi],
  [BiBrightnessHalf, "BiBrightnessHalf", "named", reactIconsBi],
  [BiBrightness, "BiBrightness", "named", reactIconsBi],
  [BiBroadcast, "BiBroadcast", "named", reactIconsBi],
  [BiBrushAlt, "BiBrushAlt", "named", reactIconsBi],
  [BiBrush, "BiBrush", "named", reactIconsBi],
  [BiBugAlt, "BiBugAlt", "named", reactIconsBi],
  [BiBug, "BiBug", "named", reactIconsBi],
  [BiBuildingHouse, "BiBuildingHouse", "named", reactIconsBi],
  [BiBuilding, "BiBuilding", "named", reactIconsBi],
  [BiBuildings, "BiBuildings", "named", reactIconsBi],
  [BiBulb, "BiBulb", "named", reactIconsBi],
  [BiBullseye, "BiBullseye", "named", reactIconsBi],
  [BiBuoy, "BiBuoy", "named", reactIconsBi],
  [BiBusSchool, "BiBusSchool", "named", reactIconsBi],
  [BiBus, "BiBus", "named", reactIconsBi],
  [BiCabinet, "BiCabinet", "named", reactIconsBi],
  [BiCableCar, "BiCableCar", "named", reactIconsBi],
  [BiCake, "BiCake", "named", reactIconsBi],
  [BiCalculator, "BiCalculator", "named", reactIconsBi],
  [BiCalendarAlt, "BiCalendarAlt", "named", reactIconsBi],
  [BiCalendarCheck, "BiCalendarCheck", "named", reactIconsBi],
  [BiCalendarEdit, "BiCalendarEdit", "named", reactIconsBi],
  [BiCalendarEvent, "BiCalendarEvent", "named", reactIconsBi],
  [BiCalendarExclamation, "BiCalendarExclamation", "named", reactIconsBi],
  [BiCalendarHeart, "BiCalendarHeart", "named", reactIconsBi],
  [BiCalendarMinus, "BiCalendarMinus", "named", reactIconsBi],
  [BiCalendarPlus, "BiCalendarPlus", "named", reactIconsBi],
  [BiCalendarStar, "BiCalendarStar", "named", reactIconsBi],
  [BiCalendarWeek, "BiCalendarWeek", "named", reactIconsBi],
  [BiCalendarX, "BiCalendarX", "named", reactIconsBi],
  [BiCalendar, "BiCalendar", "named", reactIconsBi],
  [BiCameraHome, "BiCameraHome", "named", reactIconsBi],
  [BiCameraMovie, "BiCameraMovie", "named", reactIconsBi],
  [BiCameraOff, "BiCameraOff", "named", reactIconsBi],
  [BiCamera, "BiCamera", "named", reactIconsBi],
  [BiCandles, "BiCandles", "named", reactIconsBi],
  [BiCapsule, "BiCapsule", "named", reactIconsBi],
  [BiCaptions, "BiCaptions", "named", reactIconsBi],
  [BiCar, "BiCar", "named", reactIconsBi],
  [BiCard, "BiCard", "named", reactIconsBi],
  [BiCaretDownCircle, "BiCaretDownCircle", "named", reactIconsBi],
  [BiCaretDownSquare, "BiCaretDownSquare", "named", reactIconsBi],
  [BiCaretDown, "BiCaretDown", "named", reactIconsBi],
  [BiCaretLeftCircle, "BiCaretLeftCircle", "named", reactIconsBi],
  [BiCaretLeftSquare, "BiCaretLeftSquare", "named", reactIconsBi],
  [BiCaretLeft, "BiCaretLeft", "named", reactIconsBi],
  [BiCaretRightCircle, "BiCaretRightCircle", "named", reactIconsBi],
  [BiCaretRightSquare, "BiCaretRightSquare", "named", reactIconsBi],
  [BiCaretRight, "BiCaretRight", "named", reactIconsBi],
  [BiCaretUpCircle, "BiCaretUpCircle", "named", reactIconsBi],
  [BiCaretUpSquare, "BiCaretUpSquare", "named", reactIconsBi],
  [BiCaretUp, "BiCaretUp", "named", reactIconsBi],
  [BiCarousel, "BiCarousel", "named", reactIconsBi],
  [BiCartAdd, "BiCartAdd", "named", reactIconsBi],
  [BiCartAlt, "BiCartAlt", "named", reactIconsBi],
  [BiCartDownload, "BiCartDownload", "named", reactIconsBi],
  [BiCart, "BiCart", "named", reactIconsBi],
  [BiCast, "BiCast", "named", reactIconsBi],
  [BiCategoryAlt, "BiCategoryAlt", "named", reactIconsBi],
  [BiCategory, "BiCategory", "named", reactIconsBi],
  [BiCctv, "BiCctv", "named", reactIconsBi],
  [BiCertification, "BiCertification", "named", reactIconsBi],
  [BiChair, "BiChair", "named", reactIconsBi],
  [BiChalkboard, "BiChalkboard", "named", reactIconsBi],
  [BiChart, "BiChart", "named", reactIconsBi],
  [BiChat, "BiChat", "named", reactIconsBi],
  [BiCheckCircle, "BiCheckCircle", "named", reactIconsBi],
  [BiCheckDouble, "BiCheckDouble", "named", reactIconsBi],
  [BiCheckShield, "BiCheckShield", "named", reactIconsBi],
  [BiCheckSquare, "BiCheckSquare", "named", reactIconsBi],
  [BiCheck, "BiCheck", "named", reactIconsBi],
  [BiCheckboxChecked, "BiCheckboxChecked", "named", reactIconsBi],
  [BiCheckboxMinus, "BiCheckboxMinus", "named", reactIconsBi],
  [BiCheckboxSquare, "BiCheckboxSquare", "named", reactIconsBi],
  [BiCheckbox, "BiCheckbox", "named", reactIconsBi],
  [BiCheese, "BiCheese", "named", reactIconsBi],
  [BiChevronDownCircle, "BiChevronDownCircle", "named", reactIconsBi],
  [BiChevronDownSquare, "BiChevronDownSquare", "named", reactIconsBi],
  [BiChevronDown, "BiChevronDown", "named", reactIconsBi],
  [BiChevronLeftCircle, "BiChevronLeftCircle", "named", reactIconsBi],
  [BiChevronLeftSquare, "BiChevronLeftSquare", "named", reactIconsBi],
  [BiChevronLeft, "BiChevronLeft", "named", reactIconsBi],
  [BiChevronRightCircle, "BiChevronRightCircle", "named", reactIconsBi],
  [BiChevronRightSquare, "BiChevronRightSquare", "named", reactIconsBi],
  [BiChevronRight, "BiChevronRight", "named", reactIconsBi],
  [BiChevronUpCircle, "BiChevronUpCircle", "named", reactIconsBi],
  [BiChevronUpSquare, "BiChevronUpSquare", "named", reactIconsBi],
  [BiChevronUp, "BiChevronUp", "named", reactIconsBi],
  [BiChevronsDown, "BiChevronsDown", "named", reactIconsBi],
  [BiChevronsLeft, "BiChevronsLeft", "named", reactIconsBi],
  [BiChevronsRight, "BiChevronsRight", "named", reactIconsBi],
  [BiChevronsUp, "BiChevronsUp", "named", reactIconsBi],
  [BiChild, "BiChild", "named", reactIconsBi],
  [BiChip, "BiChip", "named", reactIconsBi],
  [BiChurch, "BiChurch", "named", reactIconsBi],
  [BiCircleHalf, "BiCircleHalf", "named", reactIconsBi],
  [BiCircleQuarter, "BiCircleQuarter", "named", reactIconsBi],
  [BiCircleThreeQuarter, "BiCircleThreeQuarter", "named", reactIconsBi],
  [BiCircle, "BiCircle", "named", reactIconsBi],
  [BiClinic, "BiClinic", "named", reactIconsBi],
  [BiClipboard, "BiClipboard", "named", reactIconsBi],
  [BiCloset, "BiCloset", "named", reactIconsBi],
  [BiCloudDownload, "BiCloudDownload", "named", reactIconsBi],
  [BiCloudDrizzle, "BiCloudDrizzle", "named", reactIconsBi],
  [BiCloudLightRain, "BiCloudLightRain", "named", reactIconsBi],
  [BiCloudLightning, "BiCloudLightning", "named", reactIconsBi],
  [BiCloudRain, "BiCloudRain", "named", reactIconsBi],
  [BiCloudSnow, "BiCloudSnow", "named", reactIconsBi],
  [BiCloudUpload, "BiCloudUpload", "named", reactIconsBi],
  [BiCloud, "BiCloud", "named", reactIconsBi],
  [BiCodeAlt, "BiCodeAlt", "named", reactIconsBi],
  [BiCodeBlock, "BiCodeBlock", "named", reactIconsBi],
  [BiCodeCurly, "BiCodeCurly", "named", reactIconsBi],
  [BiCode, "BiCode", "named", reactIconsBi],
  [BiCoffeeTogo, "BiCoffeeTogo", "named", reactIconsBi],
  [BiCoffee, "BiCoffee", "named", reactIconsBi],
  [BiCog, "BiCog", "named", reactIconsBi],
  [BiCoinStack, "BiCoinStack", "named", reactIconsBi],
  [BiCoin, "BiCoin", "named", reactIconsBi],
  [BiCollapseAlt, "BiCollapseAlt", "named", reactIconsBi],
  [BiCollapseHorizontal, "BiCollapseHorizontal", "named", reactIconsBi],
  [BiCollapseVertical, "BiCollapseVertical", "named", reactIconsBi],
  [BiCollapse, "BiCollapse", "named", reactIconsBi],
  [BiCollection, "BiCollection", "named", reactIconsBi],
  [BiColorFill, "BiColorFill", "named", reactIconsBi],
  [BiColor, "BiColor", "named", reactIconsBi],
  [BiColumns, "BiColumns", "named", reactIconsBi],
  [BiCommand, "BiCommand", "named", reactIconsBi],
  [BiCommentAdd, "BiCommentAdd", "named", reactIconsBi],
  [BiCommentCheck, "BiCommentCheck", "named", reactIconsBi],
  [BiCommentDetail, "BiCommentDetail", "named", reactIconsBi],
  [BiCommentDots, "BiCommentDots", "named", reactIconsBi],
  [BiCommentEdit, "BiCommentEdit", "named", reactIconsBi],
  [BiCommentError, "BiCommentError", "named", reactIconsBi],
  [BiCommentMinus, "BiCommentMinus", "named", reactIconsBi],
  [BiCommentX, "BiCommentX", "named", reactIconsBi],
  [BiComment, "BiComment", "named", reactIconsBi],
  [BiCompass, "BiCompass", "named", reactIconsBi],
  [BiConfused, "BiConfused", "named", reactIconsBi],
  [BiConversation, "BiConversation", "named", reactIconsBi],
  [BiCookie, "BiCookie", "named", reactIconsBi],
  [BiCool, "BiCool", "named", reactIconsBi],
  [BiCopyAlt, "BiCopyAlt", "named", reactIconsBi],
  [BiCopy, "BiCopy", "named", reactIconsBi],
  [BiCopyright, "BiCopyright", "named", reactIconsBi],
  [BiCreditCardAlt, "BiCreditCardAlt", "named", reactIconsBi],
  [BiCreditCardFront, "BiCreditCardFront", "named", reactIconsBi],
  [BiCreditCard, "BiCreditCard", "named", reactIconsBi],
  [BiCricketBall, "BiCricketBall", "named", reactIconsBi],
  [BiCrop, "BiCrop", "named", reactIconsBi],
  [BiCross, "BiCross", "named", reactIconsBi],
  [BiCrosshair, "BiCrosshair", "named", reactIconsBi],
  [BiCrown, "BiCrown", "named", reactIconsBi],
  [BiCubeAlt, "BiCubeAlt", "named", reactIconsBi],
  [BiCube, "BiCube", "named", reactIconsBi],
  [BiCuboid, "BiCuboid", "named", reactIconsBi],
  [BiCurrentLocation, "BiCurrentLocation", "named", reactIconsBi],
  [BiCustomize, "BiCustomize", "named", reactIconsBi],
  [BiCut, "BiCut", "named", reactIconsBi],
  [BiCycling, "BiCycling", "named", reactIconsBi],
  [BiCylinder, "BiCylinder", "named", reactIconsBi],
  [BiData, "BiData", "named", reactIconsBi],
  [BiDesktop, "BiDesktop", "named", reactIconsBi],
  [BiDetail, "BiDetail", "named", reactIconsBi],
  [BiDevices, "BiDevices", "named", reactIconsBi],
  [BiDialpadAlt, "BiDialpadAlt", "named", reactIconsBi],
  [BiDialpad, "BiDialpad", "named", reactIconsBi],
  [BiDiamond, "BiDiamond", "named", reactIconsBi],
  [BiDice1, "BiDice1", "named", reactIconsBi],
  [BiDice2, "BiDice2", "named", reactIconsBi],
  [BiDice3, "BiDice3", "named", reactIconsBi],
  [BiDice4, "BiDice4", "named", reactIconsBi],
  [BiDice5, "BiDice5", "named", reactIconsBi],
  [BiDice6, "BiDice6", "named", reactIconsBi],
  [BiDirections, "BiDirections", "named", reactIconsBi],
  [BiDisc, "BiDisc", "named", reactIconsBi],
  [BiDish, "BiDish", "named", reactIconsBi],
  [BiDislike, "BiDislike", "named", reactIconsBi],
  [BiDizzy, "BiDizzy", "named", reactIconsBi],
  [BiDna, "BiDna", "named", reactIconsBi],
  [BiDockBottom, "BiDockBottom", "named", reactIconsBi],
  [BiDockLeft, "BiDockLeft", "named", reactIconsBi],
  [BiDockRight, "BiDockRight", "named", reactIconsBi],
  [BiDockTop, "BiDockTop", "named", reactIconsBi],
  [BiDollarCircle, "BiDollarCircle", "named", reactIconsBi],
  [BiDollar, "BiDollar", "named", reactIconsBi],
  [BiDonateBlood, "BiDonateBlood", "named", reactIconsBi],
  [BiDonateHeart, "BiDonateHeart", "named", reactIconsBi],
  [BiDoorOpen, "BiDoorOpen", "named", reactIconsBi],
  [BiDotsHorizontalRounded, "BiDotsHorizontalRounded", "named", reactIconsBi],
  [BiDotsHorizontal, "BiDotsHorizontal", "named", reactIconsBi],
  [BiDotsVerticalRounded, "BiDotsVerticalRounded", "named", reactIconsBi],
  [BiDotsVertical, "BiDotsVertical", "named", reactIconsBi],
  [BiDoughnutChart, "BiDoughnutChart", "named", reactIconsBi],
  [BiDownArrowAlt, "BiDownArrowAlt", "named", reactIconsBi],
  [BiDownArrowCircle, "BiDownArrowCircle", "named", reactIconsBi],
  [BiDownArrow, "BiDownArrow", "named", reactIconsBi],
  [BiDownload, "BiDownload", "named", reactIconsBi],
  [BiDownvote, "BiDownvote", "named", reactIconsBi],
  [BiDrink, "BiDrink", "named", reactIconsBi],
  [BiDroplet, "BiDroplet", "named", reactIconsBi],
  [BiDumbbell, "BiDumbbell", "named", reactIconsBi],
  [BiDuplicate, "BiDuplicate", "named", reactIconsBi],
  [BiEditAlt, "BiEditAlt", "named", reactIconsBi],
  [BiEdit, "BiEdit", "named", reactIconsBi],
  [BiEnvelopeOpen, "BiEnvelopeOpen", "named", reactIconsBi],
  [BiEnvelope, "BiEnvelope", "named", reactIconsBi],
  [BiEqualizer, "BiEqualizer", "named", reactIconsBi],
  [BiEraser, "BiEraser", "named", reactIconsBi],
  [BiErrorAlt, "BiErrorAlt", "named", reactIconsBi],
  [BiErrorCircle, "BiErrorCircle", "named", reactIconsBi],
  [BiError, "BiError", "named", reactIconsBi],
  [BiEuro, "BiEuro", "named", reactIconsBi],
  [BiExclude, "BiExclude", "named", reactIconsBi],
  [BiExitFullscreen, "BiExitFullscreen", "named", reactIconsBi],
  [BiExit, "BiExit", "named", reactIconsBi],
  [BiExpandAlt, "BiExpandAlt", "named", reactIconsBi],
  [BiExpandHorizontal, "BiExpandHorizontal", "named", reactIconsBi],
  [BiExpandVertical, "BiExpandVertical", "named", reactIconsBi],
  [BiExpand, "BiExpand", "named", reactIconsBi],
  [BiExport, "BiExport", "named", reactIconsBi],
  [BiExtension, "BiExtension", "named", reactIconsBi],
  [BiFace, "BiFace", "named", reactIconsBi],
  [BiFastForwardCircle, "BiFastForwardCircle", "named", reactIconsBi],
  [BiFastForward, "BiFastForward", "named", reactIconsBi],
  [BiFemaleSign, "BiFemaleSign", "named", reactIconsBi],
  [BiFemale, "BiFemale", "named", reactIconsBi],
  [BiFileBlank, "BiFileBlank", "named", reactIconsBi],
  [BiFileFind, "BiFileFind", "named", reactIconsBi],
  [BiFile, "BiFile", "named", reactIconsBi],
  [BiFilm, "BiFilm", "named", reactIconsBi],
  [BiFilterAlt, "BiFilterAlt", "named", reactIconsBi],
  [BiFilter, "BiFilter", "named", reactIconsBi],
  [BiFingerprint, "BiFingerprint", "named", reactIconsBi],
  [BiFirstAid, "BiFirstAid", "named", reactIconsBi],
  [BiFirstPage, "BiFirstPage", "named", reactIconsBi],
  [BiFlag, "BiFlag", "named", reactIconsBi],
  [BiFolderMinus, "BiFolderMinus", "named", reactIconsBi],
  [BiFolderOpen, "BiFolderOpen", "named", reactIconsBi],
  [BiFolderPlus, "BiFolderPlus", "named", reactIconsBi],
  [BiFolder, "BiFolder", "named", reactIconsBi],
  [BiFontColor, "BiFontColor", "named", reactIconsBi],
  [BiFontFamily, "BiFontFamily", "named", reactIconsBi],
  [BiFontSize, "BiFontSize", "named", reactIconsBi],
  [BiFont, "BiFont", "named", reactIconsBi],
  [BiFoodMenu, "BiFoodMenu", "named", reactIconsBi],
  [BiFoodTag, "BiFoodTag", "named", reactIconsBi],
  [BiFootball, "BiFootball", "named", reactIconsBi],
  [BiFork, "BiFork", "named", reactIconsBi],
  [BiFridge, "BiFridge", "named", reactIconsBi],
  [BiFullscreen, "BiFullscreen", "named", reactIconsBi],
  [BiGame, "BiGame", "named", reactIconsBi],
  [BiGasPump, "BiGasPump", "named", reactIconsBi],
  [BiGhost, "BiGhost", "named", reactIconsBi],
  [BiGift, "BiGift", "named", reactIconsBi],
  [BiGitBranch, "BiGitBranch", "named", reactIconsBi],
  [BiGitCommit, "BiGitCommit", "named", reactIconsBi],
  [BiGitCompare, "BiGitCompare", "named", reactIconsBi],
  [BiGitMerge, "BiGitMerge", "named", reactIconsBi],
  [BiGitPullRequest, "BiGitPullRequest", "named", reactIconsBi],
  [BiGitRepoForked, "BiGitRepoForked", "named", reactIconsBi],
  [BiGlassesAlt, "BiGlassesAlt", "named", reactIconsBi],
  [BiGlasses, "BiGlasses", "named", reactIconsBi],
  [BiGlobeAlt, "BiGlobeAlt", "named", reactIconsBi],
  [BiGlobe, "BiGlobe", "named", reactIconsBi],
  [BiGridAlt, "BiGridAlt", "named", reactIconsBi],
  [BiGridHorizontal, "BiGridHorizontal", "named", reactIconsBi],
  [BiGridSmall, "BiGridSmall", "named", reactIconsBi],
  [BiGridVertical, "BiGridVertical", "named", reactIconsBi],
  [BiGrid, "BiGrid", "named", reactIconsBi],
  [BiGroup, "BiGroup", "named", reactIconsBi],
  [BiHandicap, "BiHandicap", "named", reactIconsBi],
  [BiHappyAlt, "BiHappyAlt", "named", reactIconsBi],
  [BiHappyBeaming, "BiHappyBeaming", "named", reactIconsBi],
  [BiHappyHeartEyes, "BiHappyHeartEyes", "named", reactIconsBi],
  [BiHappy, "BiHappy", "named", reactIconsBi],
  [BiHardHat, "BiHardHat", "named", reactIconsBi],
  [BiHash, "BiHash", "named", reactIconsBi],
  [BiHdd, "BiHdd", "named", reactIconsBi],
  [BiHeading, "BiHeading", "named", reactIconsBi],
  [BiHeadphone, "BiHeadphone", "named", reactIconsBi],
  [BiHealth, "BiHealth", "named", reactIconsBi],
  [BiHeartCircle, "BiHeartCircle", "named", reactIconsBi],
  [BiHeartSquare, "BiHeartSquare", "named", reactIconsBi],
  [BiHeart, "BiHeart", "named", reactIconsBi],
  [BiHelpCircle, "BiHelpCircle", "named", reactIconsBi],
  [BiHide, "BiHide", "named", reactIconsBi],
  [BiHighlight, "BiHighlight", "named", reactIconsBi],
  [BiHistory, "BiHistory", "named", reactIconsBi],
  [BiHive, "BiHive", "named", reactIconsBi],
  [BiHomeAlt2, "BiHomeAlt2", "named", reactIconsBi],
  [BiHomeAlt, "BiHomeAlt", "named", reactIconsBi],
  [BiHomeCircle, "BiHomeCircle", "named", reactIconsBi],
  [BiHomeHeart, "BiHomeHeart", "named", reactIconsBi],
  [BiHomeSmile, "BiHomeSmile", "named", reactIconsBi],
  [BiHome, "BiHome", "named", reactIconsBi],
  [BiHorizontalCenter, "BiHorizontalCenter", "named", reactIconsBi],
  [BiHorizontalLeft, "BiHorizontalLeft", "named", reactIconsBi],
  [BiHorizontalRight, "BiHorizontalRight", "named", reactIconsBi],
  [BiHotel, "BiHotel", "named", reactIconsBi],
  [BiHourglass, "BiHourglass", "named", reactIconsBi],
  [BiIdCard, "BiIdCard", "named", reactIconsBi],
  [BiImageAdd, "BiImageAdd", "named", reactIconsBi],
  [BiImageAlt, "BiImageAlt", "named", reactIconsBi],
  [BiImage, "BiImage", "named", reactIconsBi],
  [BiImages, "BiImages", "named", reactIconsBi],
  [BiImport, "BiImport", "named", reactIconsBi],
  [BiInfinite, "BiInfinite", "named", reactIconsBi],
  [BiInfoCircle, "BiInfoCircle", "named", reactIconsBi],
  [BiInfoSquare, "BiInfoSquare", "named", reactIconsBi],
  [BiInjection, "BiInjection", "named", reactIconsBi],
  [BiIntersect, "BiIntersect", "named", reactIconsBi],
  [BiItalic, "BiItalic", "named", reactIconsBi],
  [BiJoystickAlt, "BiJoystickAlt", "named", reactIconsBi],
  [BiJoystickButton, "BiJoystickButton", "named", reactIconsBi],
  [BiJoystick, "BiJoystick", "named", reactIconsBi],
  [BiKey, "BiKey", "named", reactIconsBi],
  [BiKnife, "BiKnife", "named", reactIconsBi],
  [BiLabel, "BiLabel", "named", reactIconsBi],
  [BiLandscape, "BiLandscape", "named", reactIconsBi],
  [BiLaptop, "BiLaptop", "named", reactIconsBi],
  [BiLastPage, "BiLastPage", "named", reactIconsBi],
  [BiLaugh, "BiLaugh", "named", reactIconsBi],
  [BiLayerMinus, "BiLayerMinus", "named", reactIconsBi],
  [BiLayerPlus, "BiLayerPlus", "named", reactIconsBi],
  [BiLayer, "BiLayer", "named", reactIconsBi],
  [BiLayout, "BiLayout", "named", reactIconsBi],
  [BiLeaf, "BiLeaf", "named", reactIconsBi],
  [BiLeftArrowAlt, "BiLeftArrowAlt", "named", reactIconsBi],
  [BiLeftArrowCircle, "BiLeftArrowCircle", "named", reactIconsBi],
  [BiLeftArrow, "BiLeftArrow", "named", reactIconsBi],
  [BiLeftDownArrowCircle, "BiLeftDownArrowCircle", "named", reactIconsBi],
  [BiLeftIndent, "BiLeftIndent", "named", reactIconsBi],
  [BiLeftTopArrowCircle, "BiLeftTopArrowCircle", "named", reactIconsBi],
  [BiLemon, "BiLemon", "named", reactIconsBi],
  [BiLibrary, "BiLibrary", "named", reactIconsBi],
  [BiLike, "BiLike", "named", reactIconsBi],
  [BiLineChartDown, "BiLineChartDown", "named", reactIconsBi],
  [BiLineChart, "BiLineChart", "named", reactIconsBi],
  [BiLinkAlt, "BiLinkAlt", "named", reactIconsBi],
  [BiLinkExternal, "BiLinkExternal", "named", reactIconsBi],
  [BiLink, "BiLink", "named", reactIconsBi],
  [BiLira, "BiLira", "named", reactIconsBi],
  [BiListCheck, "BiListCheck", "named", reactIconsBi],
  [BiListMinus, "BiListMinus", "named", reactIconsBi],
  [BiListOl, "BiListOl", "named", reactIconsBi],
  [BiListPlus, "BiListPlus", "named", reactIconsBi],
  [BiListUl, "BiListUl", "named", reactIconsBi],
  [BiLoaderAlt, "BiLoaderAlt", "named", reactIconsBi],
  [BiLoaderCircle, "BiLoaderCircle", "named", reactIconsBi],
  [BiLoader, "BiLoader", "named", reactIconsBi],
  [BiLocationPlus, "BiLocationPlus", "named", reactIconsBi],
  [BiLockAlt, "BiLockAlt", "named", reactIconsBi],
  [BiLockOpenAlt, "BiLockOpenAlt", "named", reactIconsBi],
  [BiLockOpen, "BiLockOpen", "named", reactIconsBi],
  [BiLock, "BiLock", "named", reactIconsBi],
  [BiLogInCircle, "BiLogInCircle", "named", reactIconsBi],
  [BiLogIn, "BiLogIn", "named", reactIconsBi],
  [BiLogOutCircle, "BiLogOutCircle", "named", reactIconsBi],
  [BiLogOut, "BiLogOut", "named", reactIconsBi],
  [BiLowVision, "BiLowVision", "named", reactIconsBi],
  [BiMagnet, "BiMagnet", "named", reactIconsBi],
  [BiMailSend, "BiMailSend", "named", reactIconsBi],
  [BiMaleFemale, "BiMaleFemale", "named", reactIconsBi],
  [BiMaleSign, "BiMaleSign", "named", reactIconsBi],
  [BiMale, "BiMale", "named", reactIconsBi],
  [BiMapAlt, "BiMapAlt", "named", reactIconsBi],
  [BiMapPin, "BiMapPin", "named", reactIconsBi],
  [BiMap, "BiMap", "named", reactIconsBi],
  [BiMask, "BiMask", "named", reactIconsBi],
  [BiMath, "BiMath", "named", reactIconsBi],
  [BiMedal, "BiMedal", "named", reactIconsBi],
  [BiMehAlt, "BiMehAlt", "named", reactIconsBi],
  [BiMehBlank, "BiMehBlank", "named", reactIconsBi],
  [BiMeh, "BiMeh", "named", reactIconsBi],
  [BiMemoryCard, "BiMemoryCard", "named", reactIconsBi],
  [BiMenuAltLeft, "BiMenuAltLeft", "named", reactIconsBi],
  [BiMenuAltRight, "BiMenuAltRight", "named", reactIconsBi],
  [BiMenu, "BiMenu", "named", reactIconsBi],
  [BiMerge, "BiMerge", "named", reactIconsBi],
  [BiMessageAdd, "BiMessageAdd", "named", reactIconsBi],
  [BiMessageAltAdd, "BiMessageAltAdd", "named", reactIconsBi],
  [BiMessageAltCheck, "BiMessageAltCheck", "named", reactIconsBi],
  [BiMessageAltDetail, "BiMessageAltDetail", "named", reactIconsBi],
  [BiMessageAltDots, "BiMessageAltDots", "named", reactIconsBi],
  [BiMessageAltEdit, "BiMessageAltEdit", "named", reactIconsBi],
  [BiMessageAltError, "BiMessageAltError", "named", reactIconsBi],
  [BiMessageAltMinus, "BiMessageAltMinus", "named", reactIconsBi],
  [BiMessageAltX, "BiMessageAltX", "named", reactIconsBi],
  [BiMessageAlt, "BiMessageAlt", "named", reactIconsBi],
  [BiMessageCheck, "BiMessageCheck", "named", reactIconsBi],
  [BiMessageDetail, "BiMessageDetail", "named", reactIconsBi],
  [BiMessageDots, "BiMessageDots", "named", reactIconsBi],
  [BiMessageEdit, "BiMessageEdit", "named", reactIconsBi],
  [BiMessageError, "BiMessageError", "named", reactIconsBi],
  [BiMessageMinus, "BiMessageMinus", "named", reactIconsBi],
  [BiMessageRoundedAdd, "BiMessageRoundedAdd", "named", reactIconsBi],
  [BiMessageRoundedCheck, "BiMessageRoundedCheck", "named", reactIconsBi],
  [BiMessageRoundedDetail, "BiMessageRoundedDetail", "named", reactIconsBi],
  [BiMessageRoundedDots, "BiMessageRoundedDots", "named", reactIconsBi],
  [BiMessageRoundedEdit, "BiMessageRoundedEdit", "named", reactIconsBi],
  [BiMessageRoundedError, "BiMessageRoundedError", "named", reactIconsBi],
  [BiMessageRoundedMinus, "BiMessageRoundedMinus", "named", reactIconsBi],
  [BiMessageRoundedX, "BiMessageRoundedX", "named", reactIconsBi],
  [BiMessageRounded, "BiMessageRounded", "named", reactIconsBi],
  [BiMessageSquareAdd, "BiMessageSquareAdd", "named", reactIconsBi],
  [BiMessageSquareCheck, "BiMessageSquareCheck", "named", reactIconsBi],
  [BiMessageSquareDetail, "BiMessageSquareDetail", "named", reactIconsBi],
  [BiMessageSquareDots, "BiMessageSquareDots", "named", reactIconsBi],
  [BiMessageSquareEdit, "BiMessageSquareEdit", "named", reactIconsBi],
  [BiMessageSquareError, "BiMessageSquareError", "named", reactIconsBi],
  [BiMessageSquareMinus, "BiMessageSquareMinus", "named", reactIconsBi],
  [BiMessageSquareX, "BiMessageSquareX", "named", reactIconsBi],
  [BiMessageSquare, "BiMessageSquare", "named", reactIconsBi],
  [BiMessageX, "BiMessageX", "named", reactIconsBi],
  [BiMessage, "BiMessage", "named", reactIconsBi],
  [BiMeteor, "BiMeteor", "named", reactIconsBi],
  [BiMicrochip, "BiMicrochip", "named", reactIconsBi],
  [BiMicrophoneOff, "BiMicrophoneOff", "named", reactIconsBi],
  [BiMicrophone, "BiMicrophone", "named", reactIconsBi],
  [BiMinusBack, "BiMinusBack", "named", reactIconsBi],
  [BiMinusCircle, "BiMinusCircle", "named", reactIconsBi],
  [BiMinusFront, "BiMinusFront", "named", reactIconsBi],
  [BiMinus, "BiMinus", "named", reactIconsBi],
  [BiMobileAlt, "BiMobileAlt", "named", reactIconsBi],
  [BiMobileLandscape, "BiMobileLandscape", "named", reactIconsBi],
  [BiMobileVibration, "BiMobileVibration", "named", reactIconsBi],
  [BiMobile, "BiMobile", "named", reactIconsBi],
  [BiMoneyWithdraw, "BiMoneyWithdraw", "named", reactIconsBi],
  [BiMoney, "BiMoney", "named", reactIconsBi],
  [BiMoon, "BiMoon", "named", reactIconsBi],
  [BiMouseAlt, "BiMouseAlt", "named", reactIconsBi],
  [BiMouse, "BiMouse", "named", reactIconsBi],
  [BiMoveHorizontal, "BiMoveHorizontal", "named", reactIconsBi],
  [BiMoveVertical, "BiMoveVertical", "named", reactIconsBi],
  [BiMove, "BiMove", "named", reactIconsBi],
  [BiMoviePlay, "BiMoviePlay", "named", reactIconsBi],
  [BiMovie, "BiMovie", "named", reactIconsBi],
  [BiMusic, "BiMusic", "named", reactIconsBi],
  [BiNavigation, "BiNavigation", "named", reactIconsBi],
  [BiNetworkChart, "BiNetworkChart", "named", reactIconsBi],
  [BiNews, "BiNews", "named", reactIconsBi],
  [BiNoEntry, "BiNoEntry", "named", reactIconsBi],
  [BiNoSignal, "BiNoSignal", "named", reactIconsBi],
  [BiNote, "BiNote", "named", reactIconsBi],
  [BiNotepad, "BiNotepad", "named", reactIconsBi],
  [BiNotificationOff, "BiNotificationOff", "named", reactIconsBi],
  [BiNotification, "BiNotification", "named", reactIconsBi],
  [BiObjectsHorizontalCenter, "BiObjectsHorizontalCenter", "named", reactIconsBi],
  [BiObjectsHorizontalLeft, "BiObjectsHorizontalLeft", "named", reactIconsBi],
  [BiObjectsHorizontalRight, "BiObjectsHorizontalRight", "named", reactIconsBi],
  [BiObjectsVerticalBottom, "BiObjectsVerticalBottom", "named", reactIconsBi],
  [BiObjectsVerticalCenter, "BiObjectsVerticalCenter", "named", reactIconsBi],
  [BiObjectsVerticalTop, "BiObjectsVerticalTop", "named", reactIconsBi],
  [BiOutline, "BiOutline", "named", reactIconsBi],
  [BiPackage, "BiPackage", "named", reactIconsBi],
  [BiPaintRoll, "BiPaintRoll", "named", reactIconsBi],
  [BiPaint, "BiPaint", "named", reactIconsBi],
  [BiPalette, "BiPalette", "named", reactIconsBi],
  [BiPaperPlane, "BiPaperPlane", "named", reactIconsBi],
  [BiPaperclip, "BiPaperclip", "named", reactIconsBi],
  [BiParagraph, "BiParagraph", "named", reactIconsBi],
  [BiParty, "BiParty", "named", reactIconsBi],
  [BiPaste, "BiPaste", "named", reactIconsBi],
  [BiPauseCircle, "BiPauseCircle", "named", reactIconsBi],
  [BiPause, "BiPause", "named", reactIconsBi],
  [BiPen, "BiPen", "named", reactIconsBi],
  [BiPencil, "BiPencil", "named", reactIconsBi],
  [BiPhoneCall, "BiPhoneCall", "named", reactIconsBi],
  [BiPhoneIncoming, "BiPhoneIncoming", "named", reactIconsBi],
  [BiPhoneOff, "BiPhoneOff", "named", reactIconsBi],
  [BiPhoneOutgoing, "BiPhoneOutgoing", "named", reactIconsBi],
  [BiPhone, "BiPhone", "named", reactIconsBi],
  [BiPhotoAlbum, "BiPhotoAlbum", "named", reactIconsBi],
  [BiPieChartAlt2, "BiPieChartAlt2", "named", reactIconsBi],
  [BiPieChartAlt, "BiPieChartAlt", "named", reactIconsBi],
  [BiPieChart, "BiPieChart", "named", reactIconsBi],
  [BiPin, "BiPin", "named", reactIconsBi],
  [BiPlanet, "BiPlanet", "named", reactIconsBi],
  [BiPlayCircle, "BiPlayCircle", "named", reactIconsBi],
  [BiPlay, "BiPlay", "named", reactIconsBi],
  [BiPlug, "BiPlug", "named", reactIconsBi],
  [BiPlusCircle, "BiPlusCircle", "named", reactIconsBi],
  [BiPlusMedical, "BiPlusMedical", "named", reactIconsBi],
  [BiPlus, "BiPlus", "named", reactIconsBi],
  [BiPodcast, "BiPodcast", "named", reactIconsBi],
  [BiPointer, "BiPointer", "named", reactIconsBi],
  [BiPoll, "BiPoll", "named", reactIconsBi],
  [BiPolygon, "BiPolygon", "named", reactIconsBi],
  [BiPopsicle, "BiPopsicle", "named", reactIconsBi],
  [BiPound, "BiPound", "named", reactIconsBi],
  [BiPowerOff, "BiPowerOff", "named", reactIconsBi],
  [BiPrinter, "BiPrinter", "named", reactIconsBi],
  [BiPulse, "BiPulse", "named", reactIconsBi],
  [BiPurchaseTagAlt, "BiPurchaseTagAlt", "named", reactIconsBi],
  [BiPurchaseTag, "BiPurchaseTag", "named", reactIconsBi],
  [BiPyramid, "BiPyramid", "named", reactIconsBi],
  [BiQrScan, "BiQrScan", "named", reactIconsBi],
  [BiQr, "BiQr", "named", reactIconsBi],
  [BiQuestionMark, "BiQuestionMark", "named", reactIconsBi],
  [BiRadar, "BiRadar", "named", reactIconsBi],
  [BiRadioCircleMarked, "BiRadioCircleMarked", "named", reactIconsBi],
  [BiRadioCircle, "BiRadioCircle", "named", reactIconsBi],
  [BiRadio, "BiRadio", "named", reactIconsBi],
  [BiReceipt, "BiReceipt", "named", reactIconsBi],
  [BiRectangle, "BiRectangle", "named", reactIconsBi],
  [BiRecycle, "BiRecycle", "named", reactIconsBi],
  [BiRedo, "BiRedo", "named", reactIconsBi],
  [BiReflectHorizontal, "BiReflectHorizontal", "named", reactIconsBi],
  [BiReflectVertical, "BiReflectVertical", "named", reactIconsBi],
  [BiRefresh, "BiRefresh", "named", reactIconsBi],
  [BiRegistered, "BiRegistered", "named", reactIconsBi],
  [BiRename, "BiRename", "named", reactIconsBi],
  [BiRepeat, "BiRepeat", "named", reactIconsBi],
  [BiReplyAll, "BiReplyAll", "named", reactIconsBi],
  [BiReply, "BiReply", "named", reactIconsBi],
  [BiRepost, "BiRepost", "named", reactIconsBi],
  [BiReset, "BiReset", "named", reactIconsBi],
  [BiRestaurant, "BiRestaurant", "named", reactIconsBi],
  [BiRevision, "BiRevision", "named", reactIconsBi],
  [BiRewindCircle, "BiRewindCircle", "named", reactIconsBi],
  [BiRewind, "BiRewind", "named", reactIconsBi],
  [BiRfid, "BiRfid", "named", reactIconsBi],
  [BiRightArrowAlt, "BiRightArrowAlt", "named", reactIconsBi],
  [BiRightArrowCircle, "BiRightArrowCircle", "named", reactIconsBi],
  [BiRightArrow, "BiRightArrow", "named", reactIconsBi],
  [BiRightDownArrowCircle, "BiRightDownArrowCircle", "named", reactIconsBi],
  [BiRightIndent, "BiRightIndent", "named", reactIconsBi],
  [BiRightTopArrowCircle, "BiRightTopArrowCircle", "named", reactIconsBi],
  [BiRocket, "BiRocket", "named", reactIconsBi],
  [BiRotateLeft, "BiRotateLeft", "named", reactIconsBi],
  [BiRotateRight, "BiRotateRight", "named", reactIconsBi],
  [BiRss, "BiRss", "named", reactIconsBi],
  [BiRuble, "BiRuble", "named", reactIconsBi],
  [BiRuler, "BiRuler", "named", reactIconsBi],
  [BiRun, "BiRun", "named", reactIconsBi],
  [BiRupee, "BiRupee", "named", reactIconsBi],
  [BiSad, "BiSad", "named", reactIconsBi],
  [BiSave, "BiSave", "named", reactIconsBi],
  [BiScan, "BiScan", "named", reactIconsBi],
  [BiScatterChart, "BiScatterChart", "named", reactIconsBi],
  [BiScreenshot, "BiScreenshot", "named", reactIconsBi],
  [BiSearchAlt2, "BiSearchAlt2", "named", reactIconsBi],
  [BiSearchAlt, "BiSearchAlt", "named", reactIconsBi],
  [BiSearch, "BiSearch", "named", reactIconsBi],
  [BiSelectMultiple, "BiSelectMultiple", "named", reactIconsBi],
  [BiSelection, "BiSelection", "named", reactIconsBi],
  [BiSend, "BiSend", "named", reactIconsBi],
  [BiServer, "BiServer", "named", reactIconsBi],
  [BiShapeCircle, "BiShapeCircle", "named", reactIconsBi],
  [BiShapePolygon, "BiShapePolygon", "named", reactIconsBi],
  [BiShapeSquare, "BiShapeSquare", "named", reactIconsBi],
  [BiShapeTriangle, "BiShapeTriangle", "named", reactIconsBi],
  [BiShareAlt, "BiShareAlt", "named", reactIconsBi],
  [BiShare, "BiShare", "named", reactIconsBi],
  [BiShekel, "BiShekel", "named", reactIconsBi],
  [BiShieldAlt2, "BiShieldAlt2", "named", reactIconsBi],
  [BiShieldAlt, "BiShieldAlt", "named", reactIconsBi],
  [BiShieldMinus, "BiShieldMinus", "named", reactIconsBi],
  [BiShieldPlus, "BiShieldPlus", "named", reactIconsBi],
  [BiShieldQuarter, "BiShieldQuarter", "named", reactIconsBi],
  [BiShieldX, "BiShieldX", "named", reactIconsBi],
  [BiShield, "BiShield", "named", reactIconsBi],
  [BiShocked, "BiShocked", "named", reactIconsBi],
  [BiShoppingBag, "BiShoppingBag", "named", reactIconsBi],
  [BiShowAlt, "BiShowAlt", "named", reactIconsBi],
  [BiShow, "BiShow", "named", reactIconsBi],
  [BiShower, "BiShower", "named", reactIconsBi],
  [BiShuffle, "BiShuffle", "named", reactIconsBi],
  [BiSidebar, "BiSidebar", "named", reactIconsBi],
  [BiSignal1, "BiSignal1", "named", reactIconsBi],
  [BiSignal2, "BiSignal2", "named", reactIconsBi],
  [BiSignal3, "BiSignal3", "named", reactIconsBi],
  [BiSignal4, "BiSignal4", "named", reactIconsBi],
  [BiSignal5, "BiSignal5", "named", reactIconsBi],
  [BiSitemap, "BiSitemap", "named", reactIconsBi],
  [BiSkipNextCircle, "BiSkipNextCircle", "named", reactIconsBi],
  [BiSkipNext, "BiSkipNext", "named", reactIconsBi],
  [BiSkipPreviousCircle, "BiSkipPreviousCircle", "named", reactIconsBi],
  [BiSkipPrevious, "BiSkipPrevious", "named", reactIconsBi],
  [BiSleepy, "BiSleepy", "named", reactIconsBi],
  [BiSliderAlt, "BiSliderAlt", "named", reactIconsBi],
  [BiSlider, "BiSlider", "named", reactIconsBi],
  [BiSlideshow, "BiSlideshow", "named", reactIconsBi],
  [BiSmile, "BiSmile", "named", reactIconsBi],
  [BiSortAZ, "BiSortAZ", "named", reactIconsBi],
  [BiSortAlt2, "BiSortAlt2", "named", reactIconsBi],
  [BiSortDown, "BiSortDown", "named", reactIconsBi],
  [BiSortUp, "BiSortUp", "named", reactIconsBi],
  [BiSortZA, "BiSortZA", "named", reactIconsBi],
  [BiSort, "BiSort", "named", reactIconsBi],
  [BiSpa, "BiSpa", "named", reactIconsBi],
  [BiSpaceBar, "BiSpaceBar", "named", reactIconsBi],
  [BiSpeaker, "BiSpeaker", "named", reactIconsBi],
  [BiSprayCan, "BiSprayCan", "named", reactIconsBi],
  [BiSpreadsheet, "BiSpreadsheet", "named", reactIconsBi],
  [BiSquareRounded, "BiSquareRounded", "named", reactIconsBi],
  [BiSquare, "BiSquare", "named", reactIconsBi],
  [BiStar, "BiStar", "named", reactIconsBi],
  [BiStation, "BiStation", "named", reactIconsBi],
  [BiStats, "BiStats", "named", reactIconsBi],
  [BiSticker, "BiSticker", "named", reactIconsBi],
  [BiStopCircle, "BiStopCircle", "named", reactIconsBi],
  [BiStop, "BiStop", "named", reactIconsBi],
  [BiStopwatch, "BiStopwatch", "named", reactIconsBi],
  [BiStoreAlt, "BiStoreAlt", "named", reactIconsBi],
  [BiStore, "BiStore", "named", reactIconsBi],
  [BiStreetView, "BiStreetView", "named", reactIconsBi],
  [BiStrikethrough, "BiStrikethrough", "named", reactIconsBi],
  [BiSubdirectoryLeft, "BiSubdirectoryLeft", "named", reactIconsBi],
  [BiSubdirectoryRight, "BiSubdirectoryRight", "named", reactIconsBi],
  [BiSun, "BiSun", "named", reactIconsBi],
  [BiSupport, "BiSupport", "named", reactIconsBi],
  [BiSushi, "BiSushi", "named", reactIconsBi],
  [BiSwim, "BiSwim", "named", reactIconsBi],
  [BiSync, "BiSync", "named", reactIconsBi],
  [BiTab, "BiTab", "named", reactIconsBi],
  [BiTable, "BiTable", "named", reactIconsBi],
  [BiTachometer, "BiTachometer", "named", reactIconsBi],
  [BiTagAlt, "BiTagAlt", "named", reactIconsBi],
  [BiTag, "BiTag", "named", reactIconsBi],
  [BiTargetLock, "BiTargetLock", "named", reactIconsBi],
  [BiTaskX, "BiTaskX", "named", reactIconsBi],
  [BiTask, "BiTask", "named", reactIconsBi],
  [BiTaxi, "BiTaxi", "named", reactIconsBi],
  [BiTennisBall, "BiTennisBall", "named", reactIconsBi],
  [BiTerminal, "BiTerminal", "named", reactIconsBi],
  [BiTestTube, "BiTestTube", "named", reactIconsBi],
  [BiText, "BiText", "named", reactIconsBi],
  [BiTimeFive, "BiTimeFive", "named", reactIconsBi],
  [BiTime, "BiTime", "named", reactIconsBi],
  [BiTimer, "BiTimer", "named", reactIconsBi],
  [BiTired, "BiTired", "named", reactIconsBi],
  [BiToggleLeft, "BiToggleLeft", "named", reactIconsBi],
  [BiToggleRight, "BiToggleRight", "named", reactIconsBi],
  [BiTone, "BiTone", "named", reactIconsBi],
  [BiTrafficCone, "BiTrafficCone", "named", reactIconsBi],
  [BiTrain, "BiTrain", "named", reactIconsBi],
  [BiTransferAlt, "BiTransferAlt", "named", reactIconsBi],
  [BiTransfer, "BiTransfer", "named", reactIconsBi],
  [BiTrashAlt, "BiTrashAlt", "named", reactIconsBi],
  [BiTrash, "BiTrash", "named", reactIconsBi],
  [BiTrendingDown, "BiTrendingDown", "named", reactIconsBi],
  [BiTrendingUp, "BiTrendingUp", "named", reactIconsBi],
  [BiTrim, "BiTrim", "named", reactIconsBi],
  [BiTrip, "BiTrip", "named", reactIconsBi],
  [BiTrophy, "BiTrophy", "named", reactIconsBi],
  [BiTv, "BiTv", "named", reactIconsBi],
  [BiUnderline, "BiUnderline", "named", reactIconsBi],
  [BiUndo, "BiUndo", "named", reactIconsBi],
  [BiUnite, "BiUnite", "named", reactIconsBi],
  [BiUniversalAccess, "BiUniversalAccess", "named", reactIconsBi],
  [BiUnlink, "BiUnlink", "named", reactIconsBi],
  [BiUpArrowAlt, "BiUpArrowAlt", "named", reactIconsBi],
  [BiUpArrowCircle, "BiUpArrowCircle", "named", reactIconsBi],
  [BiUpArrow, "BiUpArrow", "named", reactIconsBi],
  [BiUpload, "BiUpload", "named", reactIconsBi],
  [BiUpsideDown, "BiUpsideDown", "named", reactIconsBi],
  [BiUpvote, "BiUpvote", "named", reactIconsBi],
  [BiUsb, "BiUsb", "named", reactIconsBi],
  [BiUserCheck, "BiUserCheck", "named", reactIconsBi],
  [BiUserCircle, "BiUserCircle", "named", reactIconsBi],
  [BiUserMinus, "BiUserMinus", "named", reactIconsBi],
  [BiUserPin, "BiUserPin", "named", reactIconsBi],
  [BiUserPlus, "BiUserPlus", "named", reactIconsBi],
  [BiUserVoice, "BiUserVoice", "named", reactIconsBi],
  [BiUserX, "BiUserX", "named", reactIconsBi],
  [BiUser, "BiUser", "named", reactIconsBi],
  [BiVector, "BiVector", "named", reactIconsBi],
  [BiVerticalBottom, "BiVerticalBottom", "named", reactIconsBi],
  [BiVerticalCenter, "BiVerticalCenter", "named", reactIconsBi],
  [BiVerticalTop, "BiVerticalTop", "named", reactIconsBi],
  [BiVial, "BiVial", "named", reactIconsBi],
  [BiVideoOff, "BiVideoOff", "named", reactIconsBi],
  [BiVideoPlus, "BiVideoPlus", "named", reactIconsBi],
  [BiVideoRecording, "BiVideoRecording", "named", reactIconsBi],
  [BiVideo, "BiVideo", "named", reactIconsBi],
  [BiVoicemail, "BiVoicemail", "named", reactIconsBi],
  [BiVolumeFull, "BiVolumeFull", "named", reactIconsBi],
  [BiVolumeLow, "BiVolumeLow", "named", reactIconsBi],
  [BiVolumeMute, "BiVolumeMute", "named", reactIconsBi],
  [BiVolume, "BiVolume", "named", reactIconsBi],
  [BiWalk, "BiWalk", "named", reactIconsBi],
  [BiWalletAlt, "BiWalletAlt", "named", reactIconsBi],
  [BiWallet, "BiWallet", "named", reactIconsBi],
  [BiWater, "BiWater", "named", reactIconsBi],
  [BiWebcam, "BiWebcam", "named", reactIconsBi],
  [BiWifi0, "BiWifi0", "named", reactIconsBi],
  [BiWifi1, "BiWifi1", "named", reactIconsBi],
  [BiWifi2, "BiWifi2", "named", reactIconsBi],
  [BiWifiOff, "BiWifiOff", "named", reactIconsBi],
  [BiWifi, "BiWifi", "named", reactIconsBi],
  [BiWind, "BiWind", "named", reactIconsBi],
  [BiWindowAlt, "BiWindowAlt", "named", reactIconsBi],
  [BiWindowClose, "BiWindowClose", "named", reactIconsBi],
  [BiWindowOpen, "BiWindowOpen", "named", reactIconsBi],
  [BiWindow, "BiWindow", "named", reactIconsBi],
  [BiWindows, "BiWindows", "named", reactIconsBi],
  [BiWine, "BiWine", "named", reactIconsBi],
  [BiWinkSmile, "BiWinkSmile", "named", reactIconsBi],
  [BiWinkTongue, "BiWinkTongue", "named", reactIconsBi],
  [BiWon, "BiWon", "named", reactIconsBi],
  [BiWorld, "BiWorld", "named", reactIconsBi],
  [BiWrench, "BiWrench", "named", reactIconsBi],
  [BiXCircle, "BiXCircle", "named", reactIconsBi],
  [BiX, "BiX", "named", reactIconsBi],
  [BiYen, "BiYen", "named", reactIconsBi],
  [BiZoomIn, "BiZoomIn", "named", reactIconsBi],
  [BiZoomOut, "BiZoomOut", "named", reactIconsBi],
  [BiSolidAddToQueue, "BiSolidAddToQueue", "named", reactIconsBi],
  [BiSolidAdjustAlt, "BiSolidAdjustAlt", "named", reactIconsBi],
  [BiSolidAdjust, "BiSolidAdjust", "named", reactIconsBi],
  [BiSolidAlarmAdd, "BiSolidAlarmAdd", "named", reactIconsBi],
  [BiSolidAlarmExclamation, "BiSolidAlarmExclamation", "named", reactIconsBi],
  [BiSolidAlarmOff, "BiSolidAlarmOff", "named", reactIconsBi],
  [BiSolidAlarmSnooze, "BiSolidAlarmSnooze", "named", reactIconsBi],
  [BiSolidAlarm, "BiSolidAlarm", "named", reactIconsBi],
  [BiSolidAlbum, "BiSolidAlbum", "named", reactIconsBi],
  [BiSolidAmbulance, "BiSolidAmbulance", "named", reactIconsBi],
  [BiSolidAnalyse, "BiSolidAnalyse", "named", reactIconsBi],
  [BiSolidAngry, "BiSolidAngry", "named", reactIconsBi],
  [BiSolidArch, "BiSolidArch", "named", reactIconsBi],
  [BiSolidArchiveIn, "BiSolidArchiveIn", "named", reactIconsBi],
  [BiSolidArchiveOut, "BiSolidArchiveOut", "named", reactIconsBi],
  [BiSolidArchive, "BiSolidArchive", "named", reactIconsBi],
  [BiSolidArea, "BiSolidArea", "named", reactIconsBi],
  [BiSolidArrowFromBottom, "BiSolidArrowFromBottom", "named", reactIconsBi],
  [BiSolidArrowFromLeft, "BiSolidArrowFromLeft", "named", reactIconsBi],
  [BiSolidArrowFromRight, "BiSolidArrowFromRight", "named", reactIconsBi],
  [BiSolidArrowFromTop, "BiSolidArrowFromTop", "named", reactIconsBi],
  [BiSolidArrowToBottom, "BiSolidArrowToBottom", "named", reactIconsBi],
  [BiSolidArrowToLeft, "BiSolidArrowToLeft", "named", reactIconsBi],
  [BiSolidArrowToRight, "BiSolidArrowToRight", "named", reactIconsBi],
  [BiSolidArrowToTop, "BiSolidArrowToTop", "named", reactIconsBi],
  [BiSolidAward, "BiSolidAward", "named", reactIconsBi],
  [BiSolidBabyCarriage, "BiSolidBabyCarriage", "named", reactIconsBi],
  [BiSolidBackpack, "BiSolidBackpack", "named", reactIconsBi],
  [BiSolidBadgeCheck, "BiSolidBadgeCheck", "named", reactIconsBi],
  [BiSolidBadgeDollar, "BiSolidBadgeDollar", "named", reactIconsBi],
  [BiSolidBadge, "BiSolidBadge", "named", reactIconsBi],
  [BiSolidBaguette, "BiSolidBaguette", "named", reactIconsBi],
  [BiSolidBall, "BiSolidBall", "named", reactIconsBi],
  [BiSolidBalloon, "BiSolidBalloon", "named", reactIconsBi],
  [BiSolidBandAid, "BiSolidBandAid", "named", reactIconsBi],
  [BiSolidBank, "BiSolidBank", "named", reactIconsBi],
  [BiSolidBarChartAlt2, "BiSolidBarChartAlt2", "named", reactIconsBi],
  [BiSolidBarChartSquare, "BiSolidBarChartSquare", "named", reactIconsBi],
  [BiSolidBarcode, "BiSolidBarcode", "named", reactIconsBi],
  [BiSolidBaseball, "BiSolidBaseball", "named", reactIconsBi],
  [BiSolidBasket, "BiSolidBasket", "named", reactIconsBi],
  [BiSolidBasketball, "BiSolidBasketball", "named", reactIconsBi],
  [BiSolidBath, "BiSolidBath", "named", reactIconsBi],
  [BiSolidBatteryCharging, "BiSolidBatteryCharging", "named", reactIconsBi],
  [BiSolidBatteryFull, "BiSolidBatteryFull", "named", reactIconsBi],
  [BiSolidBatteryLow, "BiSolidBatteryLow", "named", reactIconsBi],
  [BiSolidBattery, "BiSolidBattery", "named", reactIconsBi],
  [BiSolidBed, "BiSolidBed", "named", reactIconsBi],
  [BiSolidBeenHere, "BiSolidBeenHere", "named", reactIconsBi],
  [BiSolidBeer, "BiSolidBeer", "named", reactIconsBi],
  [BiSolidBellMinus, "BiSolidBellMinus", "named", reactIconsBi],
  [BiSolidBellOff, "BiSolidBellOff", "named", reactIconsBi],
  [BiSolidBellPlus, "BiSolidBellPlus", "named", reactIconsBi],
  [BiSolidBellRing, "BiSolidBellRing", "named", reactIconsBi],
  [BiSolidBell, "BiSolidBell", "named", reactIconsBi],
  [BiSolidBible, "BiSolidBible", "named", reactIconsBi],
  [BiSolidBinoculars, "BiSolidBinoculars", "named", reactIconsBi],
  [BiSolidBlanket, "BiSolidBlanket", "named", reactIconsBi],
  [BiSolidBoltCircle, "BiSolidBoltCircle", "named", reactIconsBi],
  [BiSolidBolt, "BiSolidBolt", "named", reactIconsBi],
  [BiSolidBomb, "BiSolidBomb", "named", reactIconsBi],
  [BiSolidBone, "BiSolidBone", "named", reactIconsBi],
  [BiSolidBong, "BiSolidBong", "named", reactIconsBi],
  [BiSolidBookAdd, "BiSolidBookAdd", "named", reactIconsBi],
  [BiSolidBookAlt, "BiSolidBookAlt", "named", reactIconsBi],
  [BiSolidBookBookmark, "BiSolidBookBookmark", "named", reactIconsBi],
  [BiSolidBookContent, "BiSolidBookContent", "named", reactIconsBi],
  [BiSolidBookHeart, "BiSolidBookHeart", "named", reactIconsBi],
  [BiSolidBookOpen, "BiSolidBookOpen", "named", reactIconsBi],
  [BiSolidBookReader, "BiSolidBookReader", "named", reactIconsBi],
  [BiSolidBook, "BiSolidBook", "named", reactIconsBi],
  [BiSolidBookmarkAltMinus, "BiSolidBookmarkAltMinus", "named", reactIconsBi],
  [BiSolidBookmarkAltPlus, "BiSolidBookmarkAltPlus", "named", reactIconsBi],
  [BiSolidBookmarkAlt, "BiSolidBookmarkAlt", "named", reactIconsBi],
  [BiSolidBookmarkHeart, "BiSolidBookmarkHeart", "named", reactIconsBi],
  [BiSolidBookmarkMinus, "BiSolidBookmarkMinus", "named", reactIconsBi],
  [BiSolidBookmarkPlus, "BiSolidBookmarkPlus", "named", reactIconsBi],
  [BiSolidBookmarkStar, "BiSolidBookmarkStar", "named", reactIconsBi],
  [BiSolidBookmark, "BiSolidBookmark", "named", reactIconsBi],
  [BiSolidBookmarks, "BiSolidBookmarks", "named", reactIconsBi],
  [BiSolidBot, "BiSolidBot", "named", reactIconsBi],
  [BiSolidBowlHot, "BiSolidBowlHot", "named", reactIconsBi],
  [BiSolidBowlRice, "BiSolidBowlRice", "named", reactIconsBi],
  [BiSolidBowlingBall, "BiSolidBowlingBall", "named", reactIconsBi],
  [BiSolidBox, "BiSolidBox", "named", reactIconsBi],
  [BiSolidBrain, "BiSolidBrain", "named", reactIconsBi],
  [BiSolidBriefcaseAlt2, "BiSolidBriefcaseAlt2", "named", reactIconsBi],
  [BiSolidBriefcaseAlt, "BiSolidBriefcaseAlt", "named", reactIconsBi],
  [BiSolidBriefcase, "BiSolidBriefcase", "named", reactIconsBi],
  [BiSolidBrightnessHalf, "BiSolidBrightnessHalf", "named", reactIconsBi],
  [BiSolidBrightness, "BiSolidBrightness", "named", reactIconsBi],
  [BiSolidBrushAlt, "BiSolidBrushAlt", "named", reactIconsBi],
  [BiSolidBrush, "BiSolidBrush", "named", reactIconsBi],
  [BiSolidBugAlt, "BiSolidBugAlt", "named", reactIconsBi],
  [BiSolidBug, "BiSolidBug", "named", reactIconsBi],
  [BiSolidBuildingHouse, "BiSolidBuildingHouse", "named", reactIconsBi],
  [BiSolidBuilding, "BiSolidBuilding", "named", reactIconsBi],
  [BiSolidBuildings, "BiSolidBuildings", "named", reactIconsBi],
  [BiSolidBulb, "BiSolidBulb", "named", reactIconsBi],
  [BiSolidBullseye, "BiSolidBullseye", "named", reactIconsBi],
  [BiSolidBuoy, "BiSolidBuoy", "named", reactIconsBi],
  [BiSolidBusSchool, "BiSolidBusSchool", "named", reactIconsBi],
  [BiSolidBus, "BiSolidBus", "named", reactIconsBi],
  [BiSolidBusiness, "BiSolidBusiness", "named", reactIconsBi],
  [BiSolidCabinet, "BiSolidCabinet", "named", reactIconsBi],
  [BiSolidCableCar, "BiSolidCableCar", "named", reactIconsBi],
  [BiSolidCake, "BiSolidCake", "named", reactIconsBi],
  [BiSolidCalculator, "BiSolidCalculator", "named", reactIconsBi],
  [BiSolidCalendarAlt, "BiSolidCalendarAlt", "named", reactIconsBi],
  [BiSolidCalendarCheck, "BiSolidCalendarCheck", "named", reactIconsBi],
  [BiSolidCalendarEdit, "BiSolidCalendarEdit", "named", reactIconsBi],
  [BiSolidCalendarEvent, "BiSolidCalendarEvent", "named", reactIconsBi],
  [BiSolidCalendarExclamation, "BiSolidCalendarExclamation", "named", reactIconsBi],
  [BiSolidCalendarHeart, "BiSolidCalendarHeart", "named", reactIconsBi],
  [BiSolidCalendarMinus, "BiSolidCalendarMinus", "named", reactIconsBi],
  [BiSolidCalendarPlus, "BiSolidCalendarPlus", "named", reactIconsBi],
  [BiSolidCalendarStar, "BiSolidCalendarStar", "named", reactIconsBi],
  [BiSolidCalendarWeek, "BiSolidCalendarWeek", "named", reactIconsBi],
  [BiSolidCalendarX, "BiSolidCalendarX", "named", reactIconsBi],
  [BiSolidCalendar, "BiSolidCalendar", "named", reactIconsBi],
  [BiSolidCameraHome, "BiSolidCameraHome", "named", reactIconsBi],
  [BiSolidCameraMovie, "BiSolidCameraMovie", "named", reactIconsBi],
  [BiSolidCameraOff, "BiSolidCameraOff", "named", reactIconsBi],
  [BiSolidCameraPlus, "BiSolidCameraPlus", "named", reactIconsBi],
  [BiSolidCamera, "BiSolidCamera", "named", reactIconsBi],
  [BiSolidCapsule, "BiSolidCapsule", "named", reactIconsBi],
  [BiSolidCaptions, "BiSolidCaptions", "named", reactIconsBi],
  [BiSolidCarBattery, "BiSolidCarBattery", "named", reactIconsBi],
  [BiSolidCarCrash, "BiSolidCarCrash", "named", reactIconsBi],
  [BiSolidCarGarage, "BiSolidCarGarage", "named", reactIconsBi],
  [BiSolidCarMechanic, "BiSolidCarMechanic", "named", reactIconsBi],
  [BiSolidCarWash, "BiSolidCarWash", "named", reactIconsBi],
  [BiSolidCar, "BiSolidCar", "named", reactIconsBi],
  [BiSolidCard, "BiSolidCard", "named", reactIconsBi],
  [BiSolidCaretDownCircle, "BiSolidCaretDownCircle", "named", reactIconsBi],
  [BiSolidCaretDownSquare, "BiSolidCaretDownSquare", "named", reactIconsBi],
  [BiSolidCaretLeftCircle, "BiSolidCaretLeftCircle", "named", reactIconsBi],
  [BiSolidCaretLeftSquare, "BiSolidCaretLeftSquare", "named", reactIconsBi],
  [BiSolidCaretRightCircle, "BiSolidCaretRightCircle", "named", reactIconsBi],
  [BiSolidCaretRightSquare, "BiSolidCaretRightSquare", "named", reactIconsBi],
  [BiSolidCaretUpCircle, "BiSolidCaretUpCircle", "named", reactIconsBi],
  [BiSolidCaretUpSquare, "BiSolidCaretUpSquare", "named", reactIconsBi],
  [BiSolidCarousel, "BiSolidCarousel", "named", reactIconsBi],
  [BiSolidCartAdd, "BiSolidCartAdd", "named", reactIconsBi],
  [BiSolidCartAlt, "BiSolidCartAlt", "named", reactIconsBi],
  [BiSolidCartDownload, "BiSolidCartDownload", "named", reactIconsBi],
  [BiSolidCart, "BiSolidCart", "named", reactIconsBi],
  [BiSolidCastle, "BiSolidCastle", "named", reactIconsBi],
  [BiSolidCat, "BiSolidCat", "named", reactIconsBi],
  [BiSolidCategoryAlt, "BiSolidCategoryAlt", "named", reactIconsBi],
  [BiSolidCategory, "BiSolidCategory", "named", reactIconsBi],
  [BiSolidCctv, "BiSolidCctv", "named", reactIconsBi],
  [BiSolidCertification, "BiSolidCertification", "named", reactIconsBi],
  [BiSolidChalkboard, "BiSolidChalkboard", "named", reactIconsBi],
  [BiSolidChart, "BiSolidChart", "named", reactIconsBi],
  [BiSolidChat, "BiSolidChat", "named", reactIconsBi],
  [BiSolidCheckCircle, "BiSolidCheckCircle", "named", reactIconsBi],
  [BiSolidCheckShield, "BiSolidCheckShield", "named", reactIconsBi],
  [BiSolidCheckSquare, "BiSolidCheckSquare", "named", reactIconsBi],
  [BiSolidCheckboxChecked, "BiSolidCheckboxChecked", "named", reactIconsBi],
  [BiSolidCheckboxMinus, "BiSolidCheckboxMinus", "named", reactIconsBi],
  [BiSolidCheckbox, "BiSolidCheckbox", "named", reactIconsBi],
  [BiSolidCheese, "BiSolidCheese", "named", reactIconsBi],
  [BiSolidChess, "BiSolidChess", "named", reactIconsBi],
  [BiSolidChevronDownCircle, "BiSolidChevronDownCircle", "named", reactIconsBi],
  [BiSolidChevronDownSquare, "BiSolidChevronDownSquare", "named", reactIconsBi],
  [BiSolidChevronDown, "BiSolidChevronDown", "named", reactIconsBi],
  [BiSolidChevronLeftCircle, "BiSolidChevronLeftCircle", "named", reactIconsBi],
  [BiSolidChevronLeftSquare, "BiSolidChevronLeftSquare", "named", reactIconsBi],
  [BiSolidChevronLeft, "BiSolidChevronLeft", "named", reactIconsBi],
  [BiSolidChevronRightCircle, "BiSolidChevronRightCircle", "named", reactIconsBi],
  [BiSolidChevronRightSquare, "BiSolidChevronRightSquare", "named", reactIconsBi],
  [BiSolidChevronRight, "BiSolidChevronRight", "named", reactIconsBi],
  [BiSolidChevronUpCircle, "BiSolidChevronUpCircle", "named", reactIconsBi],
  [BiSolidChevronUpSquare, "BiSolidChevronUpSquare", "named", reactIconsBi],
  [BiSolidChevronUp, "BiSolidChevronUp", "named", reactIconsBi],
  [BiSolidChevronsDown, "BiSolidChevronsDown", "named", reactIconsBi],
  [BiSolidChevronsLeft, "BiSolidChevronsLeft", "named", reactIconsBi],
  [BiSolidChevronsRight, "BiSolidChevronsRight", "named", reactIconsBi],
  [BiSolidChevronsUp, "BiSolidChevronsUp", "named", reactIconsBi],
  [BiSolidChip, "BiSolidChip", "named", reactIconsBi],
  [BiSolidChurch, "BiSolidChurch", "named", reactIconsBi],
  [BiSolidCircleHalf, "BiSolidCircleHalf", "named", reactIconsBi],
  [BiSolidCircleQuarter, "BiSolidCircleQuarter", "named", reactIconsBi],
  [BiSolidCircleThreeQuarter, "BiSolidCircleThreeQuarter", "named", reactIconsBi],
  [BiSolidCircle, "BiSolidCircle", "named", reactIconsBi],
  [BiSolidCity, "BiSolidCity", "named", reactIconsBi],
  [BiSolidClinic, "BiSolidClinic", "named", reactIconsBi],
  [BiSolidCloudDownload, "BiSolidCloudDownload", "named", reactIconsBi],
  [BiSolidCloudLightning, "BiSolidCloudLightning", "named", reactIconsBi],
  [BiSolidCloudRain, "BiSolidCloudRain", "named", reactIconsBi],
  [BiSolidCloudUpload, "BiSolidCloudUpload", "named", reactIconsBi],
  [BiSolidCloud, "BiSolidCloud", "named", reactIconsBi],
  [BiSolidCoffeeAlt, "BiSolidCoffeeAlt", "named", reactIconsBi],
  [BiSolidCoffeeBean, "BiSolidCoffeeBean", "named", reactIconsBi],
  [BiSolidCoffeeTogo, "BiSolidCoffeeTogo", "named", reactIconsBi],
  [BiSolidCoffee, "BiSolidCoffee", "named", reactIconsBi],
  [BiSolidCog, "BiSolidCog", "named", reactIconsBi],
  [BiSolidCoinStack, "BiSolidCoinStack", "named", reactIconsBi],
  [BiSolidCoin, "BiSolidCoin", "named", reactIconsBi],
  [BiSolidCollection, "BiSolidCollection", "named", reactIconsBi],
  [BiSolidColorFill, "BiSolidColorFill", "named", reactIconsBi],
  [BiSolidColor, "BiSolidColor", "named", reactIconsBi],
  [BiSolidCommentAdd, "BiSolidCommentAdd", "named", reactIconsBi],
  [BiSolidCommentCheck, "BiSolidCommentCheck", "named", reactIconsBi],
  [BiSolidCommentDetail, "BiSolidCommentDetail", "named", reactIconsBi],
  [BiSolidCommentDots, "BiSolidCommentDots", "named", reactIconsBi],
  [BiSolidCommentEdit, "BiSolidCommentEdit", "named", reactIconsBi],
  [BiSolidCommentError, "BiSolidCommentError", "named", reactIconsBi],
  [BiSolidCommentMinus, "BiSolidCommentMinus", "named", reactIconsBi],
  [BiSolidCommentX, "BiSolidCommentX", "named", reactIconsBi],
  [BiSolidComment, "BiSolidComment", "named", reactIconsBi],
  [BiSolidCompass, "BiSolidCompass", "named", reactIconsBi],
  [BiSolidComponent, "BiSolidComponent", "named", reactIconsBi],
  [BiSolidConfused, "BiSolidConfused", "named", reactIconsBi],
  [BiSolidContact, "BiSolidContact", "named", reactIconsBi],
  [BiSolidConversation, "BiSolidConversation", "named", reactIconsBi],
  [BiSolidCookie, "BiSolidCookie", "named", reactIconsBi],
  [BiSolidCool, "BiSolidCool", "named", reactIconsBi],
  [BiSolidCopyAlt, "BiSolidCopyAlt", "named", reactIconsBi],
  [BiSolidCopy, "BiSolidCopy", "named", reactIconsBi],
  [BiSolidCopyright, "BiSolidCopyright", "named", reactIconsBi],
  [BiSolidCoupon, "BiSolidCoupon", "named", reactIconsBi],
  [BiSolidCreditCardAlt, "BiSolidCreditCardAlt", "named", reactIconsBi],
  [BiSolidCreditCardFront, "BiSolidCreditCardFront", "named", reactIconsBi],
  [BiSolidCreditCard, "BiSolidCreditCard", "named", reactIconsBi],
  [BiSolidCricketBall, "BiSolidCricketBall", "named", reactIconsBi],
  [BiSolidCrop, "BiSolidCrop", "named", reactIconsBi],
  [BiSolidCrown, "BiSolidCrown", "named", reactIconsBi],
  [BiSolidCubeAlt, "BiSolidCubeAlt", "named", reactIconsBi],
  [BiSolidCube, "BiSolidCube", "named", reactIconsBi],
  [BiSolidCuboid, "BiSolidCuboid", "named", reactIconsBi],
  [BiSolidCustomize, "BiSolidCustomize", "named", reactIconsBi],
  [BiSolidCylinder, "BiSolidCylinder", "named", reactIconsBi],
  [BiSolidDashboard, "BiSolidDashboard", "named", reactIconsBi],
  [BiSolidData, "BiSolidData", "named", reactIconsBi],
  [BiSolidDetail, "BiSolidDetail", "named", reactIconsBi],
  [BiSolidDevices, "BiSolidDevices", "named", reactIconsBi],
  [BiSolidDiamond, "BiSolidDiamond", "named", reactIconsBi],
  [BiSolidDice1, "BiSolidDice1", "named", reactIconsBi],
  [BiSolidDice2, "BiSolidDice2", "named", reactIconsBi],
  [BiSolidDice3, "BiSolidDice3", "named", reactIconsBi],
  [BiSolidDice4, "BiSolidDice4", "named", reactIconsBi],
  [BiSolidDice5, "BiSolidDice5", "named", reactIconsBi],
  [BiSolidDice6, "BiSolidDice6", "named", reactIconsBi],
  [BiSolidDirectionLeft, "BiSolidDirectionLeft", "named", reactIconsBi],
  [BiSolidDirectionRight, "BiSolidDirectionRight", "named", reactIconsBi],
  [BiSolidDirections, "BiSolidDirections", "named", reactIconsBi],
  [BiSolidDisc, "BiSolidDisc", "named", reactIconsBi],
  [BiSolidDiscount, "BiSolidDiscount", "named", reactIconsBi],
  [BiSolidDish, "BiSolidDish", "named", reactIconsBi],
  [BiSolidDislike, "BiSolidDislike", "named", reactIconsBi],
  [BiSolidDizzy, "BiSolidDizzy", "named", reactIconsBi],
  [BiSolidDockBottom, "BiSolidDockBottom", "named", reactIconsBi],
  [BiSolidDockLeft, "BiSolidDockLeft", "named", reactIconsBi],
  [BiSolidDockRight, "BiSolidDockRight", "named", reactIconsBi],
  [BiSolidDockTop, "BiSolidDockTop", "named", reactIconsBi],
  [BiSolidDog, "BiSolidDog", "named", reactIconsBi],
  [BiSolidDollarCircle, "BiSolidDollarCircle", "named", reactIconsBi],
  [BiSolidDonateBlood, "BiSolidDonateBlood", "named", reactIconsBi],
  [BiSolidDonateHeart, "BiSolidDonateHeart", "named", reactIconsBi],
  [BiSolidDoorOpen, "BiSolidDoorOpen", "named", reactIconsBi],
  [BiSolidDoughnutChart, "BiSolidDoughnutChart", "named", reactIconsBi],
  [BiSolidDownArrowAlt, "BiSolidDownArrowAlt", "named", reactIconsBi],
  [BiSolidDownArrowCircle, "BiSolidDownArrowCircle", "named", reactIconsBi],
  [BiSolidDownArrowSquare, "BiSolidDownArrowSquare", "named", reactIconsBi],
  [BiSolidDownArrow, "BiSolidDownArrow", "named", reactIconsBi],
  [BiSolidDownload, "BiSolidDownload", "named", reactIconsBi],
  [BiSolidDownvote, "BiSolidDownvote", "named", reactIconsBi],
  [BiSolidDrink, "BiSolidDrink", "named", reactIconsBi],
  [BiSolidDropletHalf, "BiSolidDropletHalf", "named", reactIconsBi],
  [BiSolidDroplet, "BiSolidDroplet", "named", reactIconsBi],
  [BiSolidDryer, "BiSolidDryer", "named", reactIconsBi],
  [BiSolidDuplicate, "BiSolidDuplicate", "named", reactIconsBi],
  [BiSolidEditAlt, "BiSolidEditAlt", "named", reactIconsBi],
  [BiSolidEditLocation, "BiSolidEditLocation", "named", reactIconsBi],
  [BiSolidEdit, "BiSolidEdit", "named", reactIconsBi],
  [BiSolidEject, "BiSolidEject", "named", reactIconsBi],
  [BiSolidEnvelopeOpen, "BiSolidEnvelopeOpen", "named", reactIconsBi],
  [BiSolidEnvelope, "BiSolidEnvelope", "named", reactIconsBi],
  [BiSolidEraser, "BiSolidEraser", "named", reactIconsBi],
  [BiSolidErrorAlt, "BiSolidErrorAlt", "named", reactIconsBi],
  [BiSolidErrorCircle, "BiSolidErrorCircle", "named", reactIconsBi],
  [BiSolidError, "BiSolidError", "named", reactIconsBi],
  [BiSolidEvStation, "BiSolidEvStation", "named", reactIconsBi],
  [BiSolidExit, "BiSolidExit", "named", reactIconsBi],
  [BiSolidExtension, "BiSolidExtension", "named", reactIconsBi],
  [BiSolidEyedropper, "BiSolidEyedropper", "named", reactIconsBi],
  [BiSolidFaceMask, "BiSolidFaceMask", "named", reactIconsBi],
  [BiSolidFace, "BiSolidFace", "named", reactIconsBi],
  [BiSolidFactory, "BiSolidFactory", "named", reactIconsBi],
  [BiSolidFastForwardCircle, "BiSolidFastForwardCircle", "named", reactIconsBi],
  [BiSolidFileArchive, "BiSolidFileArchive", "named", reactIconsBi],
  [BiSolidFileBlank, "BiSolidFileBlank", "named", reactIconsBi],
  [BiSolidFileCss, "BiSolidFileCss", "named", reactIconsBi],
  [BiSolidFileDoc, "BiSolidFileDoc", "named", reactIconsBi],
  [BiSolidFileExport, "BiSolidFileExport", "named", reactIconsBi],
  [BiSolidFileFind, "BiSolidFileFind", "named", reactIconsBi],
  [BiSolidFileGif, "BiSolidFileGif", "named", reactIconsBi],
  [BiSolidFileHtml, "BiSolidFileHtml", "named", reactIconsBi],
  [BiSolidFileImage, "BiSolidFileImage", "named", reactIconsBi],
  [BiSolidFileImport, "BiSolidFileImport", "named", reactIconsBi],
  [BiSolidFileJpg, "BiSolidFileJpg", "named", reactIconsBi],
  [BiSolidFileJs, "BiSolidFileJs", "named", reactIconsBi],
  [BiSolidFileJson, "BiSolidFileJson", "named", reactIconsBi],
  [BiSolidFileMd, "BiSolidFileMd", "named", reactIconsBi],
  [BiSolidFilePdf, "BiSolidFilePdf", "named", reactIconsBi],
  [BiSolidFilePlus, "BiSolidFilePlus", "named", reactIconsBi],
  [BiSolidFilePng, "BiSolidFilePng", "named", reactIconsBi],
  [BiSolidFileTxt, "BiSolidFileTxt", "named", reactIconsBi],
  [BiSolidFile, "BiSolidFile", "named", reactIconsBi],
  [BiSolidFilm, "BiSolidFilm", "named", reactIconsBi],
  [BiSolidFilterAlt, "BiSolidFilterAlt", "named", reactIconsBi],
  [BiSolidFirstAid, "BiSolidFirstAid", "named", reactIconsBi],
  [BiSolidFlagAlt, "BiSolidFlagAlt", "named", reactIconsBi],
  [BiSolidFlagCheckered, "BiSolidFlagCheckered", "named", reactIconsBi],
  [BiSolidFlag, "BiSolidFlag", "named", reactIconsBi],
  [BiSolidFlame, "BiSolidFlame", "named", reactIconsBi],
  [BiSolidFlask, "BiSolidFlask", "named", reactIconsBi],
  [BiSolidFlorist, "BiSolidFlorist", "named", reactIconsBi],
  [BiSolidFolderMinus, "BiSolidFolderMinus", "named", reactIconsBi],
  [BiSolidFolderOpen, "BiSolidFolderOpen", "named", reactIconsBi],
  [BiSolidFolderPlus, "BiSolidFolderPlus", "named", reactIconsBi],
  [BiSolidFolder, "BiSolidFolder", "named", reactIconsBi],
  [BiSolidFoodMenu, "BiSolidFoodMenu", "named", reactIconsBi],
  [BiSolidFridge, "BiSolidFridge", "named", reactIconsBi],
  [BiSolidGame, "BiSolidGame", "named", reactIconsBi],
  [BiSolidGasPump, "BiSolidGasPump", "named", reactIconsBi],
  [BiSolidGhost, "BiSolidGhost", "named", reactIconsBi],
  [BiSolidGift, "BiSolidGift", "named", reactIconsBi],
  [BiSolidGraduation, "BiSolidGraduation", "named", reactIconsBi],
  [BiSolidGridAlt, "BiSolidGridAlt", "named", reactIconsBi],
  [BiSolidGrid, "BiSolidGrid", "named", reactIconsBi],
  [BiSolidGroup, "BiSolidGroup", "named", reactIconsBi],
  [BiSolidGuitarAmp, "BiSolidGuitarAmp", "named", reactIconsBi],
  [BiSolidHandDown, "BiSolidHandDown", "named", reactIconsBi],
  [BiSolidHandLeft, "BiSolidHandLeft", "named", reactIconsBi],
  [BiSolidHandRight, "BiSolidHandRight", "named", reactIconsBi],
  [BiSolidHandUp, "BiSolidHandUp", "named", reactIconsBi],
  [BiSolidHand, "BiSolidHand", "named", reactIconsBi],
  [BiSolidHappyAlt, "BiSolidHappyAlt", "named", reactIconsBi],
  [BiSolidHappyBeaming, "BiSolidHappyBeaming", "named", reactIconsBi],
  [BiSolidHappyHeartEyes, "BiSolidHappyHeartEyes", "named", reactIconsBi],
  [BiSolidHappy, "BiSolidHappy", "named", reactIconsBi],
  [BiSolidHardHat, "BiSolidHardHat", "named", reactIconsBi],
  [BiSolidHdd, "BiSolidHdd", "named", reactIconsBi],
  [BiSolidHeartCircle, "BiSolidHeartCircle", "named", reactIconsBi],
  [BiSolidHeartSquare, "BiSolidHeartSquare", "named", reactIconsBi],
  [BiSolidHeart, "BiSolidHeart", "named", reactIconsBi],
  [BiSolidHelpCircle, "BiSolidHelpCircle", "named", reactIconsBi],
  [BiSolidHide, "BiSolidHide", "named", reactIconsBi],
  [BiSolidHomeAlt2, "BiSolidHomeAlt2", "named", reactIconsBi],
  [BiSolidHomeCircle, "BiSolidHomeCircle", "named", reactIconsBi],
  [BiSolidHomeHeart, "BiSolidHomeHeart", "named", reactIconsBi],
  [BiSolidHomeSmile, "BiSolidHomeSmile", "named", reactIconsBi],
  [BiSolidHome, "BiSolidHome", "named", reactIconsBi],
  [BiSolidHot, "BiSolidHot", "named", reactIconsBi],
  [BiSolidHotel, "BiSolidHotel", "named", reactIconsBi],
  [BiSolidHourglassBottom, "BiSolidHourglassBottom", "named", reactIconsBi],
  [BiSolidHourglassTop, "BiSolidHourglassTop", "named", reactIconsBi],
  [BiSolidHourglass, "BiSolidHourglass", "named", reactIconsBi],
  [BiSolidIdCard, "BiSolidIdCard", "named", reactIconsBi],
  [BiSolidImageAdd, "BiSolidImageAdd", "named", reactIconsBi],
  [BiSolidImageAlt, "BiSolidImageAlt", "named", reactIconsBi],
  [BiSolidImage, "BiSolidImage", "named", reactIconsBi],
  [BiSolidInbox, "BiSolidInbox", "named", reactIconsBi],
  [BiSolidInfoCircle, "BiSolidInfoCircle", "named", reactIconsBi],
  [BiSolidInfoSquare, "BiSolidInfoSquare", "named", reactIconsBi],
  [BiSolidInjection, "BiSolidInjection", "named", reactIconsBi],
  [BiSolidInstitution, "BiSolidInstitution", "named", reactIconsBi],
  [BiSolidInvader, "BiSolidInvader", "named", reactIconsBi],
  [BiSolidJoystickAlt, "BiSolidJoystickAlt", "named", reactIconsBi],
  [BiSolidJoystickButton, "BiSolidJoystickButton", "named", reactIconsBi],
  [BiSolidJoystick, "BiSolidJoystick", "named", reactIconsBi],
  [BiSolidKey, "BiSolidKey", "named", reactIconsBi],
  [BiSolidKeyboard, "BiSolidKeyboard", "named", reactIconsBi],
  [BiSolidLabel, "BiSolidLabel", "named", reactIconsBi],
  [BiSolidLandmark, "BiSolidLandmark", "named", reactIconsBi],
  [BiSolidLandscape, "BiSolidLandscape", "named", reactIconsBi],
  [BiSolidLaugh, "BiSolidLaugh", "named", reactIconsBi],
  [BiSolidLayerMinus, "BiSolidLayerMinus", "named", reactIconsBi],
  [BiSolidLayerPlus, "BiSolidLayerPlus", "named", reactIconsBi],
  [BiSolidLayer, "BiSolidLayer", "named", reactIconsBi],
  [BiSolidLayout, "BiSolidLayout", "named", reactIconsBi],
  [BiSolidLeaf, "BiSolidLeaf", "named", reactIconsBi],
  [BiSolidLeftArrowAlt, "BiSolidLeftArrowAlt", "named", reactIconsBi],
  [BiSolidLeftArrowCircle, "BiSolidLeftArrowCircle", "named", reactIconsBi],
  [BiSolidLeftArrowSquare, "BiSolidLeftArrowSquare", "named", reactIconsBi],
  [BiSolidLeftArrow, "BiSolidLeftArrow", "named", reactIconsBi],
  [BiSolidLeftDownArrowCircle, "BiSolidLeftDownArrowCircle", "named", reactIconsBi],
  [BiSolidLeftTopArrowCircle, "BiSolidLeftTopArrowCircle", "named", reactIconsBi],
  [BiSolidLemon, "BiSolidLemon", "named", reactIconsBi],
  [BiSolidLike, "BiSolidLike", "named", reactIconsBi],
  [BiSolidLocationPlus, "BiSolidLocationPlus", "named", reactIconsBi],
  [BiSolidLockAlt, "BiSolidLockAlt", "named", reactIconsBi],
  [BiSolidLockOpenAlt, "BiSolidLockOpenAlt", "named", reactIconsBi],
  [BiSolidLockOpen, "BiSolidLockOpen", "named", reactIconsBi],
  [BiSolidLock, "BiSolidLock", "named", reactIconsBi],
  [BiSolidLogInCircle, "BiSolidLogInCircle", "named", reactIconsBi],
  [BiSolidLogIn, "BiSolidLogIn", "named", reactIconsBi],
  [BiSolidLogOutCircle, "BiSolidLogOutCircle", "named", reactIconsBi],
  [BiSolidLogOut, "BiSolidLogOut", "named", reactIconsBi],
  [BiSolidLowVision, "BiSolidLowVision", "named", reactIconsBi],
  [BiSolidMagicWand, "BiSolidMagicWand", "named", reactIconsBi],
  [BiSolidMagnet, "BiSolidMagnet", "named", reactIconsBi],
  [BiSolidMapAlt, "BiSolidMapAlt", "named", reactIconsBi],
  [BiSolidMapPin, "BiSolidMapPin", "named", reactIconsBi],
  [BiSolidMap, "BiSolidMap", "named", reactIconsBi],
  [BiSolidMask, "BiSolidMask", "named", reactIconsBi],
  [BiSolidMedal, "BiSolidMedal", "named", reactIconsBi],
  [BiSolidMegaphone, "BiSolidMegaphone", "named", reactIconsBi],
  [BiSolidMehAlt, "BiSolidMehAlt", "named", reactIconsBi],
  [BiSolidMehBlank, "BiSolidMehBlank", "named", reactIconsBi],
  [BiSolidMeh, "BiSolidMeh", "named", reactIconsBi],
  [BiSolidMemoryCard, "BiSolidMemoryCard", "named", reactIconsBi],
  [BiSolidMessageAdd, "BiSolidMessageAdd", "named", reactIconsBi],
  [BiSolidMessageAltAdd, "BiSolidMessageAltAdd", "named", reactIconsBi],
  [BiSolidMessageAltCheck, "BiSolidMessageAltCheck", "named", reactIconsBi],
  [BiSolidMessageAltDetail, "BiSolidMessageAltDetail", "named", reactIconsBi],
  [BiSolidMessageAltDots, "BiSolidMessageAltDots", "named", reactIconsBi],
  [BiSolidMessageAltEdit, "BiSolidMessageAltEdit", "named", reactIconsBi],
  [BiSolidMessageAltError, "BiSolidMessageAltError", "named", reactIconsBi],
  [BiSolidMessageAltMinus, "BiSolidMessageAltMinus", "named", reactIconsBi],
  [BiSolidMessageAltX, "BiSolidMessageAltX", "named", reactIconsBi],
  [BiSolidMessageAlt, "BiSolidMessageAlt", "named", reactIconsBi],
  [BiSolidMessageCheck, "BiSolidMessageCheck", "named", reactIconsBi],
  [BiSolidMessageDetail, "BiSolidMessageDetail", "named", reactIconsBi],
  [BiSolidMessageDots, "BiSolidMessageDots", "named", reactIconsBi],
  [BiSolidMessageEdit, "BiSolidMessageEdit", "named", reactIconsBi],
  [BiSolidMessageError, "BiSolidMessageError", "named", reactIconsBi],
  [BiSolidMessageMinus, "BiSolidMessageMinus", "named", reactIconsBi],
  [BiSolidMessageRoundedAdd, "BiSolidMessageRoundedAdd", "named", reactIconsBi],
  [BiSolidMessageRoundedCheck, "BiSolidMessageRoundedCheck", "named", reactIconsBi],
  [BiSolidMessageRoundedDetail, "BiSolidMessageRoundedDetail", "named", reactIconsBi],
  [BiSolidMessageRoundedDots, "BiSolidMessageRoundedDots", "named", reactIconsBi],
  [BiSolidMessageRoundedEdit, "BiSolidMessageRoundedEdit", "named", reactIconsBi],
  [BiSolidMessageRoundedError, "BiSolidMessageRoundedError", "named", reactIconsBi],
  [BiSolidMessageRoundedMinus, "BiSolidMessageRoundedMinus", "named", reactIconsBi],
  [BiSolidMessageRoundedX, "BiSolidMessageRoundedX", "named", reactIconsBi],
  [BiSolidMessageRounded, "BiSolidMessageRounded", "named", reactIconsBi],
  [BiSolidMessageSquareAdd, "BiSolidMessageSquareAdd", "named", reactIconsBi],
  [BiSolidMessageSquareCheck, "BiSolidMessageSquareCheck", "named", reactIconsBi],
  [BiSolidMessageSquareDetail, "BiSolidMessageSquareDetail", "named", reactIconsBi],
  [BiSolidMessageSquareDots, "BiSolidMessageSquareDots", "named", reactIconsBi],
  [BiSolidMessageSquareEdit, "BiSolidMessageSquareEdit", "named", reactIconsBi],
  [BiSolidMessageSquareError, "BiSolidMessageSquareError", "named", reactIconsBi],
  [BiSolidMessageSquareMinus, "BiSolidMessageSquareMinus", "named", reactIconsBi],
  [BiSolidMessageSquareX, "BiSolidMessageSquareX", "named", reactIconsBi],
  [BiSolidMessageSquare, "BiSolidMessageSquare", "named", reactIconsBi],
  [BiSolidMessageX, "BiSolidMessageX", "named", reactIconsBi],
  [BiSolidMessage, "BiSolidMessage", "named", reactIconsBi],
  [BiSolidMeteor, "BiSolidMeteor", "named", reactIconsBi],
  [BiSolidMicrochip, "BiSolidMicrochip", "named", reactIconsBi],
  [BiSolidMicrophoneAlt, "BiSolidMicrophoneAlt", "named", reactIconsBi],
  [BiSolidMicrophoneOff, "BiSolidMicrophoneOff", "named", reactIconsBi],
  [BiSolidMicrophone, "BiSolidMicrophone", "named", reactIconsBi],
  [BiSolidMinusCircle, "BiSolidMinusCircle", "named", reactIconsBi],
  [BiSolidMinusSquare, "BiSolidMinusSquare", "named", reactIconsBi],
  [BiSolidMobileVibration, "BiSolidMobileVibration", "named", reactIconsBi],
  [BiSolidMobile, "BiSolidMobile", "named", reactIconsBi],
  [BiSolidMoon, "BiSolidMoon", "named", reactIconsBi],
  [BiSolidMouseAlt, "BiSolidMouseAlt", "named", reactIconsBi],
  [BiSolidMouse, "BiSolidMouse", "named", reactIconsBi],
  [BiSolidMoviePlay, "BiSolidMoviePlay", "named", reactIconsBi],
  [BiSolidMovie, "BiSolidMovie", "named", reactIconsBi],
  [BiSolidMusic, "BiSolidMusic", "named", reactIconsBi],
  [BiSolidNavigation, "BiSolidNavigation", "named", reactIconsBi],
  [BiSolidNetworkChart, "BiSolidNetworkChart", "named", reactIconsBi],
  [BiSolidNews, "BiSolidNews", "named", reactIconsBi],
  [BiSolidNoEntry, "BiSolidNoEntry", "named", reactIconsBi],
  [BiSolidNote, "BiSolidNote", "named", reactIconsBi],
  [BiSolidNotepad, "BiSolidNotepad", "named", reactIconsBi],
  [BiSolidNotificationOff, "BiSolidNotificationOff", "named", reactIconsBi],
  [BiSolidNotification, "BiSolidNotification", "named", reactIconsBi],
  [BiSolidObjectsHorizontalCenter, "BiSolidObjectsHorizontalCenter", "named", reactIconsBi],
  [BiSolidObjectsHorizontalLeft, "BiSolidObjectsHorizontalLeft", "named", reactIconsBi],
  [BiSolidObjectsHorizontalRight, "BiSolidObjectsHorizontalRight", "named", reactIconsBi],
  [BiSolidObjectsVerticalBottom, "BiSolidObjectsVerticalBottom", "named", reactIconsBi],
  [BiSolidObjectsVerticalCenter, "BiSolidObjectsVerticalCenter", "named", reactIconsBi],
  [BiSolidObjectsVerticalTop, "BiSolidObjectsVerticalTop", "named", reactIconsBi],
  [BiSolidOffer, "BiSolidOffer", "named", reactIconsBi],
  [BiSolidPackage, "BiSolidPackage", "named", reactIconsBi],
  [BiSolidPaintRoll, "BiSolidPaintRoll", "named", reactIconsBi],
  [BiSolidPaint, "BiSolidPaint", "named", reactIconsBi],
  [BiSolidPalette, "BiSolidPalette", "named", reactIconsBi],
  [BiSolidPaperPlane, "BiSolidPaperPlane", "named", reactIconsBi],
  [BiSolidParking, "BiSolidParking", "named", reactIconsBi],
  [BiSolidParty, "BiSolidParty", "named", reactIconsBi],
  [BiSolidPaste, "BiSolidPaste", "named", reactIconsBi],
  [BiSolidPear, "BiSolidPear", "named", reactIconsBi],
  [BiSolidPen, "BiSolidPen", "named", reactIconsBi],
  [BiSolidPencil, "BiSolidPencil", "named", reactIconsBi],
  [BiSolidPhoneCall, "BiSolidPhoneCall", "named", reactIconsBi],
  [BiSolidPhoneIncoming, "BiSolidPhoneIncoming", "named", reactIconsBi],
  [BiSolidPhoneOff, "BiSolidPhoneOff", "named", reactIconsBi],
  [BiSolidPhoneOutgoing, "BiSolidPhoneOutgoing", "named", reactIconsBi],
  [BiSolidPhone, "BiSolidPhone", "named", reactIconsBi],
  [BiSolidPhotoAlbum, "BiSolidPhotoAlbum", "named", reactIconsBi],
  [BiSolidPiano, "BiSolidPiano", "named", reactIconsBi],
  [BiSolidPieChartAlt2, "BiSolidPieChartAlt2", "named", reactIconsBi],
  [BiSolidPieChartAlt, "BiSolidPieChartAlt", "named", reactIconsBi],
  [BiSolidPieChart, "BiSolidPieChart", "named", reactIconsBi],
  [BiSolidPin, "BiSolidPin", "named", reactIconsBi],
  [BiSolidPizza, "BiSolidPizza", "named", reactIconsBi],
  [BiSolidPlaneAlt, "BiSolidPlaneAlt", "named", reactIconsBi],
  [BiSolidPlaneLand, "BiSolidPlaneLand", "named", reactIconsBi],
  [BiSolidPlaneTakeOff, "BiSolidPlaneTakeOff", "named", reactIconsBi],
  [BiSolidPlane, "BiSolidPlane", "named", reactIconsBi],
  [BiSolidPlanet, "BiSolidPlanet", "named", reactIconsBi],
  [BiSolidPlaylist, "BiSolidPlaylist", "named", reactIconsBi],
  [BiSolidPlug, "BiSolidPlug", "named", reactIconsBi],
  [BiSolidPlusCircle, "BiSolidPlusCircle", "named", reactIconsBi],
  [BiSolidPlusSquare, "BiSolidPlusSquare", "named", reactIconsBi],
  [BiSolidPointer, "BiSolidPointer", "named", reactIconsBi],
  [BiSolidPolygon, "BiSolidPolygon", "named", reactIconsBi],
  [BiSolidPopsicle, "BiSolidPopsicle", "named", reactIconsBi],
  [BiSolidPrinter, "BiSolidPrinter", "named", reactIconsBi],
  [BiSolidPurchaseTagAlt, "BiSolidPurchaseTagAlt", "named", reactIconsBi],
  [BiSolidPurchaseTag, "BiSolidPurchaseTag", "named", reactIconsBi],
  [BiSolidPyramid, "BiSolidPyramid", "named", reactIconsBi],
  [BiSolidQuoteAltLeft, "BiSolidQuoteAltLeft", "named", reactIconsBi],
  [BiSolidQuoteAltRight, "BiSolidQuoteAltRight", "named", reactIconsBi],
  [BiSolidQuoteLeft, "BiSolidQuoteLeft", "named", reactIconsBi],
  [BiSolidQuoteRight, "BiSolidQuoteRight", "named", reactIconsBi],
  [BiSolidQuoteSingleLeft, "BiSolidQuoteSingleLeft", "named", reactIconsBi],
  [BiSolidQuoteSingleRight, "BiSolidQuoteSingleRight", "named", reactIconsBi],
  [BiSolidRadiation, "BiSolidRadiation", "named", reactIconsBi],
  [BiSolidRadio, "BiSolidRadio", "named", reactIconsBi],
  [BiSolidReceipt, "BiSolidReceipt", "named", reactIconsBi],
  [BiSolidRectangle, "BiSolidRectangle", "named", reactIconsBi],
  [BiSolidRegistered, "BiSolidRegistered", "named", reactIconsBi],
  [BiSolidRename, "BiSolidRename", "named", reactIconsBi],
  [BiSolidReport, "BiSolidReport", "named", reactIconsBi],
  [BiSolidRewindCircle, "BiSolidRewindCircle", "named", reactIconsBi],
  [BiSolidRightArrowAlt, "BiSolidRightArrowAlt", "named", reactIconsBi],
  [BiSolidRightArrowCircle, "BiSolidRightArrowCircle", "named", reactIconsBi],
  [BiSolidRightArrowSquare, "BiSolidRightArrowSquare", "named", reactIconsBi],
  [BiSolidRightArrow, "BiSolidRightArrow", "named", reactIconsBi],
  [BiSolidRightDownArrowCircle, "BiSolidRightDownArrowCircle", "named", reactIconsBi],
  [BiSolidRightTopArrowCircle, "BiSolidRightTopArrowCircle", "named", reactIconsBi],
  [BiSolidRocket, "BiSolidRocket", "named", reactIconsBi],
  [BiSolidRuler, "BiSolidRuler", "named", reactIconsBi],
  [BiSolidSad, "BiSolidSad", "named", reactIconsBi],
  [BiSolidSave, "BiSolidSave", "named", reactIconsBi],
  [BiSolidSchool, "BiSolidSchool", "named", reactIconsBi],
  [BiSolidSearchAlt2, "BiSolidSearchAlt2", "named", reactIconsBi],
  [BiSolidSearch, "BiSolidSearch", "named", reactIconsBi],
  [BiSolidSelectMultiple, "BiSolidSelectMultiple", "named", reactIconsBi],
  [BiSolidSend, "BiSolidSend", "named", reactIconsBi],
  [BiSolidServer, "BiSolidServer", "named", reactIconsBi],
  [BiSolidShapes, "BiSolidShapes", "named", reactIconsBi],
  [BiSolidShareAlt, "BiSolidShareAlt", "named", reactIconsBi],
  [BiSolidShare, "BiSolidShare", "named", reactIconsBi],
  [BiSolidShieldAlt2, "BiSolidShieldAlt2", "named", reactIconsBi],
  [BiSolidShieldMinus, "BiSolidShieldMinus", "named", reactIconsBi],
  [BiSolidShieldPlus, "BiSolidShieldPlus", "named", reactIconsBi],
  [BiSolidShieldX, "BiSolidShieldX", "named", reactIconsBi],
  [BiSolidShield, "BiSolidShield", "named", reactIconsBi],
  [BiSolidShip, "BiSolidShip", "named", reactIconsBi],
  [BiSolidShocked, "BiSolidShocked", "named", reactIconsBi],
  [BiSolidShoppingBagAlt, "BiSolidShoppingBagAlt", "named", reactIconsBi],
  [BiSolidShoppingBag, "BiSolidShoppingBag", "named", reactIconsBi],
  [BiSolidShoppingBags, "BiSolidShoppingBags", "named", reactIconsBi],
  [BiSolidShow, "BiSolidShow", "named", reactIconsBi],
  [BiSolidShower, "BiSolidShower", "named", reactIconsBi],
  [BiSolidSkipNextCircle, "BiSolidSkipNextCircle", "named", reactIconsBi],
  [BiSolidSkipPreviousCircle, "BiSolidSkipPreviousCircle", "named", reactIconsBi],
  [BiSolidSkull, "BiSolidSkull", "named", reactIconsBi],
  [BiSolidSleepy, "BiSolidSleepy", "named", reactIconsBi],
  [BiSolidSlideshow, "BiSolidSlideshow", "named", reactIconsBi],
  [BiSolidSmile, "BiSolidSmile", "named", reactIconsBi],
  [BiSolidSortAlt, "BiSolidSortAlt", "named", reactIconsBi],
  [BiSolidSpa, "BiSolidSpa", "named", reactIconsBi],
  [BiSolidSpeaker, "BiSolidSpeaker", "named", reactIconsBi],
  [BiSolidSprayCan, "BiSolidSprayCan", "named", reactIconsBi],
  [BiSolidSpreadsheet, "BiSolidSpreadsheet", "named", reactIconsBi],
  [BiSolidSquareRounded, "BiSolidSquareRounded", "named", reactIconsBi],
  [BiSolidSquare, "BiSolidSquare", "named", reactIconsBi],
  [BiSolidStarHalf, "BiSolidStarHalf", "named", reactIconsBi],
  [BiSolidStar, "BiSolidStar", "named", reactIconsBi],
  [BiSolidSticker, "BiSolidSticker", "named", reactIconsBi],
  [BiSolidStopwatch, "BiSolidStopwatch", "named", reactIconsBi],
  [BiSolidStoreAlt, "BiSolidStoreAlt", "named", reactIconsBi],
  [BiSolidStore, "BiSolidStore", "named", reactIconsBi],
  [BiSolidSun, "BiSolidSun", "named", reactIconsBi],
  [BiSolidSushi, "BiSolidSushi", "named", reactIconsBi],
  [BiSolidTShirt, "BiSolidTShirt", "named", reactIconsBi],
  [BiSolidTachometer, "BiSolidTachometer", "named", reactIconsBi],
  [BiSolidTagAlt, "BiSolidTagAlt", "named", reactIconsBi],
  [BiSolidTagX, "BiSolidTagX", "named", reactIconsBi],
  [BiSolidTag, "BiSolidTag", "named", reactIconsBi],
  [BiSolidTaxi, "BiSolidTaxi", "named", reactIconsBi],
  [BiSolidTennisBall, "BiSolidTennisBall", "named", reactIconsBi],
  [BiSolidTerminal, "BiSolidTerminal", "named", reactIconsBi],
  [BiSolidThermometer, "BiSolidThermometer", "named", reactIconsBi],
  [BiSolidTimeFive, "BiSolidTimeFive", "named", reactIconsBi],
  [BiSolidTime, "BiSolidTime", "named", reactIconsBi],
  [BiSolidTimer, "BiSolidTimer", "named", reactIconsBi],
  [BiSolidTired, "BiSolidTired", "named", reactIconsBi],
  [BiSolidToTop, "BiSolidToTop", "named", reactIconsBi],
  [BiSolidToggleLeft, "BiSolidToggleLeft", "named", reactIconsBi],
  [BiSolidToggleRight, "BiSolidToggleRight", "named", reactIconsBi],
  [BiSolidTone, "BiSolidTone", "named", reactIconsBi],
  [BiSolidTorch, "BiSolidTorch", "named", reactIconsBi],
  [BiSolidTrafficBarrier, "BiSolidTrafficBarrier", "named", reactIconsBi],
  [BiSolidTrafficCone, "BiSolidTrafficCone", "named", reactIconsBi],
  [BiSolidTraffic, "BiSolidTraffic", "named", reactIconsBi],
  [BiSolidTrain, "BiSolidTrain", "named", reactIconsBi],
  [BiSolidTrashAlt, "BiSolidTrashAlt", "named", reactIconsBi],
  [BiSolidTrash, "BiSolidTrash", "named", reactIconsBi],
  [BiSolidTreeAlt, "BiSolidTreeAlt", "named", reactIconsBi],
  [BiSolidTree, "BiSolidTree", "named", reactIconsBi],
  [BiSolidTrophy, "BiSolidTrophy", "named", reactIconsBi],
  [BiSolidTruck, "BiSolidTruck", "named", reactIconsBi],
  [BiSolidTv, "BiSolidTv", "named", reactIconsBi],
  [BiSolidUniversalAccess, "BiSolidUniversalAccess", "named", reactIconsBi],
  [BiSolidUpArrowAlt, "BiSolidUpArrowAlt", "named", reactIconsBi],
  [BiSolidUpArrowCircle, "BiSolidUpArrowCircle", "named", reactIconsBi],
  [BiSolidUpArrowSquare, "BiSolidUpArrowSquare", "named", reactIconsBi],
  [BiSolidUpArrow, "BiSolidUpArrow", "named", reactIconsBi],
  [BiSolidUpsideDown, "BiSolidUpsideDown", "named", reactIconsBi],
  [BiSolidUpvote, "BiSolidUpvote", "named", reactIconsBi],
  [BiSolidUserAccount, "BiSolidUserAccount", "named", reactIconsBi],
  [BiSolidUserBadge, "BiSolidUserBadge", "named", reactIconsBi],
  [BiSolidUserCheck, "BiSolidUserCheck", "named", reactIconsBi],
  [BiSolidUserCircle, "BiSolidUserCircle", "named", reactIconsBi],
  [BiSolidUserDetail, "BiSolidUserDetail", "named", reactIconsBi],
  [BiSolidUserMinus, "BiSolidUserMinus", "named", reactIconsBi],
  [BiSolidUserPin, "BiSolidUserPin", "named", reactIconsBi],
  [BiSolidUserPlus, "BiSolidUserPlus", "named", reactIconsBi],
  [BiSolidUserRectangle, "BiSolidUserRectangle", "named", reactIconsBi],
  [BiSolidUserVoice, "BiSolidUserVoice", "named", reactIconsBi],
  [BiSolidUserX, "BiSolidUserX", "named", reactIconsBi],
  [BiSolidUser, "BiSolidUser", "named", reactIconsBi],
  [BiSolidVector, "BiSolidVector", "named", reactIconsBi],
  [BiSolidVial, "BiSolidVial", "named", reactIconsBi],
  [BiSolidVideoOff, "BiSolidVideoOff", "named", reactIconsBi],
  [BiSolidVideoPlus, "BiSolidVideoPlus", "named", reactIconsBi],
  [BiSolidVideoRecording, "BiSolidVideoRecording", "named", reactIconsBi],
  [BiSolidVideo, "BiSolidVideo", "named", reactIconsBi],
  [BiSolidVideos, "BiSolidVideos", "named", reactIconsBi],
  [BiSolidVirusBlock, "BiSolidVirusBlock", "named", reactIconsBi],
  [BiSolidVirus, "BiSolidVirus", "named", reactIconsBi],
  [BiSolidVolumeFull, "BiSolidVolumeFull", "named", reactIconsBi],
  [BiSolidVolumeLow, "BiSolidVolumeLow", "named", reactIconsBi],
  [BiSolidVolumeMute, "BiSolidVolumeMute", "named", reactIconsBi],
  [BiSolidVolume, "BiSolidVolume", "named", reactIconsBi],
  [BiSolidWalletAlt, "BiSolidWalletAlt", "named", reactIconsBi],
  [BiSolidWallet, "BiSolidWallet", "named", reactIconsBi],
  [BiSolidWasher, "BiSolidWasher", "named", reactIconsBi],
  [BiSolidWatchAlt, "BiSolidWatchAlt", "named", reactIconsBi],
  [BiSolidWatch, "BiSolidWatch", "named", reactIconsBi],
  [BiSolidWebcam, "BiSolidWebcam", "named", reactIconsBi],
  [BiSolidWidget, "BiSolidWidget", "named", reactIconsBi],
  [BiSolidWindowAlt, "BiSolidWindowAlt", "named", reactIconsBi],
  [BiSolidWine, "BiSolidWine", "named", reactIconsBi],
  [BiSolidWinkSmile, "BiSolidWinkSmile", "named", reactIconsBi],
  [BiSolidWinkTongue, "BiSolidWinkTongue", "named", reactIconsBi],
  [BiSolidWrench, "BiSolidWrench", "named", reactIconsBi],
  [BiSolidXCircle, "BiSolidXCircle", "named", reactIconsBi],
  [BiSolidXSquare, "BiSolidXSquare", "named", reactIconsBi],
  [BiSolidYinYang, "BiSolidYinYang", "named", reactIconsBi],
  [BiSolidZap, "BiSolidZap", "named", reactIconsBi],
  [BiSolidZoomIn, "BiSolidZoomIn", "named", reactIconsBi],
  [BiSolidZoomOut, "BiSolidZoomOut", "named", reactIconsBi],
  [BiLogo500Px, "BiLogo500Px", "named", reactIconsBi],
  [BiLogo99Designs, "BiLogo99Designs", "named", reactIconsBi],
  [BiLogoAdobe, "BiLogoAdobe", "named", reactIconsBi],
  [BiLogoAirbnb, "BiLogoAirbnb", "named", reactIconsBi],
  [BiLogoAlgolia, "BiLogoAlgolia", "named", reactIconsBi],
  [BiLogoAmazon, "BiLogoAmazon", "named", reactIconsBi],
  [BiLogoAndroid, "BiLogoAndroid", "named", reactIconsBi],
  [BiLogoAngular, "BiLogoAngular", "named", reactIconsBi],
  [BiLogoApple, "BiLogoApple", "named", reactIconsBi],
  [BiLogoAudible, "BiLogoAudible", "named", reactIconsBi],
  [BiLogoAws, "BiLogoAws", "named", reactIconsBi],
  [BiLogoBaidu, "BiLogoBaidu", "named", reactIconsBi],
  [BiLogoBehance, "BiLogoBehance", "named", reactIconsBi],
  [BiLogoBing, "BiLogoBing", "named", reactIconsBi],
  [BiLogoBitcoin, "BiLogoBitcoin", "named", reactIconsBi],
  [BiLogoBlender, "BiLogoBlender", "named", reactIconsBi],
  [BiLogoBlogger, "BiLogoBlogger", "named", reactIconsBi],
  [BiLogoBootstrap, "BiLogoBootstrap", "named", reactIconsBi],
  [BiLogoCPlusPlus, "BiLogoCPlusPlus", "named", reactIconsBi],
  [BiLogoChrome, "BiLogoChrome", "named", reactIconsBi],
  [BiLogoCodepen, "BiLogoCodepen", "named", reactIconsBi],
  [BiLogoCreativeCommons, "BiLogoCreativeCommons", "named", reactIconsBi],
  [BiLogoCss3, "BiLogoCss3", "named", reactIconsBi],
  [BiLogoDailymotion, "BiLogoDailymotion", "named", reactIconsBi],
  [BiLogoDeezer, "BiLogoDeezer", "named", reactIconsBi],
  [BiLogoDevTo, "BiLogoDevTo", "named", reactIconsBi],
  [BiLogoDeviantart, "BiLogoDeviantart", "named", reactIconsBi],
  [BiLogoDigg, "BiLogoDigg", "named", reactIconsBi],
  [BiLogoDigitalocean, "BiLogoDigitalocean", "named", reactIconsBi],
  [BiLogoDiscordAlt, "BiLogoDiscordAlt", "named", reactIconsBi],
  [BiLogoDiscord, "BiLogoDiscord", "named", reactIconsBi],
  [BiLogoDiscourse, "BiLogoDiscourse", "named", reactIconsBi],
  [BiLogoDjango, "BiLogoDjango", "named", reactIconsBi],
  [BiLogoDocker, "BiLogoDocker", "named", reactIconsBi],
  [BiLogoDribbble, "BiLogoDribbble", "named", reactIconsBi],
  [BiLogoDropbox, "BiLogoDropbox", "named", reactIconsBi],
  [BiLogoDrupal, "BiLogoDrupal", "named", reactIconsBi],
  [BiLogoEbay, "BiLogoEbay", "named", reactIconsBi],
  [BiLogoEdge, "BiLogoEdge", "named", reactIconsBi],
  [BiLogoEtsy, "BiLogoEtsy", "named", reactIconsBi],
  [BiLogoFacebookCircle, "BiLogoFacebookCircle", "named", reactIconsBi],
  [BiLogoFacebookSquare, "BiLogoFacebookSquare", "named", reactIconsBi],
  [BiLogoFacebook, "BiLogoFacebook", "named", reactIconsBi],
  [BiLogoFigma, "BiLogoFigma", "named", reactIconsBi],
  [BiLogoFirebase, "BiLogoFirebase", "named", reactIconsBi],
  [BiLogoFirefox, "BiLogoFirefox", "named", reactIconsBi],
  [BiLogoFlask, "BiLogoFlask", "named", reactIconsBi],
  [BiLogoFlickrSquare, "BiLogoFlickrSquare", "named", reactIconsBi],
  [BiLogoFlickr, "BiLogoFlickr", "named", reactIconsBi],
  [BiLogoFlutter, "BiLogoFlutter", "named", reactIconsBi],
  [BiLogoFoursquare, "BiLogoFoursquare", "named", reactIconsBi],
  [BiLogoGit, "BiLogoGit", "named", reactIconsBi],
  [BiLogoGithub, "BiLogoGithub", "named", reactIconsBi],
  [BiLogoGitlab, "BiLogoGitlab", "named", reactIconsBi],
  [BiLogoGmail, "BiLogoGmail", "named", reactIconsBi],
  [BiLogoGoLang, "BiLogoGoLang", "named", reactIconsBi],
  [BiLogoGoogleCloud, "BiLogoGoogleCloud", "named", reactIconsBi],
  [BiLogoGooglePlusCircle, "BiLogoGooglePlusCircle", "named", reactIconsBi],
  [BiLogoGooglePlus, "BiLogoGooglePlus", "named", reactIconsBi],
  [BiLogoGoogle, "BiLogoGoogle", "named", reactIconsBi],
  [BiLogoGraphql, "BiLogoGraphql", "named", reactIconsBi],
  [BiLogoHeroku, "BiLogoHeroku", "named", reactIconsBi],
  [BiLogoHtml5, "BiLogoHtml5", "named", reactIconsBi],
  [BiLogoImdb, "BiLogoImdb", "named", reactIconsBi],
  [BiLogoInstagramAlt, "BiLogoInstagramAlt", "named", reactIconsBi],
  [BiLogoInstagram, "BiLogoInstagram", "named", reactIconsBi],
  [BiLogoInternetExplorer, "BiLogoInternetExplorer", "named", reactIconsBi],
  [BiLogoInvision, "BiLogoInvision", "named", reactIconsBi],
  [BiLogoJava, "BiLogoJava", "named", reactIconsBi],
  [BiLogoJavascript, "BiLogoJavascript", "named", reactIconsBi],
  [BiLogoJoomla, "BiLogoJoomla", "named", reactIconsBi],
  [BiLogoJquery, "BiLogoJquery", "named", reactIconsBi],
  [BiLogoJsfiddle, "BiLogoJsfiddle", "named", reactIconsBi],
  [BiLogoKickstarter, "BiLogoKickstarter", "named", reactIconsBi],
  [BiLogoKubernetes, "BiLogoKubernetes", "named", reactIconsBi],
  [BiLogoLess, "BiLogoLess", "named", reactIconsBi],
  [BiLogoLinkedinSquare, "BiLogoLinkedinSquare", "named", reactIconsBi],
  [BiLogoLinkedin, "BiLogoLinkedin", "named", reactIconsBi],
  [BiLogoMagento, "BiLogoMagento", "named", reactIconsBi],
  [BiLogoMailchimp, "BiLogoMailchimp", "named", reactIconsBi],
  [BiLogoMarkdown, "BiLogoMarkdown", "named", reactIconsBi],
  [BiLogoMastercard, "BiLogoMastercard", "named", reactIconsBi],
  [BiLogoMastodon, "BiLogoMastodon", "named", reactIconsBi],
  [BiLogoMediumOld, "BiLogoMediumOld", "named", reactIconsBi],
  [BiLogoMediumSquare, "BiLogoMediumSquare", "named", reactIconsBi],
  [BiLogoMedium, "BiLogoMedium", "named", reactIconsBi],
  [BiLogoMessenger, "BiLogoMessenger", "named", reactIconsBi],
  [BiLogoMeta, "BiLogoMeta", "named", reactIconsBi],
  [BiLogoMicrosoftTeams, "BiLogoMicrosoftTeams", "named", reactIconsBi],
  [BiLogoMicrosoft, "BiLogoMicrosoft", "named", reactIconsBi],
  [BiLogoMongodb, "BiLogoMongodb", "named", reactIconsBi],
  [BiLogoNetlify, "BiLogoNetlify", "named", reactIconsBi],
  [BiLogoNodejs, "BiLogoNodejs", "named", reactIconsBi],
  [BiLogoOkRu, "BiLogoOkRu", "named", reactIconsBi],
  [BiLogoOpera, "BiLogoOpera", "named", reactIconsBi],
  [BiLogoPatreon, "BiLogoPatreon", "named", reactIconsBi],
  [BiLogoPaypal, "BiLogoPaypal", "named", reactIconsBi],
  [BiLogoPeriscope, "BiLogoPeriscope", "named", reactIconsBi],
  [BiLogoPhp, "BiLogoPhp", "named", reactIconsBi],
  [BiLogoPinterestAlt, "BiLogoPinterestAlt", "named", reactIconsBi],
  [BiLogoPinterest, "BiLogoPinterest", "named", reactIconsBi],
  [BiLogoPlayStore, "BiLogoPlayStore", "named", reactIconsBi],
  [BiLogoPocket, "BiLogoPocket", "named", reactIconsBi],
  [BiLogoPostgresql, "BiLogoPostgresql", "named", reactIconsBi],
  [BiLogoProductHunt, "BiLogoProductHunt", "named", reactIconsBi],
  [BiLogoPython, "BiLogoPython", "named", reactIconsBi],
  [BiLogoQuora, "BiLogoQuora", "named", reactIconsBi],
  [BiLogoReact, "BiLogoReact", "named", reactIconsBi],
  [BiLogoRedbubble, "BiLogoRedbubble", "named", reactIconsBi],
  [BiLogoReddit, "BiLogoReddit", "named", reactIconsBi],
  [BiLogoRedux, "BiLogoRedux", "named", reactIconsBi],
  [BiLogoSass, "BiLogoSass", "named", reactIconsBi],
  [BiLogoShopify, "BiLogoShopify", "named", reactIconsBi],
  [BiLogoSketch, "BiLogoSketch", "named", reactIconsBi],
  [BiLogoSkype, "BiLogoSkype", "named", reactIconsBi],
  [BiLogoSlackOld, "BiLogoSlackOld", "named", reactIconsBi],
  [BiLogoSlack, "BiLogoSlack", "named", reactIconsBi],
  [BiLogoSnapchat, "BiLogoSnapchat", "named", reactIconsBi],
  [BiLogoSoundcloud, "BiLogoSoundcloud", "named", reactIconsBi],
  [BiLogoSpotify, "BiLogoSpotify", "named", reactIconsBi],
  [BiLogoSpringBoot, "BiLogoSpringBoot", "named", reactIconsBi],
  [BiLogoSquarespace, "BiLogoSquarespace", "named", reactIconsBi],
  [BiLogoStackOverflow, "BiLogoStackOverflow", "named", reactIconsBi],
  [BiLogoSteam, "BiLogoSteam", "named", reactIconsBi],
  [BiLogoStripe, "BiLogoStripe", "named", reactIconsBi],
  [BiLogoTailwindCss, "BiLogoTailwindCss", "named", reactIconsBi],
  [BiLogoTelegram, "BiLogoTelegram", "named", reactIconsBi],
  [BiLogoTiktok, "BiLogoTiktok", "named", reactIconsBi],
  [BiLogoTrello, "BiLogoTrello", "named", reactIconsBi],
  [BiLogoTripAdvisor, "BiLogoTripAdvisor", "named", reactIconsBi],
  [BiLogoTumblr, "BiLogoTumblr", "named", reactIconsBi],
  [BiLogoTux, "BiLogoTux", "named", reactIconsBi],
  [BiLogoTwitch, "BiLogoTwitch", "named", reactIconsBi],
  [BiLogoTwitter, "BiLogoTwitter", "named", reactIconsBi],
  [BiLogoTypescript, "BiLogoTypescript", "named", reactIconsBi],
  [BiLogoUnity, "BiLogoUnity", "named", reactIconsBi],
  [BiLogoUnsplash, "BiLogoUnsplash", "named", reactIconsBi],
  [BiLogoUpwork, "BiLogoUpwork", "named", reactIconsBi],
  [BiLogoVenmo, "BiLogoVenmo", "named", reactIconsBi],
  [BiLogoVimeo, "BiLogoVimeo", "named", reactIconsBi],
  [BiLogoVisa, "BiLogoVisa", "named", reactIconsBi],
  [BiLogoVisualStudio, "BiLogoVisualStudio", "named", reactIconsBi],
  [BiLogoVk, "BiLogoVk", "named", reactIconsBi],
  [BiLogoVuejs, "BiLogoVuejs", "named", reactIconsBi],
  [BiLogoWhatsappSquare, "BiLogoWhatsappSquare", "named", reactIconsBi],
  [BiLogoWhatsapp, "BiLogoWhatsapp", "named", reactIconsBi],
  [BiLogoWikipedia, "BiLogoWikipedia", "named", reactIconsBi],
  [BiLogoWindows, "BiLogoWindows", "named", reactIconsBi],
  [BiLogoWix, "BiLogoWix", "named", reactIconsBi],
  [BiLogoWordpress, "BiLogoWordpress", "named", reactIconsBi],
  [BiLogoXing, "BiLogoXing", "named", reactIconsBi],
  [BiLogoYahoo, "BiLogoYahoo", "named", reactIconsBi],
  [BiLogoYelp, "BiLogoYelp", "named", reactIconsBi],
  [BiLogoYoutube, "BiLogoYoutube", "named", reactIconsBi],
  [BiLogoZoom, "BiLogoZoom", "named", reactIconsBi],
]);
