import * as React from "react";

export function defined<T>(value: T | undefined, message?: string): T {
  if (value === undefined) {
    throw new Error(message ?? "Value is undefined");
  }
  return value;
}

export function createContextManager<T>(initialValue: T) {
  const stack: Readonly<T>[] = [initialValue];
  return function withContext<R>(fn: (value: Readonly<T>, setValue: (value: T) => void) => R): R {
    let localStackCount = 0;
    const setValue = (value: T) => {
      localStackCount += 1;
      stack.push(value);
    };
    try {
      return fn(stack[stack.length - 1], setValue);
    } finally {
      stack.splice(stack.length - localStackCount, stack.length);
    }
  };
}

type ErrorBoundaryProps = {
  fallback: React.ReactNode;
  children: React.ReactNode;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}
