import * as React from "react";
import {
  DocumentNode,
  TextNode,
  SlotNode,
  ElementNode,
  AnnotationNode,
  searchFromTree,
  ManagedValue,
  PrimitiveValue,
  ArrayValue,
  ObjectValue,
  DocumentNodeValue,
} from "../ast";
import { ElementState } from "../editing";

const initialHue = 210;
function colorAtDepth(depth: number) {
  return initialHue + depth * 101;
}

function Row({
  depth,
  symbol,
  children,
}: {
  depth: number;
  symbol: string;
  children: React.ReactNode;
}) {
  const currentHue = colorAtDepth(depth);
  const background = `linear-gradient(to right, ${Array.from({ length: depth + 1 }, (_, index) => `hsl(${colorAtDepth(index)} 60% 95%) ${index * 40 + offset}px ${(index + 1) * 40 + offset}px, `).join("")}hsl(${currentHue} 60% 95%))`;
  const paddingLeft = depth * 40 + offset;
  return (
    <div className="tagging-helper-tree-row">
      <div className="tagging-helper-tree-symbol">{symbol}</div>
      <div className="tagging-helper-tree-content" style={{ paddingLeft, background }}>
        {children}
      </div>
    </div>
  );
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    console.log("Copied to clipboard: ", text);
  });
};

function TreeValue({ value }: { value: string }) {
  return (
    <span className="tagging-helper-tree-value" onClick={() => copyToClipboard(value)}>
      {value}
    </span>
  );
}

function textInProps(value: ManagedValue, text: string): boolean {
  if (value instanceof PrimitiveValue && value.value === text) {
    return true;
  }
  if (value instanceof ArrayValue && value.items.some((item) => textInProps(item, text))) {
    return true;
  }
  if (value instanceof ObjectValue && value.entries.some(([, item]) => textInProps(item, text))) {
    return true;
  }
  if (value instanceof DocumentNodeValue) {
    return textInNode(value.node, text);
  }
  return false;
}

export function textFromNode(node: DocumentNode | undefined, text: string): string {
  if (node == null) {
    return "";
  }
  let nodeText = "";
  Array.from(
    searchFromTree(
      node,
      (node): node is DocumentNode => {
        if (node instanceof TextNode && node.text === text) {
          nodeText = node.text;
        }
        return false;
      },
      !(node instanceof AnnotationNode),
    ),
  );
  console.log(nodeText);
  return nodeText;
}

export function textInNode(node: DocumentNode | undefined, text: string): boolean {
  if (node == null) {
    return false;
  }
  let found = false;
  // todo - rewrite the following using something more readable, e.g. visitor pattern
  Array.from(
    searchFromTree(
      node,
      (node): node is DocumentNode => {
        if (node instanceof AnnotationNode) {
          return true;
        }
        if (node instanceof TextNode && node.text === text) {
          found = true;
        }
        if ("props" in node) {
          if (textInProps(node.props, text)) {
            found = true;
          }
        }
        return false;
      },
      !(node instanceof AnnotationNode),
    ),
  );
  return found;
}

function renderSlots(
  slots: Record<string, ElementState[]> | undefined,
  isList: boolean,
  slotTemplates: SlotNode[],
  depth: number,
  ignoredTexts?: {
    elementName: string;
    text: string;
  }[],
) {
  if (slots == null) {
    return;
  }
  return Object.entries(slots).map(([slotName, slot], index) => {
    const slotTemplate = slotTemplates.find(
      (node) => node.isList === isList && node.match(slotName),
    );

    const nonElementChildrenIndex =
      (slotTemplate && slotTemplate.children.findIndex((node) => !(node instanceof ElementNode))) ??
      -1;
    return (
      <React.Fragment key={index}>
        <Row
          symbol={slotTemplate == null ? "❌" : nonElementChildrenIndex > -1 ? "🟠" : "✅"}
          depth={depth}
        >
          <pre>
            <strong
              className="tagging-helper-tree-pill"
              style={{ color: `hsl(${colorAtDepth(depth)} 100% 40%)` }}
            >
              {isList ? "List" : "Slot"}
            </strong>
            <TreeValue value={slotName} />

            {nonElementChildrenIndex > -1 && (
              <span className="tagging-helper-tree-row-highlighted">
                [detected non-element child at index {nonElementChildrenIndex}]
              </span>
            )}
          </pre>
        </Row>
        {slot.map((item, index) => {
          const elementTemplate = slotTemplate?.children
            .filter((node) => node instanceof ElementNode)
            .find((node) => node.match(item.name));
          return (
            <ElementTree
              key={index}
              element={item}
              template={elementTemplate}
              ignoredTexts={ignoredTexts}
            />
          );
        })}
      </React.Fragment>
    );
  });
}

const treeContext = React.createContext<number>(0);
const offset = 0;

export function ElementTree({
  element: { name, texts, slots, lists },
  template,
  ignoredTexts,
}: {
  element: ElementState;
  template?: DocumentNode;
  ignoredTexts?: {
    elementName: string;
    text: string;
  }[];
}) {
  const depth = React.useContext(treeContext);
  const currentHue = colorAtDepth(depth);
  const annotationNodes =
    (template &&
      Array.from(
        searchFromTree(template, (node) => {
          return node instanceof AnnotationNode;
        }),
      )) ??
    [];
  const danglingElements = annotationNodes.filter((node) => node instanceof ElementNode).length;
  const slotTemplates = annotationNodes.filter((node) => node instanceof SlotNode);

  return (
    <treeContext.Provider value={depth + 1}>
      <Row symbol={template == null ? "❌" : danglingElements > 0 ? "🟠" : "✅"} depth={depth}>
        <pre style={{ backgroundColor: `hsl(${currentHue} 45% 85%)` }}>
          <TreeValue value={name} />
          {danglingElements > 0 && (
            <span className="tagging-helper-tree-row-highlighted">
              [detected {danglingElements} elements tagged outside of slots, element needs to be
              direct children of slots or lists]
            </span>
          )}
        </pre>
      </Row>

      {texts &&
        texts.map(([original], index) => {
          if (
            ignoredTexts?.find(
              (ignored) => ignored.elementName === name && ignored.text === original,
            )
          ) {
            return ignoredTexts?.map((ignored) => {
              const isIgnored = ignored.elementName === name && ignored.text === original;
              // const ignoredText = ignored.text;
              if (isIgnored) {
                return (
                  <Row key={index} symbol={"❎"} depth={depth}>
                    <pre>
                      <strong
                        className="tagging-helper-tree-pill"
                        style={{ color: `hsl(${currentHue} 100% 40%)` }}
                      >
                        Text
                      </strong>
                      <span className="tagging-helper-ignored">{original}</span>
                    </pre>
                  </Row>
                );
              }
            });
          } else {
            return (
              <Row key={index} symbol={textInNode(template, original) ? "✅" : "❌"} depth={depth}>
                <pre>
                  <strong
                    className="tagging-helper-tree-pill"
                    style={{ color: `hsl(${currentHue} 100% 40%)` }}
                  >
                    Text
                  </strong>
                  {original}
                </pre>
              </Row>
            );
          }
          return (
            <Row key={index} symbol={textInNode(template, original) ? "✅" : "❌"} depth={depth}>
              <div>
                <strong
                  className="tagging-helper-tree-pill"
                  style={{ color: `hsl(${currentHue} 100% 40%)` }}
                >
                  Text
                </strong>
                <span className="tagging-helper-tree-text-original">{original}</span>
                <span className="tagging-helper-tree-text-template">
                  value found: {template && textFromNode(template, original)}
                </span>
              </div>
            </Row>
          );
        })}
      {renderSlots(slots, false, slotTemplates, depth, ignoredTexts)}
      {renderSlots(lists, true, slotTemplates, depth, ignoredTexts)}
    </treeContext.Provider>
  );
}
