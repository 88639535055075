import { importRegistry } from "../import-registry";
import {
  Checkbox,
  Tabs,
  DialogContent,
  AccordionContent,
  Button,
  Accordion,
  Dialog,
  SelectItem,
  useMediaQuery,
  TabsTrigger,
  buttonVariants,
  Select,
  DialogTrigger,
  Label,
  Switch,
  Textarea,
  AccordionTrigger,
  RadioGroup,
  SingleFieldForm,
  RadioGroupItem,
  SelectContent,
  VideoIframe,
  TabsContent,
  SelectTrigger,
  TabsList,
  Input,
  AccordionItem,
} from "@relume_io/relume-ui";

const relumeUi = "@relume_io/relume-ui";
importRegistry.registerImports([
  [Checkbox, "Checkbox", "named", relumeUi],
  [Tabs, "Tabs", "named", relumeUi],
  [DialogContent, "DialogContent", "named", relumeUi],
  [AccordionContent, "AccordionContent", "named", relumeUi],
  [Button, "Button", "named", relumeUi],
  [Accordion, "Accordion", "named", relumeUi],
  [Dialog, "Dialog", "named", relumeUi],
  [SelectItem, "SelectItem", "named", relumeUi],
  [useMediaQuery, "useMediaQuery", "named", relumeUi],
  [TabsTrigger, "TabsTrigger", "named", relumeUi],
  [buttonVariants, "buttonVariants", "named", relumeUi],
  [Select, "Select", "named", relumeUi],
  [DialogTrigger, "DialogTrigger", "named", relumeUi],
  [Label, "Label", "named", relumeUi],
  [Switch, "Switch", "named", relumeUi],
  [Textarea, "Textarea", "named", relumeUi],
  [AccordionTrigger, "AccordionTrigger", "named", relumeUi],
  [RadioGroup, "RadioGroup", "named", relumeUi],
  [SingleFieldForm, "SingleFieldForm", "named", relumeUi],
  [RadioGroupItem, "RadioGroupItem", "named", relumeUi],
  [SelectContent, "SelectContent", "named", relumeUi],
  [VideoIframe, "VideoIframe", "named", relumeUi],
  [TabsContent, "TabsContent", "named", relumeUi],
  [SelectTrigger, "SelectTrigger", "named", relumeUi],
  [TabsList, "TabsList", "named", relumeUi],
  [Input, "Input", "named", relumeUi],
  [AccordionItem, "AccordionItem", "named", relumeUi],
]);
