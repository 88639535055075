import React from "react";

export type ComponentOptions = {
  ignoredTexts?: { elementName: string; text: string }[];
};

export type TaggingContextType = {
  // todo: refactor and move this into storybook
  renderComponent: (
    Component: React.FunctionComponent,
    options?: ComponentOptions,
  ) => React.ReactNode;
};

const taggingContext = React.createContext<TaggingContextType>({
  renderComponent: (Component) => React.createElement(Component),
});

export const TaggingContextProvider = taggingContext.Provider;

type TaggingComponentProps = { children: React.ReactNode } & (
  | { name: string }
  | { match: (name: string) => boolean }
);

export function ElementAnchor(props: TaggingComponentProps) {
  return props.children;
}

export function SlotAnchor(props: TaggingComponentProps) {
  return props.children;
}

export function ListAnchor(props: TaggingComponentProps) {
  return props.children;
}

export function registerComponent(
  Component: React.FunctionComponent,
  options?: ComponentOptions,
): React.FunctionComponent & {
  source: React.FunctionComponent;
  options?: ComponentOptions;
} {
  function RegisteredComponent() {
    const { renderComponent } = React.useContext(taggingContext);
    return renderComponent(Component, options);
  }

  RegisteredComponent.source = Component;
  RegisteredComponent.options = options;

  return RegisteredComponent;
}

export function includes(text: string) {
  return function (name: string) {
    return name.includes(text);
  };
}

export function oneOf(texts: string[]) {
  return function (name: string) {
    return texts.includes(name);
  };
}
