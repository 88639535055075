import { importRegistry } from "../import-registry";
import {
  RxAccessibility,
  RxActivityLog,
  RxAlignBaseline,
  RxAlignBottom,
  RxAlignCenterHorizontally,
  RxAlignCenterVertically,
  RxAlignLeft,
  RxAlignRight,
  RxAlignTop,
  RxAllSides,
  RxAngle,
  RxArchive,
  RxArrowBottomLeft,
  RxArrowBottomRight,
  RxArrowDown,
  RxArrowLeft,
  RxArrowRight,
  RxArrowTopLeft,
  RxArrowTopRight,
  RxArrowUp,
  RxAspectRatio,
  RxAvatar,
  RxBackpack,
  RxBadge,
  RxBarChart,
  RxBell,
  RxBlendingMode,
  RxBookmarkFilled,
  RxBookmark,
  RxBorderAll,
  RxBorderBottom,
  RxBorderDashed,
  RxBorderDotted,
  RxBorderLeft,
  RxBorderNone,
  RxBorderRight,
  RxBorderSolid,
  RxBorderSplit,
  RxBorderStyle,
  RxBorderTop,
  RxBorderWidth,
  RxBoxModel,
  RxBox,
  RxButton,
  RxCalendar,
  RxCamera,
  RxCardStackMinus,
  RxCardStackPlus,
  RxCardStack,
  RxCaretDown,
  RxCaretLeft,
  RxCaretRight,
  RxCaretSort,
  RxCaretUp,
  RxChatBubble,
  RxCheckCircled,
  RxCheck,
  RxCheckbox,
  RxChevronDown,
  RxChevronLeft,
  RxChevronRight,
  RxChevronUp,
  RxCircleBackslash,
  RxCircle,
  RxClipboardCopy,
  RxClipboard,
  RxClock,
  RxCode,
  RxCodesandboxLogo,
  RxColorWheel,
  RxColumnSpacing,
  RxColumns,
  RxCommit,
  RxComponent1,
  RxComponent2,
  RxComponentBoolean,
  RxComponentInstance,
  RxComponentNone,
  RxComponentPlaceholder,
  RxContainer,
  RxCookie,
  RxCopy,
  RxCornerBottomLeft,
  RxCornerBottomRight,
  RxCornerTopLeft,
  RxCornerTopRight,
  RxCorners,
  RxCountdownTimer,
  RxCounterClockwiseClock,
  RxCrop,
  RxCross1,
  RxCross2,
  RxCrossCircled,
  RxCrosshair1,
  RxCrosshair2,
  RxCrumpledPaper,
  RxCube,
  RxCursorArrow,
  RxCursorText,
  RxDash,
  RxDashboard,
  RxDesktop,
  RxDimensions,
  RxDisc,
  RxDiscordLogo,
  RxDividerHorizontal,
  RxDividerVertical,
  RxDotFilled,
  RxDot,
  RxDotsHorizontal,
  RxDotsVertical,
  RxDoubleArrowDown,
  RxDoubleArrowLeft,
  RxDoubleArrowRight,
  RxDoubleArrowUp,
  RxDownload,
  RxDragHandleDots1,
  RxDragHandleDots2,
  RxDragHandleHorizontal,
  RxDragHandleVertical,
  RxDrawingPinFilled,
  RxDrawingPin,
  RxDropdownMenu,
  RxEnterFullScreen,
  RxEnter,
  RxEnvelopeClosed,
  RxEnvelopeOpen,
  RxEraser,
  RxExclamationTriangle,
  RxExitFullScreen,
  RxExit,
  RxExternalLink,
  RxEyeClosed,
  RxEyeNone,
  RxEyeOpen,
  RxFace,
  RxFigmaLogo,
  RxFileMinus,
  RxFilePlus,
  RxFileText,
  RxFile,
  RxFontBold,
  RxFontFamily,
  RxFontItalic,
  RxFontRoman,
  RxFontSize,
  RxFontStyle,
  RxFrame,
  RxFramerLogo,
  RxGear,
  RxGithubLogo,
  RxGlobe,
  RxGrid,
  RxGroup,
  RxHalf1,
  RxHalf2,
  RxHamburgerMenu,
  RxHand,
  RxHeading,
  RxHeartFilled,
  RxHeart,
  RxHeight,
  RxHobbyKnife,
  RxHome,
  RxIconjarLogo,
  RxIdCard,
  RxImage,
  RxInfoCircled,
  RxInput,
  RxInstagramLogo,
  RxKeyboard,
  RxLapTimer,
  RxLaptop,
  RxLayers,
  RxLayout,
  RxLetterCaseCapitalize,
  RxLetterCaseLowercase,
  RxLetterCaseToggle,
  RxLetterCaseUppercase,
  RxLetterSpacing,
  RxLightningBolt,
  RxLineHeight,
  RxLink1,
  RxLink2,
  RxLinkBreak1,
  RxLinkBreak2,
  RxLinkNone1,
  RxLinkNone2,
  RxLinkedinLogo,
  RxListBullet,
  RxLockClosed,
  RxLockOpen1,
  RxLockOpen2,
  RxLoop,
  RxMagicWand,
  RxMagnifyingGlass,
  RxMargin,
  RxMaskOff,
  RxMaskOn,
  RxMinusCircled,
  RxMinus,
  RxMix,
  RxMixerHorizontal,
  RxMixerVertical,
  RxMobile,
  RxModulzLogo,
  RxMoon,
  RxMove,
  RxNotionLogo,
  RxOpacity,
  RxOpenInNewWindow,
  RxOverline,
  RxPadding,
  RxPaperPlane,
  RxPause,
  RxPencil1,
  RxPencil2,
  RxPerson,
  RxPieChart,
  RxPilcrow,
  RxPinBottom,
  RxPinLeft,
  RxPinRight,
  RxPinTop,
  RxPlay,
  RxPlusCircled,
  RxPlus,
  RxQuestionMarkCircled,
  RxQuestionMark,
  RxQuote,
  RxRadiobutton,
  RxReader,
  RxReload,
  RxReset,
  RxResume,
  RxRocket,
  RxRotateCounterClockwise,
  RxRowSpacing,
  RxRows,
  RxRulerHorizontal,
  RxRulerSquare,
  RxScissors,
  RxSection,
  RxSewingPinFilled,
  RxSewingPin,
  RxShadowInner,
  RxShadowNone,
  RxShadowOuter,
  RxShadow,
  RxShare1,
  RxShare2,
  RxShuffle,
  RxSize,
  RxSketchLogo,
  RxSlash,
  RxSlider,
  RxSpaceBetweenHorizontally,
  RxSpaceBetweenVertically,
  RxSpaceEvenlyHorizontally,
  RxSpaceEvenlyVertically,
  RxSpeakerLoud,
  RxSpeakerModerate,
  RxSpeakerOff,
  RxSpeakerQuiet,
  RxSquare,
  RxStack,
  RxStarFilled,
  RxStar,
  RxStitchesLogo,
  RxStop,
  RxStopwatch,
  RxStretchHorizontally,
  RxStretchVertically,
  RxStrikethrough,
  RxSun,
  RxSwitch,
  RxSymbol,
  RxTable,
  RxTarget,
  RxTextAlignBottom,
  RxTextAlignCenter,
  RxTextAlignJustify,
  RxTextAlignLeft,
  RxTextAlignMiddle,
  RxTextAlignRight,
  RxTextAlignTop,
  RxTextNone,
  RxText,
  RxThickArrowDown,
  RxThickArrowLeft,
  RxThickArrowRight,
  RxThickArrowUp,
  RxTimer,
  RxTokens,
  RxTrackNext,
  RxTrackPrevious,
  RxTransform,
  RxTransparencyGrid,
  RxTrash,
  RxTriangleDown,
  RxTriangleLeft,
  RxTriangleRight,
  RxTriangleUp,
  RxTwitterLogo,
  RxUnderline,
  RxUpdate,
  RxUpload,
  RxValueNone,
  RxValue,
  RxVercelLogo,
  RxVideo,
  RxViewGrid,
  RxViewHorizontal,
  RxViewNone,
  RxViewVertical,
  RxWidth,
  RxZoomIn,
  RxZoomOut,
} from "react-icons/rx";

const reactIconsRx = "react-icons/rx";
importRegistry.registerImports([
  [RxAccessibility, "RxAccessibility", "named", reactIconsRx],
  [RxActivityLog, "RxActivityLog", "named", reactIconsRx],
  [RxAlignBaseline, "RxAlignBaseline", "named", reactIconsRx],
  [RxAlignBottom, "RxAlignBottom", "named", reactIconsRx],
  [RxAlignCenterHorizontally, "RxAlignCenterHorizontally", "named", reactIconsRx],
  [RxAlignCenterVertically, "RxAlignCenterVertically", "named", reactIconsRx],
  [RxAlignLeft, "RxAlignLeft", "named", reactIconsRx],
  [RxAlignRight, "RxAlignRight", "named", reactIconsRx],
  [RxAlignTop, "RxAlignTop", "named", reactIconsRx],
  [RxAllSides, "RxAllSides", "named", reactIconsRx],
  [RxAngle, "RxAngle", "named", reactIconsRx],
  [RxArchive, "RxArchive", "named", reactIconsRx],
  [RxArrowBottomLeft, "RxArrowBottomLeft", "named", reactIconsRx],
  [RxArrowBottomRight, "RxArrowBottomRight", "named", reactIconsRx],
  [RxArrowDown, "RxArrowDown", "named", reactIconsRx],
  [RxArrowLeft, "RxArrowLeft", "named", reactIconsRx],
  [RxArrowRight, "RxArrowRight", "named", reactIconsRx],
  [RxArrowTopLeft, "RxArrowTopLeft", "named", reactIconsRx],
  [RxArrowTopRight, "RxArrowTopRight", "named", reactIconsRx],
  [RxArrowUp, "RxArrowUp", "named", reactIconsRx],
  [RxAspectRatio, "RxAspectRatio", "named", reactIconsRx],
  [RxAvatar, "RxAvatar", "named", reactIconsRx],
  [RxBackpack, "RxBackpack", "named", reactIconsRx],
  [RxBadge, "RxBadge", "named", reactIconsRx],
  [RxBarChart, "RxBarChart", "named", reactIconsRx],
  [RxBell, "RxBell", "named", reactIconsRx],
  [RxBlendingMode, "RxBlendingMode", "named", reactIconsRx],
  [RxBookmarkFilled, "RxBookmarkFilled", "named", reactIconsRx],
  [RxBookmark, "RxBookmark", "named", reactIconsRx],
  [RxBorderAll, "RxBorderAll", "named", reactIconsRx],
  [RxBorderBottom, "RxBorderBottom", "named", reactIconsRx],
  [RxBorderDashed, "RxBorderDashed", "named", reactIconsRx],
  [RxBorderDotted, "RxBorderDotted", "named", reactIconsRx],
  [RxBorderLeft, "RxBorderLeft", "named", reactIconsRx],
  [RxBorderNone, "RxBorderNone", "named", reactIconsRx],
  [RxBorderRight, "RxBorderRight", "named", reactIconsRx],
  [RxBorderSolid, "RxBorderSolid", "named", reactIconsRx],
  [RxBorderSplit, "RxBorderSplit", "named", reactIconsRx],
  [RxBorderStyle, "RxBorderStyle", "named", reactIconsRx],
  [RxBorderTop, "RxBorderTop", "named", reactIconsRx],
  [RxBorderWidth, "RxBorderWidth", "named", reactIconsRx],
  [RxBoxModel, "RxBoxModel", "named", reactIconsRx],
  [RxBox, "RxBox", "named", reactIconsRx],
  [RxButton, "RxButton", "named", reactIconsRx],
  [RxCalendar, "RxCalendar", "named", reactIconsRx],
  [RxCamera, "RxCamera", "named", reactIconsRx],
  [RxCardStackMinus, "RxCardStackMinus", "named", reactIconsRx],
  [RxCardStackPlus, "RxCardStackPlus", "named", reactIconsRx],
  [RxCardStack, "RxCardStack", "named", reactIconsRx],
  [RxCaretDown, "RxCaretDown", "named", reactIconsRx],
  [RxCaretLeft, "RxCaretLeft", "named", reactIconsRx],
  [RxCaretRight, "RxCaretRight", "named", reactIconsRx],
  [RxCaretSort, "RxCaretSort", "named", reactIconsRx],
  [RxCaretUp, "RxCaretUp", "named", reactIconsRx],
  [RxChatBubble, "RxChatBubble", "named", reactIconsRx],
  [RxCheckCircled, "RxCheckCircled", "named", reactIconsRx],
  [RxCheck, "RxCheck", "named", reactIconsRx],
  [RxCheckbox, "RxCheckbox", "named", reactIconsRx],
  [RxChevronDown, "RxChevronDown", "named", reactIconsRx],
  [RxChevronLeft, "RxChevronLeft", "named", reactIconsRx],
  [RxChevronRight, "RxChevronRight", "named", reactIconsRx],
  [RxChevronUp, "RxChevronUp", "named", reactIconsRx],
  [RxCircleBackslash, "RxCircleBackslash", "named", reactIconsRx],
  [RxCircle, "RxCircle", "named", reactIconsRx],
  [RxClipboardCopy, "RxClipboardCopy", "named", reactIconsRx],
  [RxClipboard, "RxClipboard", "named", reactIconsRx],
  [RxClock, "RxClock", "named", reactIconsRx],
  [RxCode, "RxCode", "named", reactIconsRx],
  [RxCodesandboxLogo, "RxCodesandboxLogo", "named", reactIconsRx],
  [RxColorWheel, "RxColorWheel", "named", reactIconsRx],
  [RxColumnSpacing, "RxColumnSpacing", "named", reactIconsRx],
  [RxColumns, "RxColumns", "named", reactIconsRx],
  [RxCommit, "RxCommit", "named", reactIconsRx],
  [RxComponent1, "RxComponent1", "named", reactIconsRx],
  [RxComponent2, "RxComponent2", "named", reactIconsRx],
  [RxComponentBoolean, "RxComponentBoolean", "named", reactIconsRx],
  [RxComponentInstance, "RxComponentInstance", "named", reactIconsRx],
  [RxComponentNone, "RxComponentNone", "named", reactIconsRx],
  [RxComponentPlaceholder, "RxComponentPlaceholder", "named", reactIconsRx],
  [RxContainer, "RxContainer", "named", reactIconsRx],
  [RxCookie, "RxCookie", "named", reactIconsRx],
  [RxCopy, "RxCopy", "named", reactIconsRx],
  [RxCornerBottomLeft, "RxCornerBottomLeft", "named", reactIconsRx],
  [RxCornerBottomRight, "RxCornerBottomRight", "named", reactIconsRx],
  [RxCornerTopLeft, "RxCornerTopLeft", "named", reactIconsRx],
  [RxCornerTopRight, "RxCornerTopRight", "named", reactIconsRx],
  [RxCorners, "RxCorners", "named", reactIconsRx],
  [RxCountdownTimer, "RxCountdownTimer", "named", reactIconsRx],
  [RxCounterClockwiseClock, "RxCounterClockwiseClock", "named", reactIconsRx],
  [RxCrop, "RxCrop", "named", reactIconsRx],
  [RxCross1, "RxCross1", "named", reactIconsRx],
  [RxCross2, "RxCross2", "named", reactIconsRx],
  [RxCrossCircled, "RxCrossCircled", "named", reactIconsRx],
  [RxCrosshair1, "RxCrosshair1", "named", reactIconsRx],
  [RxCrosshair2, "RxCrosshair2", "named", reactIconsRx],
  [RxCrumpledPaper, "RxCrumpledPaper", "named", reactIconsRx],
  [RxCube, "RxCube", "named", reactIconsRx],
  [RxCursorArrow, "RxCursorArrow", "named", reactIconsRx],
  [RxCursorText, "RxCursorText", "named", reactIconsRx],
  [RxDash, "RxDash", "named", reactIconsRx],
  [RxDashboard, "RxDashboard", "named", reactIconsRx],
  [RxDesktop, "RxDesktop", "named", reactIconsRx],
  [RxDimensions, "RxDimensions", "named", reactIconsRx],
  [RxDisc, "RxDisc", "named", reactIconsRx],
  [RxDiscordLogo, "RxDiscordLogo", "named", reactIconsRx],
  [RxDividerHorizontal, "RxDividerHorizontal", "named", reactIconsRx],
  [RxDividerVertical, "RxDividerVertical", "named", reactIconsRx],
  [RxDotFilled, "RxDotFilled", "named", reactIconsRx],
  [RxDot, "RxDot", "named", reactIconsRx],
  [RxDotsHorizontal, "RxDotsHorizontal", "named", reactIconsRx],
  [RxDotsVertical, "RxDotsVertical", "named", reactIconsRx],
  [RxDoubleArrowDown, "RxDoubleArrowDown", "named", reactIconsRx],
  [RxDoubleArrowLeft, "RxDoubleArrowLeft", "named", reactIconsRx],
  [RxDoubleArrowRight, "RxDoubleArrowRight", "named", reactIconsRx],
  [RxDoubleArrowUp, "RxDoubleArrowUp", "named", reactIconsRx],
  [RxDownload, "RxDownload", "named", reactIconsRx],
  [RxDragHandleDots1, "RxDragHandleDots1", "named", reactIconsRx],
  [RxDragHandleDots2, "RxDragHandleDots2", "named", reactIconsRx],
  [RxDragHandleHorizontal, "RxDragHandleHorizontal", "named", reactIconsRx],
  [RxDragHandleVertical, "RxDragHandleVertical", "named", reactIconsRx],
  [RxDrawingPinFilled, "RxDrawingPinFilled", "named", reactIconsRx],
  [RxDrawingPin, "RxDrawingPin", "named", reactIconsRx],
  [RxDropdownMenu, "RxDropdownMenu", "named", reactIconsRx],
  [RxEnterFullScreen, "RxEnterFullScreen", "named", reactIconsRx],
  [RxEnter, "RxEnter", "named", reactIconsRx],
  [RxEnvelopeClosed, "RxEnvelopeClosed", "named", reactIconsRx],
  [RxEnvelopeOpen, "RxEnvelopeOpen", "named", reactIconsRx],
  [RxEraser, "RxEraser", "named", reactIconsRx],
  [RxExclamationTriangle, "RxExclamationTriangle", "named", reactIconsRx],
  [RxExitFullScreen, "RxExitFullScreen", "named", reactIconsRx],
  [RxExit, "RxExit", "named", reactIconsRx],
  [RxExternalLink, "RxExternalLink", "named", reactIconsRx],
  [RxEyeClosed, "RxEyeClosed", "named", reactIconsRx],
  [RxEyeNone, "RxEyeNone", "named", reactIconsRx],
  [RxEyeOpen, "RxEyeOpen", "named", reactIconsRx],
  [RxFace, "RxFace", "named", reactIconsRx],
  [RxFigmaLogo, "RxFigmaLogo", "named", reactIconsRx],
  [RxFileMinus, "RxFileMinus", "named", reactIconsRx],
  [RxFilePlus, "RxFilePlus", "named", reactIconsRx],
  [RxFileText, "RxFileText", "named", reactIconsRx],
  [RxFile, "RxFile", "named", reactIconsRx],
  [RxFontBold, "RxFontBold", "named", reactIconsRx],
  [RxFontFamily, "RxFontFamily", "named", reactIconsRx],
  [RxFontItalic, "RxFontItalic", "named", reactIconsRx],
  [RxFontRoman, "RxFontRoman", "named", reactIconsRx],
  [RxFontSize, "RxFontSize", "named", reactIconsRx],
  [RxFontStyle, "RxFontStyle", "named", reactIconsRx],
  [RxFrame, "RxFrame", "named", reactIconsRx],
  [RxFramerLogo, "RxFramerLogo", "named", reactIconsRx],
  [RxGear, "RxGear", "named", reactIconsRx],
  [RxGithubLogo, "RxGithubLogo", "named", reactIconsRx],
  [RxGlobe, "RxGlobe", "named", reactIconsRx],
  [RxGrid, "RxGrid", "named", reactIconsRx],
  [RxGroup, "RxGroup", "named", reactIconsRx],
  [RxHalf1, "RxHalf1", "named", reactIconsRx],
  [RxHalf2, "RxHalf2", "named", reactIconsRx],
  [RxHamburgerMenu, "RxHamburgerMenu", "named", reactIconsRx],
  [RxHand, "RxHand", "named", reactIconsRx],
  [RxHeading, "RxHeading", "named", reactIconsRx],
  [RxHeartFilled, "RxHeartFilled", "named", reactIconsRx],
  [RxHeart, "RxHeart", "named", reactIconsRx],
  [RxHeight, "RxHeight", "named", reactIconsRx],
  [RxHobbyKnife, "RxHobbyKnife", "named", reactIconsRx],
  [RxHome, "RxHome", "named", reactIconsRx],
  [RxIconjarLogo, "RxIconjarLogo", "named", reactIconsRx],
  [RxIdCard, "RxIdCard", "named", reactIconsRx],
  [RxImage, "RxImage", "named", reactIconsRx],
  [RxInfoCircled, "RxInfoCircled", "named", reactIconsRx],
  [RxInput, "RxInput", "named", reactIconsRx],
  [RxInstagramLogo, "RxInstagramLogo", "named", reactIconsRx],
  [RxKeyboard, "RxKeyboard", "named", reactIconsRx],
  [RxLapTimer, "RxLapTimer", "named", reactIconsRx],
  [RxLaptop, "RxLaptop", "named", reactIconsRx],
  [RxLayers, "RxLayers", "named", reactIconsRx],
  [RxLayout, "RxLayout", "named", reactIconsRx],
  [RxLetterCaseCapitalize, "RxLetterCaseCapitalize", "named", reactIconsRx],
  [RxLetterCaseLowercase, "RxLetterCaseLowercase", "named", reactIconsRx],
  [RxLetterCaseToggle, "RxLetterCaseToggle", "named", reactIconsRx],
  [RxLetterCaseUppercase, "RxLetterCaseUppercase", "named", reactIconsRx],
  [RxLetterSpacing, "RxLetterSpacing", "named", reactIconsRx],
  [RxLightningBolt, "RxLightningBolt", "named", reactIconsRx],
  [RxLineHeight, "RxLineHeight", "named", reactIconsRx],
  [RxLink1, "RxLink1", "named", reactIconsRx],
  [RxLink2, "RxLink2", "named", reactIconsRx],
  [RxLinkBreak1, "RxLinkBreak1", "named", reactIconsRx],
  [RxLinkBreak2, "RxLinkBreak2", "named", reactIconsRx],
  [RxLinkNone1, "RxLinkNone1", "named", reactIconsRx],
  [RxLinkNone2, "RxLinkNone2", "named", reactIconsRx],
  [RxLinkedinLogo, "RxLinkedinLogo", "named", reactIconsRx],
  [RxListBullet, "RxListBullet", "named", reactIconsRx],
  [RxLockClosed, "RxLockClosed", "named", reactIconsRx],
  [RxLockOpen1, "RxLockOpen1", "named", reactIconsRx],
  [RxLockOpen2, "RxLockOpen2", "named", reactIconsRx],
  [RxLoop, "RxLoop", "named", reactIconsRx],
  [RxMagicWand, "RxMagicWand", "named", reactIconsRx],
  [RxMagnifyingGlass, "RxMagnifyingGlass", "named", reactIconsRx],
  [RxMargin, "RxMargin", "named", reactIconsRx],
  [RxMaskOff, "RxMaskOff", "named", reactIconsRx],
  [RxMaskOn, "RxMaskOn", "named", reactIconsRx],
  [RxMinusCircled, "RxMinusCircled", "named", reactIconsRx],
  [RxMinus, "RxMinus", "named", reactIconsRx],
  [RxMix, "RxMix", "named", reactIconsRx],
  [RxMixerHorizontal, "RxMixerHorizontal", "named", reactIconsRx],
  [RxMixerVertical, "RxMixerVertical", "named", reactIconsRx],
  [RxMobile, "RxMobile", "named", reactIconsRx],
  [RxModulzLogo, "RxModulzLogo", "named", reactIconsRx],
  [RxMoon, "RxMoon", "named", reactIconsRx],
  [RxMove, "RxMove", "named", reactIconsRx],
  [RxNotionLogo, "RxNotionLogo", "named", reactIconsRx],
  [RxOpacity, "RxOpacity", "named", reactIconsRx],
  [RxOpenInNewWindow, "RxOpenInNewWindow", "named", reactIconsRx],
  [RxOverline, "RxOverline", "named", reactIconsRx],
  [RxPadding, "RxPadding", "named", reactIconsRx],
  [RxPaperPlane, "RxPaperPlane", "named", reactIconsRx],
  [RxPause, "RxPause", "named", reactIconsRx],
  [RxPencil1, "RxPencil1", "named", reactIconsRx],
  [RxPencil2, "RxPencil2", "named", reactIconsRx],
  [RxPerson, "RxPerson", "named", reactIconsRx],
  [RxPieChart, "RxPieChart", "named", reactIconsRx],
  [RxPilcrow, "RxPilcrow", "named", reactIconsRx],
  [RxPinBottom, "RxPinBottom", "named", reactIconsRx],
  [RxPinLeft, "RxPinLeft", "named", reactIconsRx],
  [RxPinRight, "RxPinRight", "named", reactIconsRx],
  [RxPinTop, "RxPinTop", "named", reactIconsRx],
  [RxPlay, "RxPlay", "named", reactIconsRx],
  [RxPlusCircled, "RxPlusCircled", "named", reactIconsRx],
  [RxPlus, "RxPlus", "named", reactIconsRx],
  [RxQuestionMarkCircled, "RxQuestionMarkCircled", "named", reactIconsRx],
  [RxQuestionMark, "RxQuestionMark", "named", reactIconsRx],
  [RxQuote, "RxQuote", "named", reactIconsRx],
  [RxRadiobutton, "RxRadiobutton", "named", reactIconsRx],
  [RxReader, "RxReader", "named", reactIconsRx],
  [RxReload, "RxReload", "named", reactIconsRx],
  [RxReset, "RxReset", "named", reactIconsRx],
  [RxResume, "RxResume", "named", reactIconsRx],
  [RxRocket, "RxRocket", "named", reactIconsRx],
  [RxRotateCounterClockwise, "RxRotateCounterClockwise", "named", reactIconsRx],
  [RxRowSpacing, "RxRowSpacing", "named", reactIconsRx],
  [RxRows, "RxRows", "named", reactIconsRx],
  [RxRulerHorizontal, "RxRulerHorizontal", "named", reactIconsRx],
  [RxRulerSquare, "RxRulerSquare", "named", reactIconsRx],
  [RxScissors, "RxScissors", "named", reactIconsRx],
  [RxSection, "RxSection", "named", reactIconsRx],
  [RxSewingPinFilled, "RxSewingPinFilled", "named", reactIconsRx],
  [RxSewingPin, "RxSewingPin", "named", reactIconsRx],
  [RxShadowInner, "RxShadowInner", "named", reactIconsRx],
  [RxShadowNone, "RxShadowNone", "named", reactIconsRx],
  [RxShadowOuter, "RxShadowOuter", "named", reactIconsRx],
  [RxShadow, "RxShadow", "named", reactIconsRx],
  [RxShare1, "RxShare1", "named", reactIconsRx],
  [RxShare2, "RxShare2", "named", reactIconsRx],
  [RxShuffle, "RxShuffle", "named", reactIconsRx],
  [RxSize, "RxSize", "named", reactIconsRx],
  [RxSketchLogo, "RxSketchLogo", "named", reactIconsRx],
  [RxSlash, "RxSlash", "named", reactIconsRx],
  [RxSlider, "RxSlider", "named", reactIconsRx],
  [RxSpaceBetweenHorizontally, "RxSpaceBetweenHorizontally", "named", reactIconsRx],
  [RxSpaceBetweenVertically, "RxSpaceBetweenVertically", "named", reactIconsRx],
  [RxSpaceEvenlyHorizontally, "RxSpaceEvenlyHorizontally", "named", reactIconsRx],
  [RxSpaceEvenlyVertically, "RxSpaceEvenlyVertically", "named", reactIconsRx],
  [RxSpeakerLoud, "RxSpeakerLoud", "named", reactIconsRx],
  [RxSpeakerModerate, "RxSpeakerModerate", "named", reactIconsRx],
  [RxSpeakerOff, "RxSpeakerOff", "named", reactIconsRx],
  [RxSpeakerQuiet, "RxSpeakerQuiet", "named", reactIconsRx],
  [RxSquare, "RxSquare", "named", reactIconsRx],
  [RxStack, "RxStack", "named", reactIconsRx],
  [RxStarFilled, "RxStarFilled", "named", reactIconsRx],
  [RxStar, "RxStar", "named", reactIconsRx],
  [RxStitchesLogo, "RxStitchesLogo", "named", reactIconsRx],
  [RxStop, "RxStop", "named", reactIconsRx],
  [RxStopwatch, "RxStopwatch", "named", reactIconsRx],
  [RxStretchHorizontally, "RxStretchHorizontally", "named", reactIconsRx],
  [RxStretchVertically, "RxStretchVertically", "named", reactIconsRx],
  [RxStrikethrough, "RxStrikethrough", "named", reactIconsRx],
  [RxSun, "RxSun", "named", reactIconsRx],
  [RxSwitch, "RxSwitch", "named", reactIconsRx],
  [RxSymbol, "RxSymbol", "named", reactIconsRx],
  [RxTable, "RxTable", "named", reactIconsRx],
  [RxTarget, "RxTarget", "named", reactIconsRx],
  [RxTextAlignBottom, "RxTextAlignBottom", "named", reactIconsRx],
  [RxTextAlignCenter, "RxTextAlignCenter", "named", reactIconsRx],
  [RxTextAlignJustify, "RxTextAlignJustify", "named", reactIconsRx],
  [RxTextAlignLeft, "RxTextAlignLeft", "named", reactIconsRx],
  [RxTextAlignMiddle, "RxTextAlignMiddle", "named", reactIconsRx],
  [RxTextAlignRight, "RxTextAlignRight", "named", reactIconsRx],
  [RxTextAlignTop, "RxTextAlignTop", "named", reactIconsRx],
  [RxTextNone, "RxTextNone", "named", reactIconsRx],
  [RxText, "RxText", "named", reactIconsRx],
  [RxThickArrowDown, "RxThickArrowDown", "named", reactIconsRx],
  [RxThickArrowLeft, "RxThickArrowLeft", "named", reactIconsRx],
  [RxThickArrowRight, "RxThickArrowRight", "named", reactIconsRx],
  [RxThickArrowUp, "RxThickArrowUp", "named", reactIconsRx],
  [RxTimer, "RxTimer", "named", reactIconsRx],
  [RxTokens, "RxTokens", "named", reactIconsRx],
  [RxTrackNext, "RxTrackNext", "named", reactIconsRx],
  [RxTrackPrevious, "RxTrackPrevious", "named", reactIconsRx],
  [RxTransform, "RxTransform", "named", reactIconsRx],
  [RxTransparencyGrid, "RxTransparencyGrid", "named", reactIconsRx],
  [RxTrash, "RxTrash", "named", reactIconsRx],
  [RxTriangleDown, "RxTriangleDown", "named", reactIconsRx],
  [RxTriangleLeft, "RxTriangleLeft", "named", reactIconsRx],
  [RxTriangleRight, "RxTriangleRight", "named", reactIconsRx],
  [RxTriangleUp, "RxTriangleUp", "named", reactIconsRx],
  [RxTwitterLogo, "RxTwitterLogo", "named", reactIconsRx],
  [RxUnderline, "RxUnderline", "named", reactIconsRx],
  [RxUpdate, "RxUpdate", "named", reactIconsRx],
  [RxUpload, "RxUpload", "named", reactIconsRx],
  [RxValueNone, "RxValueNone", "named", reactIconsRx],
  [RxValue, "RxValue", "named", reactIconsRx],
  [RxVercelLogo, "RxVercelLogo", "named", reactIconsRx],
  [RxVideo, "RxVideo", "named", reactIconsRx],
  [RxViewGrid, "RxViewGrid", "named", reactIconsRx],
  [RxViewHorizontal, "RxViewHorizontal", "named", reactIconsRx],
  [RxViewNone, "RxViewNone", "named", reactIconsRx],
  [RxViewVertical, "RxViewVertical", "named", reactIconsRx],
  [RxWidth, "RxWidth", "named", reactIconsRx],
  [RxZoomIn, "RxZoomIn", "named", reactIconsRx],
  [RxZoomOut, "RxZoomOut", "named", reactIconsRx],
]);
