import * as React from "react";
import { DocumentNode } from "../ast";
import * as prettier from "prettier";
import * as babelPlugin from "prettier/plugins/babel";
import * as estreePlugin from "prettier/plugins/estree";
import { renderToString } from "../rendering";

export function FormattedCode({ ast }: { ast?: DocumentNode }) {
  const [formattedCode, setCode] = React.useState("...");
  React.useEffect(() => {
    if (ast == null) {
      setCode("...");
      return;
    }
    prettier
      .format(renderToString(ast), {
        parser: "babel",
        plugins: [estreePlugin, babelPlugin],
      })
      .then(setCode)
      .catch((error) => {
        console.error(error);
        setCode("code formatting failed, please check console for details");
      });
  }, [ast]);
  return formattedCode;
}
