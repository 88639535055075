import * as React from "react";
import { ElementState, elementStateSchema } from "../editing";
import { z } from "zod";

export type SectionState = {
  element: ElementState;
  source: "override" | "default";
};

const localStorageKey = "relume-tagging-helper-paste";

const sectionStateContext = React.createContext<SectionState | undefined>(undefined);
const sectionStateUpdateContext = React.createContext<
  ((state: { element?: ElementState; source: "override" | "default" }) => void) | undefined
>(undefined);

export function useSectionState() {
  return React.useContext(sectionStateContext);
}

export function useSectionStateUpdate() {
  return React.useContext(sectionStateUpdateContext);
}

const saveSchema = z.object({
  id: z.string(),
  element: elementStateSchema,
});

function getSavedElement(id?: string) {
  if (id == null) {
    return;
  }
  try {
    const stored = window.localStorage.getItem(localStorageKey);
    if (stored != null) {
      const parsed = saveSchema.parse(JSON.parse(stored));
      if (parsed.id === id) {
        return parsed.element;
      }
    }
  } catch (error: unknown) {
    window.localStorage.removeItem(localStorageKey);
  }
}

export function SectionStateProvider({ children, id }: { children: React.ReactNode; id?: string }) {
  const [defaultElement, setDefaultElement] = React.useState<ElementState>();
  const [overrideElement, setOverrideElement] = React.useState<ElementState | undefined>(() =>
    getSavedElement(id),
  );
  React.useEffect(() => {
    setOverrideElement(getSavedElement(id));
  }, [id]);
  const state = React.useMemo(() => {
    if (overrideElement != null) {
      return {
        element: overrideElement,
        source: "override" as const,
      };
    }
    if (defaultElement != null) {
      return {
        element: defaultElement,
        source: "default" as const,
      };
    }
    return undefined;
  }, [overrideElement ?? defaultElement]);
  const setState = React.useCallback(
    (state: { element?: ElementState; source: "override" | "default" }) => {
      if (state.source === "default") {
        setDefaultElement(state.element);
      } else if (state.source === "override") {
        setOverrideElement(state.element);
        if (id != null && state.element != null) {
          window.localStorage.setItem(
            localStorageKey,
            JSON.stringify({ id, element: state.element }),
          );
        } else {
          window.localStorage.removeItem(localStorageKey);
        }
      }
    },
    [],
  );
  return (
    <sectionStateContext.Provider value={state}>
      <sectionStateUpdateContext.Provider value={setState}>
        {children}
      </sectionStateUpdateContext.Provider>
    </sectionStateContext.Provider>
  );
}
